import { Component, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";

@Component({
  selector: 'app-root',
  template: `
    <app-loader></app-loader>
    <lib-header></lib-header>
    
  `
})
export class AppComponent implements OnInit {
  title = 'BOSHHH';
  public location = '' ;
  displayFooter: boolean = true;
  excludedRoute = '/register';
  paymentSuccessRoute = '/paymentsuccess?';
  awinCheckoutId: any;
  awinOrderAmount: any;

  constructor(private router:Router, private renderer2: Renderer2) {
    router.events.forEach((event) => {
     
      if (event instanceof NavigationEnd) {
        var chatbot = document.querySelector('.intercom-lightweight-app')
        chatbot?.setAttribute('hidden','true')
        if (event.url.startsWith('/register')) {
          document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
          this.displayFooter = false;
        } 
        else if(event.url.startsWith('/pre/signup')){
          document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
          this.displayFooter = false;
        }
        else if(event.url.startsWith('/purchase/purchaseform')){
          document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
          this.displayFooter = false;
        }
        else if(event.url === '/'){
          if(chatbot != null){
            chatbot?.removeAttribute('hidden')
          }else{
          // intercom Chat Bot Start -->
          var script = document.createElement("script"); // create a script tag
          script.setAttribute("class","chatbot");
          script.innerHTML = `window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "o2ajsdeh"
          };`
          document.body.appendChild(script);
        }
        }
        else {
          this.displayFooter = true;
        }
      }
    })
  };

  ngOnInit(): void {
    this.awinCheckoutId = localStorage.getItem('checkoutId') || '{}';
    this.awinOrderAmount = localStorage.getItem('orderAmount') || '{}';
  }
}
