import { Component, OnInit } from '@angular/core';
import { IHowItWorksModel } from './how-it-works.model';

@Component({
  selector: 'lib-how-it-works',
  template: `
  <div class="pt-5 container">
      <h1 class="mt-5 text-center" style="font-size: 3rem !important;">How it works.</h1>
      <div class="pl-3 pr-3 text-center">
        <div class="row">
          <div class="col-sm-6" hidden>
            <div class="how-it-works ml-4 mr-4 p-3">
              <h1>I have been unsuccessful with credit checks in the past.</h1>
              <p>
                Unsure about not being accepted? Don’t worry, we don’t do any credit
                checks
              </p>
            </div>
          </div>
          <div class="col-sm-6" hidden>
            <div class="how-it-works ml-4 mr-4 p-3">
              <h1>Why Boshhh?</h1>
              <p>
                You want a proven way to improve your credit score and can afford to
                invest around £20 per month to do so
              </p>
            </div>
          </div>


          <div class="col-sm-6">
            <div class="how-it-works ml-4 mr-4 p-3">
              <h1>Difference between other providers?</h1>
              <p>
                <span hidden>As well as improving your credit rating every month, y</span>You save £10-60 each
                month by switching from your current network provider and unlocking
                unlimited texts and minutes.
              </p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="how-it-works ml-4 mr-4 p-3">
              <h1>Can I keep my current number?</h1>
              <p>
                Yes, you can keep your current number, don’t worry, you’re not tied into
                any contracts!
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-12" hidden>
            <div class="how-it-works ml-4 mr-4 p-3">
              <h1>How can credit agencies know I am making payments?</h1>
              <p>
                Here is the smart part... Each month, Boshhh reports to the credit
                agencies (the companies that track and publish your credit score), that
                you've made your repayments, which increases your credit score.
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>
  `,
  styles: [`
    .how-it-works h1 {
      font-size: 1.3rem !important;
      line-height: 1.5rem !important;
      background: -webkit-linear-gradient(45deg, #009245, #003f37);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }
    .how-it-works {
      background-color: white;
      border: 2px solid transparent;
      border-radius: 25px;
      filter: drop-shadow(0 0 0.4rem #9A9999);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .how-it-works p {
      margin-bottom: 0;
    }
  `]
})
export class HowItWorksComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
