import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Product } from '../../shared/models/Products'
import { GlobalService } from '../../shared/services/Global.service'

@Component({
  selector: 'app-mobiles',
  template: `



<div *ngIf="this.production">
    <div *ngIf="!hidePanel">
      <div class="pb-5" *ngIf="!isVisible">
           <app-header-section></app-header-section>
      </div>
    </div>
    <div class="features container">
        <ul class="row ps-0">
            <li class="d-flex align-items-center col-lg-4 col-md-4 col-sm-6">
                <img src="/assets/images/shield.png" alt="shield" srcset="">
                <h5 class="my-1">12 month warranty</h5>
            </li>
            <li class="d-flex align-items-center col-lg-4 col-md-4 col-sm-6">
                <img src="/assets/images/settings.png" alt="settings" srcset="">
                <h5 class="my-1">A more sustainable option</h5>
            </li>
            <li class="d-flex align-items-center col-lg-4 col-md-4 col-sm-6">
                <img src="/assets/images/truck.png" alt="delivery" srcset="">
                <h5 class="my-1">Next day shipping</h5>
            </li>
            <li class="d-flex align-items-center col-lg-4 col-md-4 col-sm-6">
                <img src="/assets/images/battery.png" alt="battery" srcset="">
                <h5 class="my-1">80% battery health or better.</h5>
            </li>
        </ul>
    </div>
    <div class="container" id="products">
        <h2 class="text-center fw-bold">Pre-Order your handset</h2>
        <div class="product">
          <div class=" row">
            <div class="block col-lg-4 col-md-6 col-sm-6" *ngFor="let product of products">
              <app-product-detail [product]= 'product'></app-product-detail>
            </div>
          </div>
        </div>
    </div>
    </div>
    <!-- Best Seller -->
    <!-- <div class="container py-4" id="products">
          <h2 class="text-center fw-bold" >Best Seller</h2>
          <app-product-detail [product]= 'product'></app-product-detail>
    </div> -->
  `,
  styles: [``],

})
export class MobilesComponent implements OnInit {
  hidePanel: string;

products: Product[];
production: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private global:GlobalService,
    // @ts-ignore
    @Inject('env') private env,
  ) {
    this.production = this.env.production;

  }
  isVisible = false;
  ngOnInit(): void {
    this.products = this.global.product.sort((a,b) => a.name>b.name?1:-1)

    this.route.queryParams.subscribe(params => {
      this.hidePanel = params['hidepanel'];
    });

  }
}
