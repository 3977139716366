import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MbillsService } from "../../shared/services/mbills.service";
import { PipedriveService } from "../../shared/services/pipedrive.service";

@Component({
  selector: 'app-account-setup',
  templateUrl: 'account-setup.component.html',
  styles: [`
    /* .btn-next button{
      background: -webkit-linear-gradient(45deg,#009245,#003f37)!important;
      padding: 8px;
      width: 100%!important;
      color: #fff;
      font-size: 18px;
    } */
  `],
})
export class AccountSetupComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  newPassword: string;
  accno: string;
  email: string;
  expierytime: string;
  issuetime: string;
  accountNumber: string;
  isURLExpired: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private mbillsService: MbillsService,
    private toastr: ToastrService,
    private pipeDriveService: PipedriveService

  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.newPassword = '';
          this.email = params['e'];
          this.changePasswordFormGroup =
            this.fb.group({
              newPassword: ['', Validators.required],
            });
        }
      );
  }

  setPassword(): void {
    console.log(this.email);
    if (this.changePasswordFormGroup.valid) {
        this.mbillsService.setPassword(
       this.email,
        this.changePasswordFormGroup.controls['newPassword'].value
      ).subscribe(result => {
        if (result.error) {
          this.toastr.error('Account Setup Error', 'Failed to Set Password');
          this.toastr.error('Try again', 'Try again to set your password please');
        }
       }, error => { }, () => {
      });
      // this.router.navigate(['account-setup-complete']);
      this.toastr.success('Account Setup Success', 'Password Reset Successfully');
      this.router.navigate(['account-setup-complete']);

    }
  }
}
