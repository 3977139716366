import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PipedriveService } from "../../../services/pipedrive.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'lib-stripe-payment-details',
  template: `
      <!-- <ng-container>
        <ngx-stripe-payment [clientSecret]="clientSecret"></ngx-stripe-payment>
        <form [formGroup]="acceptForm">
          <lib-signed-agreement (acceptedTerms)="doAcceptedTerms($event)"></lib-signed-agreement>
        </form>
        <div *ngIf="paymentProcessing$ | async" class="justify-content-center">
          <img src="https://boshhh.com/assets/images/boshh-dark-spinner.gif" alt="spinner" width="80px">
          <br>
          <p>Processing Your Order</p>
        </div>
        <div *ngIf="noPaymentProcessing$ | async">
          <div *ngIf="paymentErrorResponse">
            <p style="color: red;">{{paymentErrorResponse}}</p>
          </div>
          <button [disabled]="acceptedTerms" mat-button (click)="doConfirmPayment()" class="confirm-button">CONFIRM</button>
        </div>
      </ng-container> -->
  `,
  styles: [`
    .process-payment-loader {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(104,104,104,0.89);
    }

    .confirm-button {
      margin-top: 10px;
      display: block;
      background-color: green;
      width: 100%!important;
      min-width: unset!important;
      color: #fff;
    }

    .confirm-button:disabled {
      margin-top: 10px;
      display: block;
      background-color: green;
      width: 100%!important;
      min-width: unset!important;
      color: #fff;
    }

    mat-button {
      width: 100%!important;
    }
  `]
})
export class StripePaymentDetailsComponent implements OnInit {
  acceptForm: FormGroup;
  acceptedTerms: boolean = false;
  @Input() clientSecret: string;
  @Input() customerName: string;
  @Input() customerEmail: string;
  @Input() customerAddress: string;
  @Input() customerPhone: string;
  @Input() selectedPackageId: string;


  public paymentProcessing$ = new BehaviorSubject<boolean>(false);
  public noPaymentProcessing$ = new BehaviorSubject<boolean>(true);

  paymentErrorResponse?: string;

  constructor(
    private fb: FormBuilder,
    private pipedriveService: PipedriveService
  ) { }

  ngOnInit(): void {
    // this.acceptForm = this.fb.group({});
  }

  doAcceptedTerms(acceptedTerms: boolean) {
    this.acceptedTerms = !acceptedTerms;
  }

 

}
