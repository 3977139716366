
  <div class="row">
     <div class="col-md-6 mx-auto mt-5">
        <div class="payment">
           <div class="payment_header">
              <div class="check"><img src="assets/images/boshhh_icon.png" height="50px" width="50px" alt="boshhh-icon"/></div>
           </div>
           <div class="content">
              <h1>Payment faild</h1>
              <p>Your payment has been faild due to some reason
                <br> Please check details
                <p>Sorry for the Inconvenience</p>
              <button style="background-color: #c31818;" [routerLink]="['/']">Go back to Home</button>
           </div>
           
        </div>
     </div>
  </div>
    
  <button #errorModal data-toggle="modal" data-target="#exampleModal" data-backdrop="false" hidden></button>
<div class="modal fade" id="exampleModal" tabindex="-200" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" style="margin-top: 100px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Payment Declined</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>

                </button>
            </div>
            <div class="modal-body">
                <p style="color: red;">{{message}}</p><br>
                <p><b>Please Try again using different card</b></p>

                <form #ref action="/payment-success" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div> -->
        </div>
    </div>
</div>

