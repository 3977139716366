<div class="content bg-white billing-container table-data">
  <h3 class="page-heading">Billing History</h3>
  <!-- Bill table Start-->
  <table id="myTable" class="w-100 mt-3">
      <thead>
          <tr>
              <th class="text-center f-14">Invoice No.</th>
              <th class="text-center f-14">Date</th>
              <th class="text-center f-14">Amount</th>
              <!-- <th class="text-center">Status</th> -->
              <th></th>
          </tr>
      </thead>
      <tbody >
          <tr *ngFor="let a of this.mbillInvoces" class="bg-white border my-2">
              <td class="text-center">{{a.invoiceNumber}}</td>
              <td class="text-center">{{a.invoicePeriod}}</td>
              <td class="text-center">£{{a.invoiceTotal}}</td>
              <!-- <td class="text-center text-green fw-bold">Paid</td> -->
              <td><a href={{a.pdfUrl}} target="_blank" class="btn btn-small f-12 bosh-btn-primary">View invoice</a></td>
          </tr>
          
      </tbody>
  </table>
  
  

  <!-- Content Block End -->
</div>