
  <div class="row">
    <div class="col-md-6 mx-auto mt-5">
       <div class="payment">
         <div class="payment_header">
             <div class="check"><img src="assets/images/boshhh_icon.png" height="50px" width="50px" alt="boshhh-icon" /></div>
         </div>
         <div class="content">
            <h1>Request sent Successfully</h1>
            <p>Your Sim activation request is under process.</p>
            <p>It can take up to 48 hours to activate</p>
            <p>Have log in to my mobile.</p>
            <button class="btn-success" style="background-color: #50D192;" [routerLink]="['/login']">Login </button>
            <p class="mt-2">Connect to open banking and view the credit report</p>
         </div>
          
       </div>
    </div>
 </div>