import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calls-data-roaming-charges',
  templateUrl: 'calls-data-roaming-charges.component.html'
})
export class CallsDataRoamingChargesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
