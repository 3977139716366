import { Component, OnInit } from '@angular/core';
import { Product } from '../../../shared/models/Products'
import { GlobalService } from '../../../shared/services/Global.service'
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css']
})
export class OrderFormComponent implements OnInit {
  products: Product[];
    product:any
    constructor(private global: GlobalService,private route: ActivatedRoute ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.product = this.global.product.find(x=>x.id === Number(params['id']))
    });
  }

}
