import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AddressLookupService } from "../../services/address-lookup.service";


@Component({
  selector: 'lib-sim-plans-selector',
  templateUrl: './sim-plans-selector.component.html'
})
export class SimPlansSelectorComponent implements OnInit {
  @Input() useEventEmitter: boolean;
  @Output() selectedPackage = new EventEmitter<string>();

  priceIdOne: string;
  priceIdTwo: string;
  priceIdThree: string;
  packagePlans: any;
  selectedPackageId: any;
  production: boolean = false;

  constructor(
    // @ts-ignore
    @Inject('env') private env,
    private adressLookupService: AddressLookupService,
    private router: Router
  ) {
    this.production = this.env.production
  }

  ngOnInit() {
    this.adressLookupService.getPackages().subscribe(
      resp => {
        this.packagePlans = resp
          this.selectedPackageId = this.packagePlans.filter( (x: any) => !x.name.includes('Bolt On')).reverse()
          this.priceIdOne = this.selectedPackageId[0]['stripePriceId'];
          this.priceIdTwo = this.selectedPackageId[1]['stripePriceId'];
          this.priceIdThree = this.selectedPackageId[2]['stripePriceId'];
      })
    
  }

  selectPackage(packageId: string): void {
    if (this.useEventEmitter) {
      this.selectedPackage.emit(packageId);
    } else {
      this.router.navigateByUrl(`/register?packageId=${packageId}`);
      document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
    }
  }
}
