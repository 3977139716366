import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MbillsService } from '../../../shared/services/mbills.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  helpForm: FormGroup;
  tickets: any = [];
  formValidity = true;
  constructor(private mbills: MbillsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private el: ElementRef,
    private router: Router) { }

  ngOnInit() {
    this.getTicketTypes()
    this.initHelpForm()
  }

  initHelpForm(){
    this.helpForm = this.fb.group({

      ticketType: ['0', Validators.required],
      notes: ['', Validators.required],
    })
  }

  getTicketTypes(){
    this.mbills.getTickettypes().subscribe(resp => {
      this.tickets = resp
    })
  }

  changeType(event: any){
    this.helpForm.controls['ticketType'].setValue(event.target.value)
  }

  checkValidity(){
    if((this.helpForm.get('ticketType')?.invalid && this.helpForm.get('ticketType')?.touched)|| (this.helpForm.get('notes')?.invalid && this.helpForm.get('notes')?.touched))
    {
      return this.formValidity = false
    }
    else{
      return this.formValidity = true
    }
  }

  submit(){
    if(this.helpForm.valid && this.helpForm.get('ticketType')?.value != 0 ){
      this.formValidity = true
      this.mbills.postHelp(this.helpForm.value).subscribe(x => {
        this.toastr.success('Query submitted','Query submitted Successfully')
        this.router.navigateByUrl('/account/dashboard')
      },
      err => {
        this.toastr.error('Query not submitted','Something went wrong')
      })
    } else {
      this.formValidity = false
    }
  }

  causeValidation(): void {
    var fst = true
    Object.keys(this.helpForm.controls).forEach( field => {
      const control = this.helpForm.get(field);
      if (control instanceof FormControl) {
        if(control.dirty === false && control.valid === false && fst === true){
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + field + '"]');
          invalidControl.focus();
          fst = false
        }
        control.markAsTouched({onlySelf: true});
        if(control.invalid){
        control.setErrors({'incorrect': true})
        }
      }
    });
  }
}
