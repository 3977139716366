import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr"

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnectionBuilder!: HubConnection;
  private listenedData = new Subject<any>();

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

    public startConnection(){
      this.hubConnectionBuilder = new HubConnectionBuilder()
      .withUrl(`${this.env.pipedriveApi}/hubs/boshhhHub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build();
    this.hubConnectionBuilder
      .start()
      .then(() => console.log('Connection started.......!'))
      .catch(err => {
        console.log('Error while connect with server')
      }
      );
    }

    public listeningToPipeDriveEvent(){
      this.hubConnectionBuilder.on('PipeDriveEvent', (result: any) => {
        this.listenedData.next(result)
      });
    }

    public listeningToPaymentEvent(){
      this.hubConnectionBuilder.on('PaymentEvent', (result: any) => {
        this.listenedData.next(result)
      });
    }

    public getDataAsObservable(){
      return this.listenedData.asObservable();
    }

}
