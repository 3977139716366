<div class="py-5 text-center container equifax-content " id="about">
  <div class="inner-content">
    <h2 class="mb-2 fw-bolder">
      Boshhh™️ Credit BOOSTER can improve Your score with <img  src="/assets/images/efx_hr_logo_horizontal.png" width="165px">
    </h2>
  </div>
  <p>Boshhh! We are in partnership with the UK’s leading credit reference agency, Equifax.</p>

  <div class="journey-process" id="boshhh-journey">
  <h2 class="fw-bold py-3">Here's the clever bit...</h2>
  <!-- <div id="stepper-slider" class="carousel slide mobile-view" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#stepper-slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#stepper-slider" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#stepper-slider" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner stepper-slider">
      <div class="carousel-item active">
        <h2 class="text-center bg-number">01</h2>
        <a [routerLink]="['/register']">
          <img src="/assets/images/sim-card.png" alt="sim-card" width="120px">
       </a>
        <h2 class="fw-bolder text-gredient">Bish!</h2>
        <h3 class="fw-bold">Choose a plan that fits your needs</h3>
        <p>Kickstart by choosing the perfect SIM plan for you - 10GB, 30GB, or a whopping 100GB! Our award winning SIM contracts have unlimited calls and texts as a bonus.</p>
      </div>
      <div class="carousel-item">
        <div class="container">
          <h2 class="text-center bg-number">02</h2>
          <a [routerLink]="['/register']">
          <img src="/assets/images/mobile-card.png" alt="mobile-card" width="120px"></a>
          <h2 class="fw-bolder text-gredient">Bash!</h2>
          <h3 class="fw-bold">Free access to your credit file </h3>
          <p>Our Boshhh SIM contract acts as your credit agreement! Concerned about getting credit? No need to stress - everyones welcome at Boshhh. 100% acceptance</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="container">
          <h2 class="text-center bg-number">03</h2>
          <a [routerLink]="['/register']">
          <img src="/assets/images/speed-meter.png" alt="speed-meter" width="120px"></a>
          <h2 class="fw-bolder text-gredient">Boshhh!</h2>
          <h3 class="fw-bold">Positive impact on your credit file! </h3>
          <p>Each month, Boshhh has your back by reporting your timely SIM bill payments to Equifax. Sit back and watch your credit score climb*</p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#stepper-slider" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#stepper-slider" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div> -->
  
    <div class="container ">
      <div class="row stepper-slider">
        <div class="col-md-4">
          <div class="">
            <!-- <h2 class="text-center bg-number">01</h2> -->
            <img src="/assets/images/stepper/bish-1.png" alt="stepper-bish" width="150px">
            <!-- <h2 class="fw-bolder text-gredient">Bish!</h2> -->
            <h3 class="fw-bold">Choose a plan that fits your needs</h3>
            <p>Kickstart by choosing the perfect SIM plan for you - 10GB, 30GB, or a whopping 100GB! Our award winning SIM contracts have unlimited calls and texts as a bonus.</p>
          </div>
        </div>
        
          <div class="col-md-4">
            <div class="">
              <!-- <h2 class="text-center bg-number">02</h2> -->
              <img src="/assets/images/stepper/bash-1.png" alt="stepper-bash" width="150px">
              <!-- <h2 class="fw-bolder text-gredient">Bash!</h2> -->
              <h3 class="fw-bold">Free access to your credit file </h3>
              <p>Our Boshhh SIM contract acts as your credit agreement! Concerned about getting credit? No need to stress - everyones welcome at Boshhh. 100% acceptance</p>
            </div>
          </div>
        
          <div class="col-md-4">
            <div class="">
              <!-- <h2 class="text-center bg-number">03</h2> -->
              <img src="/assets/images/stepper/boshhh-1.png" alt="stepper-boshhh" width="150px">
              <!-- <h2 class="fw-bolder text-gredient">Boshhh!</h2> -->
              <h3 class="fw-bold">Positive impact on your credit file! </h3>
              <p>Each month, Boshhh has your back by reporting your timely SIM bill payments to Equifax. Sit back and watch your credit score climb*</p>
            </div>
          </div>
      </div>
    </div>
    <p class="mt-2"><b>P.S We give you access to your credit file for FREE, plus we offer guidance to help you understand and master your credit file. Want to keep your current number? Bring it along! Boshhh to the rescue!</b></p>
  </div>
</div>