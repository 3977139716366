import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refund-policy',
  templateUrl: 'refund-policy.component.html'
})
export class RefundPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
