import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-report-landing-page',
  templateUrl: './credit-report-landing-page.component.html',
  styleUrls: ['./credit-report-landing-page.component.css']
})
export class CreditReportLandingPageComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
}


  redirectToRegister() {
    this.router.navigateByUrl(`/register?packageId=4661`)
  }

}