<div class="text-center" id="plans">
  <img class="mb-3" src="/assets/images/logo-inverted.png" width="130px">
  <h2 class="fw-bold">
    Choose Your Sim Plan
  </h2>
  <p class="px-3">We will give your SIM card to the postman and have it posted by 3pm daily</p>
</div>

<div class="d-flex flex-column">
  <div class="row package-container container mx-auto my-2" #scrollContent (scroll)="onScroll($event)">
    <div id="package_plans" class="package-design-2 col-md-4 col-sm-4 col-lg-4" *ngFor="let plan of packagePlans; index as i">
      
    <div>
        <div class="plan-block" style="position: relative;"
          [ngClass]="{'plan-popular': plan.Name === '30GB Data', 'plan-value': plan.Name === '100GB Data'}">
          <div *ngIf="plan.Name   == '30GB Ultra Fast' " class="most-popular-tag">
            <p class="m-0"><span>POPULAR</span></p>
          </div>
          <div *ngIf="plan.Name   == '10 Gb Ultra Fast' " class="basic-tag">
            <p class="m-0"><span>BASIC</span></p>
          </div>
          <div *ngIf="plan.Name   == '100 GB Ultra Fast' " class="most-value-tag">
            <p class="m-0">MOST VALUE</p>
          </div>

          <div class="mt-25">
            <h2 [attr.id]="'plan_' + i+1" class="package-name d-flex justify-content-between mt-2">{{ plan.Name }}
              <img *ngIf="plan.Name   != 'Credit File'" src="/assets/images/boshhh-sim.jpeg" width="50" alt="Boshhh Sim Card" style="position:absolute; top: 10px; right: 10px;">
            </h2>
          </div>
          <div class="d-flex align-items-center">
            <p class="price">£{{ restrictToTwoDecimals(plan.Price)  }}</p>
            <p class="per-month text-center">Per Month</p>
          </div>
          <div>
            <div class="specs">
              <div class="check-img">
                <img src="/assets/images/check.svg" alt="check-icon">
              </div>
              <p class="text">Get <b>unlimited </b>calls and texts</p>
            </div>
            <div class="specs">
              <div class="check-img">
                <img src="/assets/images/check.svg" alt="check-icon">
              </div>
              <p class="text">Free access to<b> Boshhh Credit Booster</b></p>
            </div>

            <div class="specs">
              <div class="check-img">
                <img src="/assets/images/check.svg" alt="check-icon">
              </div>
              <p class="text">Free access to your <img  src="/assets/images/efx_hr_logo_horizontal.png" width="60px"> <b>credit report</b></p>
            </div>
            <div class="specs">
              <div class="check-img">
                <img src="/assets/images/check.svg" alt="check-icon">
              </div>
              <p class="text">Free access to <b>tools to improve your finances</b></p>
            </div>
            <div class="specs">
              <div class="check-img">
                <img src="/assets/images/check.svg" alt="check-icon">
              </div>
              <p class="text">Free access to <b>Boshhh Smart Match</b></p>
            </div>
          </div>
          <input type="radio" name="package_name" id="package_id-{{ i }}" value="{{ plan.Sku }}">
          <label for="package_id-{{ i }}" (click)="selectedPlan(plan.Sku)"
            class="bosh-btn-radio bosh-btn-primary text-center">
            Select Plan
          </label>

          <div class="cards d-flex justify-content-center mt-2">
            <div class="d-flex  justify-content-center">
              <img class="me-2" src="/assets/images/visa.svg" alt="payment-card-visa">
              <img src="/assets/images/mastercard.svg" alt="payment-card-mastercard">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="dots-container d-flex justify-content-center">
    <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 1}" (click)="scrollToElement(1);"></span>
    <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 2}" (click)="scrollToElement(2);"></span>
    <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 3}" (click)="scrollToElement(3);"></span>
    <!-- <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 4}" (click)="scrollToElement(4);"></span> -->
  </div>


</div>

