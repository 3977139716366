import { Component, AfterViewInit, Inject, Renderer2, ViewChild, ElementRef, } from "@angular/core";
import { TotalProcessingService } from "../../shared/services/total-processing.service";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-paymentfaild',
  templateUrl: './payment-faild.component.html',
  styleUrls: ['./payment-faild.component.css'],
})
export class paymentfaildComponent implements AfterViewInit {

    message: any
  @ViewChild('errorModal') errorModal: ElementRef;
    
    constructor(
      private _totalProcessingService: TotalProcessingService,
      private  _renderer2: Renderer2,
      private route: ActivatedRoute,
      private router: Router,

          // @ts-ignore
    @Inject('env') private env
    ) {
      router.events.subscribe((val) => {
        if(val instanceof NavigationEnd){
          if (val.url === '/payment-failed') {
          localStorage.removeItem('email')
          localStorage.removeItem('amount')
          }
        }
      })
    }


  ngAfterViewInit() {
    this.route.queryParams
    .subscribe(params => {
      this.message = params['message'];
    });
    this.createTotalProcessingPayment();
  }

  createTotalProcessingPayment(){
    var email = localStorage.getItem('email')
    var amount = localStorage.getItem('amount')
    var obj= {
      amount: Number(amount).toFixed(2).toString(),
      Email: email
    }
    this._totalProcessingService.createPaymentProcessing(
      obj).subscribe(resp => {
      var checkoutId = resp.id
      let script = this._renderer2.createElement('script');
      
      script.src = `${this.env.totalProcessingIntent}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      this._renderer2.appendChild(document.body, script);
    })
    this.errorModal.nativeElement.click()
  }

}
