import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class SharedService {

    public selectedPackagePlan:any;
    private isAffiliate: boolean = false;

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public getPackages(): Observable<any> {
    return this
      .http
      .get<any>(`${this.env.pipedriveApi}/api/packages`, this.httpOptions);
  }


    public setPackagePlan(plan:any) {
        localStorage.setItem('packagePlan', plan);
    }

    public getSelectedPackagePlan() {
        console.log(localStorage.getItem('packagePlan'));
        return localStorage.getItem('packagePlan');
    }


      public setProspectId(prospectId:any) {
        localStorage.setItem('prospectId', prospectId);
    }

    public setCreditFileFlow(isCredit:any) {
      localStorage.setItem('isCredit', isCredit);
  }

  public getCreditFileFlow() {
    return localStorage.getItem('isCredit');
  }
      public getProspectId() {
          return localStorage.getItem('prospectId');
      }
  public setaffiliate(status: any) {
    this.isAffiliate = status;
  }

  public getAffiliateStatus() {
    return this.isAffiliate;
  }
  
}
