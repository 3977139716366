import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AddressLookupService } from '../../../shared/services/address-lookup.service';

@Component({
  selector: 'app-qa-section-hidden',
  templateUrl: './qa-section-hidden-price.component.html',
  styleUrls: ['./qa-section-hidden-price.component.css']
})
export class QaSectionHiddenPriceComponent implements OnInit {

  @Output() packageInfo = new EventEmitter();
  packagePlans: any;
  plan: any;


  constructor(
    private adressLookupService: AddressLookupService,
    private router: Router
  ) { }

  ngOnInit() {
    this.adressLookupService.getPackages().subscribe(
      resp => {
      this.packagePlans = resp
      
      // this.packagePlans = this.packagePlans.filter( (x: any) => x.name == 'Credit File')
      this.packagePlans = this.packagePlans.filter( (x: any) => x.Categoryid == 5)
      
            
      })

  
    
  }

  selectedPlan(packageId: string): void {
    this.router.navigateByUrl(`/register?packageId=${packageId}`);
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0);
  }


  redirectToRegister() {
    this.router.navigateByUrl(`/register`);
  }


  restrictToTwoDecimals(price:any) {
    const fixedPrice = Math.floor(Number(price) * 100) / 100;
    return fixedPrice.toFixed(2);
  }


}
