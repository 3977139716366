import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-record-policy',
  templateUrl: 'call-record-policy.component.html'
})
export class CallRecordPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
