import { NgModule } from "@angular/core";

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { GlobalService } from './shared/services/Global.service'
import { BoshhhModule } from "./boshhh.module";
import { CommonModule, DatePipe } from "@angular/common";
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLinkWithHref, RouterOutlet } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS,HttpClientJsonpModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { TextMaskModule } from "angular2-text-mask";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { HeaderComponent } from "./shared/components/header/header.component";
import { StickyFooterComponent } from "./shared/components/sticky-footer/sticky-footer.component";
import { CookiePolicyComponent } from "./pages/cookie-policy/cookie-policy.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { CallRecordPolicyComponent } from "./pages/call-recording-policy/call-record-policy.component";
import {
  CallsDataRoamingChargesComponent
} from "./pages/calls-data-roaming-charges/calls-data-roaming-charges.component";
import { CodeOfPracticeComponent } from "./pages/code-of-practice/code-of-practice.component";
import { HowToAndFaqsComponent } from "./pages/how-to-and-faqs/how-to-and-faqs.component";
import { RefundPolicyComponent } from "./pages/refund-policy/refund-policy.component";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { JumbotronComponent } from "./shared/components/jumbotron/jumbotron.component";
import { HowItWorksComponent } from "./shared/components/how-it-works/how-it-works.component";
import { FrequentlyAskedQuestionsComponent } from "./shared/components/frequently-asked-questions/frequently-asked-questions.component";
import { SideDrawerComponent } from "./shared/components/side-drawer/side-drawer.component";
import { SelectPackageComponent } from "./shared/components/select-package/select-package.component";
import { WhatThePointComponent } from "./components/what-the-point/what-the-point.component";
import {
  CustomerDetailsComponent
} from "./shared/components/account-stepper/customer-details/customer-details.component";
import { PackageDetailsComponent } from "./shared/components/account-stepper/package-details/package-details.component";
import { AddressDetailsComponent } from "./shared/components/account-stepper/address-details/address-details.component";
import { CardDetailsComponent } from "./shared/components/account-stepper/card-details/card-details.component";
import { CompleteComponent } from "./shared/components/account-stepper/complete/complete.component";
import { SimPlansSelectorComponent } from "./shared/components/sim-plans-selector/sim-plans-selector.component";
import { FooterBoshhhTempComponent } from "./shared/components/footer/footer-boshhh-temp.component";
import { FooterHelloTempComponent } from "./shared/components/footer/footer-hello-temp.component";
import {
  PlanFeaturedMobileComponent
} from "./shared/components/sim-plans-selector-mobile/plan-featured-mobile.component";
import {
  PlanStandardMobileComponent
} from "./shared/components/sim-plans-selector-mobile/plan-standard-mobile.component";
import {
  StripePaymentDetailsComponent
} from "./shared/components/account-stepper/stripe-payment-details/stripe-payment-details.component";
import {
  SimPlansSelectorMobileComponent
} from "./shared/components/sim-plans-selector-mobile/sim-plans-selector-mobile.component";
import {
  SelectedPackageComponent
} from "./shared/components/account-stepper/selected-package/selected-package.component";
import { ReturnsPolicyComponent } from "./pages/returns-policy/returns-policy.component";
import { CreditBuilderPlusComponent } from "./pages/credit-builder-plus/credit-builder-plus.component";
import { HomeComponent } from "./pages/home/home.component";
import { MobilesComponent } from "./pages/mobiles/mobiles.component";
import { MobilesDetailComponent } from "./pages/mobiles/mobiles-detail.component";
import { ProductDetailComponent } from "./pages/mobiles/product-detail/product-detail.component";
import { OrderFormComponent } from "./pages/mobiles/order-form/order-form.component";
import { HeaderSectionComponent } from "./pages/mobiles/header-section/header-section.component";
import { ToastrModule } from "ngx-toastr";
import { SharedModule } from "./shared.module";
import { paymentexistComponent } from "./pages/payment-exist/payment-exist.component";

import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component"
import { MiniSliderComponent } from "./components/mini-slider/mini-slider.component"
import { EquifaxContentComponent } from "./components/equifax-content/equifax-content.component"
import { CreditFileComponent } from "./components/credit-file/credit-file.component"
import { PlanBannerComponent } from "./components/plan-banner/plan-banner.component"
import { AboutUsComponent } from "./components/about-us/about-us.component"
import { ReviewsComponent } from "./components/reviews/reviews.component"
import { SimPlanComponent } from "./components/sim-plan/sim-plan.component"

import { QaSectionComponent } from "./components/qa-section/qa-section.component"

import { CreditReportLandingPageComponent } from "./pages/credit-report-landing-page/credit-report-landing-page.component"

// Account Panel 
import { LoginComponent } from "./components/login/login.component"
import { HelpComponent } from "./components/account-panel/help/help.component"

import { SiteMapComponent } from "./components/site-map/site-map.component"

import { AuthInterceptor } from "./shared/auth/auth.interceptor";
import { ActivateSimComponent } from "./components/account-panel/activate-sim/activate-sim.component";
import { AccountSetupComponent } from "./pages/account-setup/account-setup.component";
import { AccountSetupCompleteComponent } from "./pages/account-setup/account-setup-complete.component";
import { ResetPasswordSetupComponent } from "./pages/reset-password-setup/reset-password-setup.component";
import { SetPasswordComponent } from "./pages/set-password/set-password.component";
import { SharedService } from "./shared/services/shared.service";
import { RecurringPolicyComponent } from "./pages/recurring-policy/recurring-policy.component";
import { StripeRepaymentComponent } from "./components/stripe-repayment/stripe-repayment.component";
import { AgentformComponent } from "./pages/agent-form/agent-form.component";
import { AgentSuccessComponent } from "./pages/agent-success/agent-success.component";
import { AgentPackageSelectorComponent } from "./pages/agent-form/agent-package-selector/agent-package-selector.component";
import { PendingSimComponent } from "./components/account-panel/pending-sim/pending-sim.component";
import { HiddenPricesLandingPageComponent } from "./components/hidden-prices-landing-page/hidden-prices-landing-page.component";
import { SimPlanHiddenPriceComponent } from "./components/hidden-prices-landing-page/sim-plan-hidden-price/sim-plan-hidden-price.component";
import { QaSectionHiddenPriceComponent } from "./components/hidden-prices-landing-page/qa-section-hidden-price/qa-section-hidden-price.component";

import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { PartnersCompanyComponent } from "./components/partners-company/partners-company.component";
import { paymentfaildComponent } from "./pages/payment-failed/payment-faild.component";
import { PendingSimStatusComponent } from "./components/pending-sim-status/pending-sim-status.component";
import { paymenterrorComponent } from "./pages/payment-error/payment-error.component";
import { paymentcancelComponent } from "./pages/payment-cancel/payment-cancel.component";
import { StepperCalculatorComponent } from "./components/stepper-calculator/stepper-calculator.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { MobilesStoreComponent } from "./components/mobiles-store/mobiles-store.component";


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    MobilesStoreComponent,
    NavBarComponent,
    MiniSliderComponent,
    EquifaxContentComponent,
    CreditFileComponent,
    PlanBannerComponent,
    AboutUsComponent,
    ReviewsComponent,
    SimPlanComponent,
    QaSectionComponent,
    LoginComponent,
    HelpComponent,
    SiteMapComponent,
    PartnersCompanyComponent,
    UnsubscribeComponent,
    HeaderComponent,
    StickyFooterComponent,
    RecurringPolicyComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    CallRecordPolicyComponent,
    CallsDataRoamingChargesComponent,
    CodeOfPracticeComponent,
    HowToAndFaqsComponent,
    RefundPolicyComponent,
    FooterComponent,
    JumbotronComponent,
    HowItWorksComponent,
    FrequentlyAskedQuestionsComponent,
    SideDrawerComponent,
    SelectPackageComponent,
    CustomerDetailsComponent,
    PackageDetailsComponent,
    AddressDetailsComponent,
    CardDetailsComponent,
    CompleteComponent,
    SimPlansSelectorComponent,
    FooterBoshhhTempComponent,
    FooterHelloTempComponent,
    PlanFeaturedMobileComponent,
    PlanStandardMobileComponent,
    StripePaymentDetailsComponent,
    SimPlansSelectorMobileComponent,
    SelectedPackageComponent,
    ReturnsPolicyComponent,
    AccountSetupComponent,
    CreditBuilderPlusComponent,
    CreditReportLandingPageComponent,
    AccountSetupCompleteComponent,
    HomeComponent,
    MobilesComponent,
    MobilesDetailComponent,
    ProductDetailComponent,
    OrderFormComponent,
    HeaderSectionComponent,
    paymentexistComponent,
    paymentfaildComponent,
    ActivateSimComponent,
    ResetPasswordSetupComponent,
    SetPasswordComponent,
    StripeRepaymentComponent,
    AgentPackageSelectorComponent,
    AgentformComponent,
    PendingSimComponent,
    WhatThePointComponent,
    PendingSimStatusComponent,
    paymenterrorComponent,
    paymentcancelComponent,
    HiddenPricesLandingPageComponent,
    StepperCalculatorComponent,
    SimPlanHiddenPriceComponent,
    QaSectionHiddenPriceComponent,
    AgentSuccessComponent,
    LoaderComponent
  ],
  imports: [
    RouterLinkWithHref,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    TextMaskModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    MatCardModule,
    CommonModule,
    AppRoutingModule,
    ToastrModule.forRoot(), // ToastrModule added
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    RouterOutlet,
    HttpClientJsonpModule,
    BoshhhModule.forRoute(environment),
  ],
  providers: [
    {provide: 'environment', useValue: environment},
    GlobalService,
    FormGroupDirective,
    SharedService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    DatePipe
  ],
  bootstrap: [AppComponent],
  exports: [

    LandingPageComponent,
    NavBarComponent,
    MiniSliderComponent,
    EquifaxContentComponent,
    CreditFileComponent,
    PlanBannerComponent,
    AboutUsComponent,
    ReviewsComponent,
    SimPlanComponent,
    SimPlanComponent,
    QaSectionComponent,
    LoginComponent,
    HelpComponent,
    SiteMapComponent,
    StickyFooterComponent,
    RecurringPolicyComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    CallRecordPolicyComponent,
    CallsDataRoamingChargesComponent,
    CodeOfPracticeComponent,
    HowToAndFaqsComponent,
    RefundPolicyComponent,
    FooterComponent,
    JumbotronComponent,
    HowItWorksComponent,
    FrequentlyAskedQuestionsComponent,
    SideDrawerComponent,
    HeaderComponent,
    SimPlansSelectorComponent,
    ReturnsPolicyComponent,
    StripeRepaymentComponent,
    AgentPackageSelectorComponent,
    AgentformComponent
  ],
})
export class AppModule { }
