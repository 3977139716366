<div class="faqs pt-5" id="faqs">
  <div class="">
    <div class=" qa-section pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="d-flex justify-content-center mb-3 flex-column still-unsure">
              <img src="/assets/images/question.png" width="100px">
              <h2 class="fw-bolder py-2">Still unsure?</h2>
              <p>You have questions, we have answers.</p>
            </div>  
          </div>  
          <div class="col-md-6 col-sm-6">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item active">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    What is the coverage like with Boshhh?
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">Wherever it can, Boshhh connects to 5G, 4G, and 3G networks. 2G mobile devices are incompatible with Boshhh. Before using your device to access our 5G network, make sure it is 5G-compatible.</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Can I keep my number?
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <p>Contact your network provider to get a PAC code. You can do this via phone, web or by texting “PAC” to 65075. After that, you have 30 days to move your phone number to your new sim. Once the 30 days expires, you will need to retrieve a new code. </p>
                    <p>Thanks to our collab with your current network provider, the transfer will take place by the end of the following working day.</p>
                    <p>If you already have an active Boshhh sim, you won’t experience any service interruption during the transfer but you might need to restart your device for it to take effect.</p>
                  </div>
                </div>
              </div>
              <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Does the cost increase every year?
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    Plans that roll over for 30 days may be cancelled at any time. This means that during the course of your contract, you are never required to accept price hikes. That is what we call BOSHHH.
                  </div>
                </div>
              </div> -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading4">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                    How do I get in contact with you?
                  </button>
                </h2>
                <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    Head to our contact page for various ways to contact us. <a href="https://boshhh.com/register">Click here</a>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading5">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                    Can I leave Boshhh?
                  </button>
                </h2>
                <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    Remember that if you're unsure about leaving you can always speak to us to find out your options. Please do not hesitate to get in touch with our customer support staff if there is anything we can do to help or improve.
                  </div>
                </div>
              </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
    <!-- Credit File Access Package -->

    <!-- <div class="booster-content container d-flex justify-content-center aligc-items-center flex-column pt-5">
      <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-12 md-flex-center phone-image my-2">
          <div id="package_plans-{{i}}" class="package-design-2" *ngFor="let plan of packagePlans; index as i">
      
            <div>
                <div class="plan-block" style="position: relative;"
                  [ngClass]="{'plan-popular': plan.Name === '30GB Data', 'plan-value': plan.Name === '100GB Data'}">
                
        
                  <div>
                    <div class="d-flex  align-items-center" >
                      <img class="me-2 ms-0" src="/assets/images/efx_hr_logo_horizontal.png" style="width: 100px !important;">
                      <div class="d-flex justify-content-around align-items-center ms-2">
                          <p class="m-0 me-2" style="font-size:8px !important">Powered by</p>
                          <img src="/assets/images/logo-inverted.png" style="width: 60px !important;">
                      </div>
                    </div>
                  </div>
                  <div class="mt-25">
                    <h2 [attr.id]="'plan_' + i+1" class="package-name d-flex justify-content-between mt-2">{{ plan.Name }}
                      <img *ngIf="plan.Name   != 'Credit File'" src="/assets/images/boshhh-sim.jpeg" width="50" alt="Boshhh Sim Card" style="position:absolute; top: 10px; right: 10px;">
                    </h2>
                  </div>
                  <div class="d-flex align-items-center">
                    <p class="price">£{{ restrictToTwoDecimals(plan.Price) }}</p>
                    <p class="per-month text-center">Per Month</p>
                  </div>
                  <div *ngIf="plan.Name   == 'Credit File'">
                  
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Track your <b>finances in your credit report</b></p>
                    </div>
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Identify problem accounts, and <b>take steps to minimise</b>  their impact</p>
                    </div>
          
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">See the types of credit reported from your <b>electric bill to your mortgage</b></p>
                    </div>
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">View up to 6 years' history of <b>your repayment performance</b></p>
                    </div>
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Find <b>credit cards and loans </b>to suit you </p>
                    </div>
                  </div>
                  <div *ngIf="plan.Name   != 'Credit File'">
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Get <b>unlimited </b>calls and texts</p>
                    </div>
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Free access to<b> Boshhh Credit Booster</b></p>
                    </div>
        
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Free access to your <img  src="/assets/images/efx_hr_logo_horizontal.png" width="60px"> <b>credit report</b></p>
                    </div>
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Free access to <b>tools to improve your finances</b></p>
                    </div>
                    <div class="specs">
                      <div class="check-img">
                        <img src="/assets/images/check.svg">
                      </div>
                      <p class="text">Free access to <b>Boshhh Smart Match</b></p>
                    </div>
                  </div>
                  <input type="radio" name="package_name" id="package_id-{{ i }}" value="{{ plan.Sku }}">
                  <label for="package_id-{{ i }}" (click)="selectedPlan(plan.Sku)"
                    class="bosh-btn-radio bosh-btn-primary text-center">
                    Select Plan
                  </label>
        
                  <div class="cards d-flex justify-content-center mt-2">
                    <div class="d-flex  justify-content-center">
                      <img class="me-2" src="/assets/images/visa.svg">
                      <img src="/assets/images/mastercard.svg">
                    </div>
                  </div>
        
                </div>
              </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-3 md-flex-center">
          <div class="">
            <p class="m-0">GUARANTEED ACCEPTANCE!</p>
            <h2 class="fw-bold mb-4">Boost your credit score by making on time SIM card payments!</h2>
            <p class="m-0"><b>Boshhh™️ Credit BOOSTER</b> can improve</p>
            <p> your score with Equifax.</p>
            <div class="d-flex flex-wrap md-justify-start xs-justify-center">
              <div class="btn-container py-2">
                <button class="bosh-btn-primary py-2" (click)="redirectToRegister()">CHOOSE MY PLAN  👉</button>
                <div class="cards  d-flex justify-content-center mt-2">
                  <div class="d-flex">
                    <img class="me-2" src="/assets/images/visa.svg">
                    <img src="/assets/images/mastercard.svg">
                  </div>
                </div>
              </div>
              <div class="rating-section py-2">
                <div class="d-flex">
                  <div class="star-contaner d-flex justify-content-between">
                    <img src="/assets/images/star-color.svg" width="20px">
                    <img src="/assets/images/star-color.svg" width="20px">
                    <img src="/assets/images/star-color.svg" width="20px">
                    <img src="/assets/images/star-color.svg" width="20px">
                    <img src="/assets/images/star-color.svg" width="20px">
                  </div>
                  <p class="my-0 h4 ms-3 text-dark f-14">Based on <b> 3,234</b> reviews</p>
                </div>
                <p class="text-dark text-center my-2 f-12">Loved by thousands of people across the UK</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </div> -->

    <!-- Credit File Access Package -->
  </div>
</div>
