<div class="container ">
  <h2>Cookie Policy</h2>

  <h3>About our Cookies</h3>

  <p>
    Cookies are small text files stored in your device when you access most
    websites on the internet.
  </p>

  <p>
    Currently across all Boshhh Ltd websites and customer portals we make use of
    cookies to help manage our websites. This is to also make our websites
    easier to use, support the provision of relevant information and
    functionality to you to improve your visitor experience. The cookies we use
    also provide us with information about how our websites are used so that we
    can make sure they are up-to-date, relevant and error free.
  </p>

  <p>
    By using our website you agree to the use of cookies as described in this
    Cookie Policy. Cookies across our website set by our 3rd party partners or
    us, can be in the form of session cookies (lasting only as long as your
    visit to our website) or persistent cookies. This may use different
    technologies, such as JavaScript.
  </p>

  <p>
    You can choose to manage the cookies we use on our website through your
    internet browser settings at any time. For more information about how to do
    this, and about cookies in general, you can visit
    https://www.allaboutcookies.org. Certain cookies may be set as soon as you
    visit our websites, but you can remove them by using your internet browser
    settings.
  </p>

  <p>
    Please note that preventing cookies is likely to impact the functionality or
    performance of our websites. This could also prevent you from using or being
    offered certain services provided through them. If you choose to prevent the
    use of cookies, we cannot guarantee access to the services provided through
    our websites or be certain on how our websites will perform during your
    visit. Doing so will also affect our ability to update our websites to cater
    for user preferences and improve its performance.
  </p>

  <p>
    If you have any further questions, would like any further clarification
    around our use of cookies or wish to discuss how we are embracing online
    privacy, please see our Privacy Policy
    <a (click)="navigate('/privacy')">https://boshhh.com/privacy</a> or email
    us at privacy@boshhh.com
  </p>

  <h3>Further Information</h3>

  <p>
    Further general information about cookies is available at
    https://www.allaboutcookies.org.
  </p>

  <p>
    For further information about Google Analytics please see
    https://www.google.com/analytics/learn/privacy.html
  </p>

  <p>
    To prevent tracking by Google Analytics across all websites visit
    https://tools.google.com/dlpage/gaoptout.
  </p>

  <h3>What Are Cookies?</h3>

  <p>
    We set out below information to assist your understanding of the cookies
    that we use here at Boshhh Ltd.
  </p>

  <p>
    There are two types of cookies used by Boshhh Ltd which vary by the length
    of time they remain active:
  </p>

  <h3>Session Cookies</h3>

  <p>Set by a site, which is deleted when the visitor closes their browser.</p>

  <h3>Persistent Cookies</h3>

  <p>
    Set by a site, which are stored on the visitor’s device and expire on a
    given date.
  </p>

  <h3>Cookie Groupings</h3>

  <p>
    To make it easier for you to understand the way in which Boshhh Ltd uses
    cookies, they have been grouped into the following categories. The category
    to which a cookie belongs can be seen below:
  </p>

  <h3>Your Visit</h3>

  <p>
    These cookies are used to ensure that your journey through our website is
    smooth. They will typically remember what settings you have applied or any
    preferences that you have set so that they are not forgotten when you click
    through to a different screen.
  </p>

  <h3>Remarketing</h3>

  <p>
    Boshhh Ltd uses third party remarketing services including Google AdWords,
    Google Tag Manager, Google Analytics and AdRoll. These services will place
    cookies on your web browsers in order to serve you ads based on past visits.
    This allows us to market to those that have shown interest in Boshhh Ltd’s
    products and services. We respect your privacy and will not collect any
    identifiable information through the use of Google or any other third party
    remarketing service.
  </p>

  <p>
    To opt out of Google’s use of cookies visit
    https://support.google.com/ads/answer/2662922?hl=en-GB.
  </p>

  <p>
    To opt out of AdRoll’s use of cookies visit
    https://app.adroll.com/optout/safari
  </p>

  <h3>User Preferences</h3>

  <p>
    These cookies are used to remember your individual preferences. On our
    websites this may include a variety of things such as the enabling of
    automatic form completion to displaying prices inclusive or exclusive of
    VAT, what you have selected before or to enable us to make product
    recommendations.
  </p>

  <h3>Analytics</h3>

  <p>
    The software which uses Analytics Cookies is intended to provide Boshhh Ltd
    with an overview of traffic trends throughout our sites using such analytics
    software. These trends may include information such as your chosen browser,
    whether you are accessing using a smartphone or laptop, participation in
    marketing/email campaigns, page views, session length, etc.
  </p>

  <p>No personal or identifiable information is collected or used.</p>

  <h3>How can I check if cookies are enabled?</h3>

  <p>
    Please be aware that if you disable cookies it will not be possible to use
    most if not all of our websites. To check the current status of cookies
    please click on your browser from the list below for step-by-step
    instructions:
  </p>

  <h3>Internet Explorer</h3>

  <ol>
    <li>
      Click on ‘Tools’ at the top of your browser window and select ‘Internet
      options’, then click on the ‘Privacy’ tab.
    </li>
    <li>
      Ensure that your Privacy level is set to Medium or below, which will
      enable cookies in your browser. Settings above Medium will disable
      cookies.
    </li>
  </ol>

  <h3>Google Chrome</h3>

  <ol>
    <li>
      Click on the menu icon near the top-right of your browser window and
      select ‘Settings’.
    </li>
    <li>On the ‘Settings’ screen, click ‘Show advanced settings…’.</li>
    <li>
      Now click the ‘Content settings…’ button. This will display your cookies
      settings. The first option ‘Allow local data to be set (recommended)’
      should be selected.
    </li>
  </ol>

  <h3>Safari</h3>

  <ol>
    <li>
      Click on ‘Safari’ at the top of your browser window and select the
      ‘Preferences’ option.
    </li>
    <li>Click on ‘Security’, then ‘Accept cookies’ and select ‘Always’.</li>
  </ol>

  <h3>Firefox</h3>

  <ol>
    <li>
      Click on the menu icon near the top-right of your browser window and
      select ‘Options’.
    </li>
    <li>
      Select ‘Privacy’, and in the ‘History’ section the drop-down menu labelled
      ‘Firefox will:’ select ‘Remember History’ should be selected.
    </li>
  </ol>

  <h3>Other</h3>

  <p>
    There are a number of other browsers available that will all have their own
    unique way of setting cookies. For assistance with these individual browsers
    please refer to their help sections.
  </p>

  <h3>Can I enable cookies on Boshhh Ltd websites but not on other sites?</h3>

  <p>
    It’s possible to only accept Cookies when you are on Boshhh Ltd’s websites
    and portals by adding our various web addresses to a ‘safe list’. Here’s how
    to do this in different browsers:
  </p>

  <h3>Internet Explorer</h3>

  <ol>
    <li>
      Click on ‘Tools’ at the top of your browser window and select ‘Internet
      options’.
    </li>
    <li>Then click on the ‘Privacy’ tab.</li>
    <li>
      Under the ‘Settings’ menu slide the bar to the top, so that ‘Block All
      Cookies’ is selected.
    </li>
    <li>
      Click the ‘Sites’ button and enter boshhh.com (for example) in the
      ‘Address of website’ bar, then select ‘Allow’.
    </li>
  </ol>

  <h3>Google Chrome</h3>

  <ol>
    <li>
      Click on the menu icon near the top-right of your browser window and
      select ‘Settings’.
    </li>
    <li>On the ‘Settings’ screen, click ‘Show advanced settings…’.</li>
    <li>
      Now select ‘Block sites from setting any data’ then select the ‘Manage
      exceptions’ button.
    </li>
    <li>
      In the ‘Hostname Pattern’ field type boshhh.com (for example) and ensure
      that the ‘Behaviour’ is set to ‘Allow’.
    </li>
  </ol>

  <h3>Safari</h3>

  <ol>
    <li>
      Click on ‘Safari’ at the top of your browser window and select the
      ‘Preferences’ option.
    </li>
    <li>
      Click on ‘Security’, then ‘Accept cookies’ and select ‘Only from site you
      navigate to’.
    </li>
  </ol>

  <h3>Firefox</h3>
  <ol>
    <li>
      Click on the menu icon near the top-right of your browser window and
      select ‘Options’.
    </li>
    <li>
      In the first drop down menu labelled ‘Firefox will:’ select ‘Use custom
      settings for history’.
    </li>
    <li>Click the ‘Exceptions…’ button</li>
    <li>
      In the ‘Address of web site’ field type boshhh.com (for example) and click
      ‘Allow’.
    </li>
  </ol>

  <h3>Other</h3>

  <p>
    There are a number of other browsers available that will all have their own
    unique way of setting cookie exceptions. For assistance with these
    individual browsers please refer to their help sections.
  </p>

  <h3>Further Questions</h3>

  <p>
    If you have any questions or concerns about any of our cookies or policies,
    please feel free to email us at privacy@boshhh.com.
  </p>
</div>
