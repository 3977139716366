<div class="credit-file text-center my-4" [ngClass]="classFlag ? '' : 'container'">
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex align-items-center">
        <div>
          <h2 class="fw-bold">
            Instant access to your <img  src="/assets/images/efx_hr_logo_horizontal.png" width="165px"> <span class="text-blue">credit report</span> 24 hours a day 
          </h2>
          <p>with live access to your credit report you will receive cool tips on how to improve your score and understand your credit file</p>
      </div>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <div class="boshhh-dashboard-image">
          <img src="/assets/images/credit-score-app.png" width="250px" alt="credit-report-screenshot">
          <div class="d-flex apps-logo justify-content-center my-4">
            <a class="mx-2" href="https://apps.apple.com/us/app/boshhh/id6446495097">
              <img src="/assets/images/appstore-logo.png" alt="appstore">
            </a>
            <a class="mx-2" href="#">
              <img src="/assets/images/playstore-logo.png" alt="playstore">
            </a>
          </div>
        </div>    
       
      </div>
    </div>
    

    
  </div>
</div>