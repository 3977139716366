<nav class="navbar navbar-expand-lg bg-light px-3" style="display: block;">
  <div class="bg-yellow text-center">
    <h3 class="text-dark m-0">14 day money back guarantee after SIM activation</h3>
    <!-- <p class="text-center text-dark h4 m-0">Limited time only</p> -->
  </div>
  <div class="container">    
    <button class="navbar-toggler" (click)="checkclass()" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <img src="/assets/images/burger-menu.svg"  id="burger-menu" alt="burger-menu">
    </button>
    <a class="navbar-brand md-1"  href="#" (click)="navigate('/')">
      <img src="/assets/images/logo-inverted.png" alt="boshhh-logo">
    </a>
    <ng-container *ngIf="!isAdminPanel; else logouticon">
    <!-- <a class="navbar-icon-user md-3" (click)="navigate('/login')"> -->
    <a class="navbar-icon-user md-3 cursorptr" (click)="navogatecondition()">
      <img src="/assets/images/user.svg" id="login-icon" alt="user-icon">
    </a>
    <div class="collapse navbar-collapse md-2" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item " (click)="hideNavBar()">
          <a class="nav-link" [routerLink]="['/our-story']">Our Story</a>
        </li>
        <li class="nav-item " (click)="hideNavBar()">
          <a class="nav-link" href="#boshhh-journey">How it works</a>
        </li>
        <li class="nav-item " (click)="hideNavBar()">
          <a class="nav-link"  role="button" [routerLink]="['/register']">View plans</a>
        </li>
        <li class="nav-item" (click)="hideNavBar()">
          <a  [routerLink]="['/faqs']"  class="nav-link" >FAQ’s</a>
        </li>
        <!-- <li class="nav-item " (click)="hideNavBar()">
          <a class="nav-link"  role="button" [routerLink]="['/company/partners']">Our Partners</a>
        </li>
        <li class="nav-item " (click)="hideNavBar()">
          <a class="nav-link"  role="button" [routerLink]="['/company/affiliate']">Affiliates</a>
        </li> -->
        
        
        <li class="nav-item activate-sim" (click)="hideNavBar()">
          <a class="nav-link" [routerLink]="['/activate']">Activate SIM</a>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-template #logouticon>
    <a class="navbar-icon-user md-3 cursorptr" (click)="logout()">
      <img src="/assets/images/box-arrow-right.svg" height="30px" alt="right-arrow">
      </a>
  </ng-template>
  
  </div>
</nav>

