import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-banner',
  templateUrl: './plan-banner.component.html',
  styleUrls: ['./plan-banner.component.css']
})
export class PlanBannerComponent implements OnInit {

  constructor() { }

  classFlag = false;
    ngOnInit(){
     if (screen.width <= 768) {
      this.classFlag = true;
    } else {
      this.classFlag = false;
    } 
    console.log(this.classFlag);
  }

}
