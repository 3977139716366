<div class="plan-banner" [ngClass]="classFlag ? '' : 'container'">
  <div class="container">
    <div class="d-flex  row">
      <div class="col-md-4 phone-girl md-flex-center">
        <img src="/assets/images/phone-girl.png" alt="phone-girl">
      </div>
      <div class="col-md-8  md-flex-center md-flex-center">
        <div class="banner-content">        
          <p class="my-3">Join Boshhh & Enjoy</p>
          <h4 class="fw-bold h3 pb-1 heading">Unlimited Calls & Texts</h4>
          <div class="d-flex py-2" [ngClass]="classFlag ? '' : 'row'">
            <div  [ngClass]="classFlag ? '' : 'col-md-4 col-sm-4'">
              <h4 class="h3 fw-bold heading">10GB</h4>
              <p class="mb-0">Personal Hotspot</p>
            </div>
            <div  [ngClass]="classFlag ? '' : 'col-md-4 col-sm-4'">
              <h4 class="h3 fw-bold heading">30GB</h4>
              <p class="mb-0">of High Speed 5G</p>
            </div>
            <div  [ngClass]="classFlag ? '' : 'col-md-4 col-sm-4'">
              <h4 class="h3 fw-bold heading">100GB</h4>
              <p class="mb-0">Worldwide roaming</p>
            </div>
          </div>
          
           
          <div class="d-flex align-items-center">
            <div class="btn-container pb-2 me-3">
              <div class="btn-container py-2" [routerLink]="['/register']">
                <button class="bosh-btn-primary">APPLY NOW  👉</button>
              </div> 
            </div>
    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>