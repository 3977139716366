import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TotalProcessingService } from '../shared/services/total-processing.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentResolverService {
  responseData: any;
  
  constructor(private totalProcessingService: TotalProcessingService) {
  }
  async resolve(route: ActivatedRouteSnapshot) {
    var x = route.queryParams['id']
      if(x != null && x !== undefined){
        this.responseData = this.transSearchByPaymentId(x);
        return this.responseData
      }
      return
  }

   async transSearchByPaymentId(id: any) {
    var pkgid = localStorage.getItem('pkgid')
    this.responseData = await this.totalProcessingService.GetPaymentStatus(id, pkgid).toPromise();
    return this.responseData;
}
    
}