import { Component, OnInit, Inject, Renderer2, ViewChild, QueryList } from '@angular/core';
import { Router, NavigationStart } from "@angular/router";
import { SideMenuComponent } from '../account-panel/side-menu/side-menu.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  isAdminPanel: boolean = false
  production: boolean = false;
  constructor(
    private router: Router,
    private renderer: Renderer2,
    // @ts-ignore
    @Inject('env') private env
  ) {
    this.production = this.env.production

    router.events.subscribe((val) => {
      if(val instanceof NavigationStart){
        if (val.url.startsWith('/account')) {
          this.isAdminPanel = true;
        } 
        else{
          this.isAdminPanel = false
        }
      }
  })
  }

  ngOnInit(): void {

  }
  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }

  navogatecondition(){
      var token = localStorage.getItem('UserContext') 
      if(token !== null){
        this.router.navigate(['/account/dashboard'])
      }
      else{
        this.router.navigate(['/login'])
      }
    
  }

  checkclass(){
    if(this.router.url.includes('/account')){
      var dashboardclass = document.querySelector('.side-panel-menu')
      this.renderer.addClass(dashboardclass,'menu-active')
    }
  }

  hideNavBar() {
    var navBar = document.querySelector('.navbar-collapse')
    this.renderer.removeClass(navBar,'show')
    this.renderer.addClass(navBar,'hide')
  }

  logout(){
    localStorage.removeItem('UserContext')
    localStorage.removeItem('mob')
    localStorage.removeItem('name')
    this.router.navigate(['/login'])
  }
}
