import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html'
})
export class HomeComponent {
  production: boolean = false;
  constructor(private router: Router,
    // @ts-ignore
    @Inject('env') private env) {
      this.production = this.env.production
    }

  doSelectPackage(selectedPackageId: string): void {
    this.router.navigate(['/create-account/', { id : selectedPackageId }]).then();
  }
  public navigate (route: string): void {
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
