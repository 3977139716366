<div class="product">
  <div class="container">
    <div class="row">
      <div class="block col-lg-7 col-md-7 col-sm-7">
        <div class="">
            <div class="product-card">
              <div class="position-relative">
                <div class="discount">
                  <span><span class="fw-bold fs-20 me-1">{{ product.discount }}%</span><span>OFF</span></span>

                </div>
              </div>
              <h2 class="h2 text-loud-bold mt-5">{{ product.name }}</h2>
              <div class="product-detail d-flex justify-content-between align-items-center">
                <div class="img_block">
                  <img src="/assets/images/{{ product.imgURL }}.png">
                </div>
                <div class="product-details">
                  <div class="py-2 my-2 colors_pallete d-flex align-items-center">
                    <a href="#">
                      <div class="bg-success"></div>
                    </a>
                    <a href="#">
                      <div class="bg-danger"></div>
                    </a>
                    <a href="#">
                      <div class="bg-primary"></div>
                    </a>
                    <a href="#">
                      <div class="bg-secondary"></div>
                    </a>
                    <a href="#">
                      <div class="bg-info"></div>
                    </a>
                  </div>

                  <div class="installment">
                    <p class="m-0 text-gray fs-12">or 24 monthly payments</p>
                    <div class="mt-3 d-flex justify-content-between">
                      <div>
                        <p class="fw-bolder"><b> £{{ product.monthlyAmount }} </b> <br>
                          <span class="text-gray">a month</span>
                        </p>
                      </div>
                      <div>
                        <p class="fw-bolder"><b> £47.00 </b> <br>
                          <span class="text-gray">{{ product.upfrontAmount }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="stock my-3">
                    <img class="me-2" src="/assets/images/tick.png" width="18px">
                    <span>Available in stock</span>
                  </div>
                </div>
              </div>
              <div class="button-container">
                <button class="btn btn-primary my-3">View Details</button>
              </div>
            </div>
</div>
      </div>
      <div class="block p-3 m-auto bg-gray-light d-flex align-item-center flex-column justify-content-between col-lg-5 col-md-5 col-sm-5 ">
        <div>
          <h3>You need to login or register to get your handset</h3>
          <button class="btn btn-primary btn-green w-100">Login</button>
          <p>Or </p>
          <button class="btn btn-primary btn-green w-100">Register</button>
        </div>
      </div>
    </div>
  </div>
</div>
