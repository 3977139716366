import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TotalProcessingService {

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }


  public createPaymentProcessing(prepareCheckOut: any): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/TotalProcessing/PrepareCheckouts`, prepareCheckOut,
        this.httpOptions
      );
  }

  public oneClickPayment(prepareCheckOut: any): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/TotalProcessing/OneClickPayment`, prepareCheckOut,
        this.httpOptions
      );
  }

  public GetPaymentStatus(id: any, simplanid: any): Observable<any> {
    return this
      .http
      .get<any>(`${this.env.pipedriveApi}/api/TotalProcessing/GetPaymentStatus?id=${id}&productId=${simplanid}`,
        this.httpOptions
      );
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };
}
