import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../shared/models/Products';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  @Input() product: Product;
  constructor() {

  }
  ngOnInit() {
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
