
  <div class="mobile-section">
    <div class="container">
      <div class="row xs-column-reverse py-3">
        <div class="col-md-6 col-sm-6 d-flex align-items-center">
          <div class="mt-2">
            <h1>Phones you love</h1>
            <p>Shop for the latest, top brands like iPhone and Samsung. These products are professionally tested and
              certified, and all come with a 6-month warranty. Get that!
            </p>
            <!-- <button class="btn btn-primary">Pre-Order Phone</button> -->
          </div>
        </div>
        <div class="col-md-6 col-sm-6 slider-img d-flex justify-content-center">
          <img class="hero-img" src="/assets/images/hero-img.png">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="features">
      <ul class="row ps-0">
        <li class="d-flex align-items-center col-lg-3 col-md-3 col-sm-6">
          <img src="/assets/images/warranty.png" alt="shield" srcset="">
          <p class="my-1">12 month warranty</p>
        </li>
        <li class="d-flex align-items-center col-lg-3 col-md-3 col-sm-6">
          <img src="/assets/images/sustainable.png" alt="settings" srcset="">
          <p class="my-1">A more sustainable option</p>
        </li>
        <li class="d-flex align-items-center col-lg-3 col-md-3 col-sm-6">
          <img src="/assets/images/truck.png" alt="delivery" srcset="">
          <p class="my-1">Next day shipping</p>
        </li>
        <li class="d-flex align-items-center col-lg-3 col-md-3 col-sm-6">
          <img src="/assets/images/battery.png" alt="battery" srcset="">
          <p class="my-1">80% plus battery health</p>
        </li>
      </ul>
    </div>
    <div class="product ">
      <div class=" row">
        <div class="block col-lg-4 col-md-6 col-sm-6" *ngFor="let product of this.products">
          <div class="product-card mt-2">
            <div class="position-relative">
              <div class="discount">
                <span><span class="fw-bold fs-20 me-1">{{ product.discount }}%</span><span>OFF</span></span>
              </div>
            </div>
            <p class="h3 fw-bolder mt-3 mb-0">{{ product.name }}</p>
            <div class="product-detail d-flex justify-content-around align-items-center">
              <div class="img_block">
                <img src="/assets/images/{{ product.imgURL }}.png">
              </div>
              <div class="product-details">
                <div class="py-2 my-2 colors_pallete d-flex align-items-center">
                    <div class="bg-success"></div>
                    <div class="bg-danger"></div>
                    <div class="bg-primary"></div>
                    <div class="bg-secondary"></div>
                    <div class="bg-info"></div>
                </div>

                <div class="installment">
                  <p class="m-0 text-gray fs-12">12 monthly payments</p>
                  <div class="mt-3 d-flex justify-content-between">
                    <div>
                      <p class="fw-bolder"><b> £{{ product.monthlyAmount }} </b> <br>
                        <span class="text-gray">a month</span>
                      </p>
                    </div>
                    <div>
                      <p class="fw-bolder"><b> £{{ product.upfrontAmount}}</b> <br>
                        <span class="text-gray">Upfront</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="stock my-1">
                  <img class="me-2" src="/assets/images/checkbox.png" width="18px">
                  <span>Available in stock</span>
                </div>
              </div>
            </div>
            <button class="btn bosh-btn-primary my-3"> Pre-Order Phone</button>
          </div>
        </div>
      </div>
    </div>
</div>