<div class="credit-file text-center my-4 container">
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex align-items-center">
        <div>
          <h2 class="fw-bold">
            Credit Report access and <span class="text-blue">cool tips</span>  for the life of your contract
          </h2>
          <p>Take control of your financial footprint or simply use Boshhh™️ to keep on top of your credit score.</p>
          <button class="bosh-btn-primary  text-center my-3" (click)="redirectToRegister()">Get Started</button>
      </div>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <div class="file-card">
          <img src="/assets/images/credit-score.png" width="100%">
        </div> 
        
        
      </div>
    </div>
    
  </div>
</div>