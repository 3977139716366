<div class="container  py-5" id="container">
    <div class="login-container row">
      <div class="col-md-6 col-sm-6 overlay-panel md-flex-center">
        <div class="overlay-right text-center py-5">
          <h1>Hello, Friend!</h1>
          <p>Activate your sim and start journey with Boshhh™️</p>
          <p><small>Loved by thousands of people across the uk</small></p>
          
        </div>
      </div>  
      <div class="col-md-6 col-sm-6 md-flex-center py-5">
        <div class="form-container w-100">
          <form [formGroup]="activesim" (ngSubmit)="activate()" class="d-flex flex-column text-center">
            <h1>Activate Sim</h1>
            <input type="email" placeholder="Enter your registered email" formControlName="email" name="user_email"  required/>
            <input type="text" placeholder="Enter New Number" formControlName="sim" name="cli_nubmer"  pattern="\d*" maxlength="11" minlength="11" required/>
            <small class="text-start">Enter 11 digits Number on SIM card</small>
            <button type="submit" class="mt-2 bosh-btn-primary" [disabled]="!this.activesim.valid" >Activate Sim</button>
          </form>
            <p class="text-center mt-3">Once you have activated your sim click below to access your credit file</p>
          <div class="btn-container py-2 px-1 w-100" >
            <button class="bosh-btn-primary bosh-border-btn" (click)="toLogin()">Access credit file</button>
          </div>
        </div>  
      </div>
      
      
    </div>
  
  </div>