import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IRegisterModel } from "../register.model";
import { IpAddressService } from "../../../shared/services/ip-address.service";
import { PipedriveService } from "../../../shared/services/pipedrive.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TotalProcessingService } from "../../../shared/services/total-processing.service";
import { ToastrService } from "ngx-toastr";
import { MbillsService } from "../../../shared/services/mbills.service";
import { BoshhhStripeService } from "../../../shared/services/boshhh-stripe.service";
import { SharedService } from "../../../shared/services/shared.service";
import { EquifaxService } from "../../../shared/services/Equifax.service";

declare const fbq: any; // 1. add an ambient declaration

@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css'],
})
export class paymentsuccessComponent implements OnInit {
  chargeId: any;
  customerId: any;
  orderAmount: any;
  checkoutId: any;
  paymentID:any;
  registerModel: IRegisterModel;
  production: any;
  customerEmail: string = '';
  parsedObj: any
  selectedPlan: any;
  creditFilePackageId =  '4661';
  public isCreditFileSelected:boolean = false;
  paymentReceived: string = ''
  thankyou: string = ''
  greet: string = ''
  whatnext: string = ''
  message: string = ''
  btnText: string = ''
  isURLChecked: string = ''
  obStatus: boolean = false
  formData: any;

  @ViewChild('formref') form: ElementRef;
  @ViewChild('errorModal') errorModal: ElementRef;

  token: any;
  actionurl: string;

    
    constructor(
      private route: ActivatedRoute,
      private totalProcessingService: TotalProcessingService,
    private toastr: ToastrService,
    private pipedriveService: PipedriveService,
    private mbillsService: MbillsService,
    private boshhhStripeService: BoshhhStripeService,
    private sharedService: SharedService,
      private equifaxService: EquifaxService,
      private _totalProcessingService: TotalProcessingService,
      private  _renderer2: Renderer2,
      private router: Router,
      
    // @ts-ignore
    @Inject('env') private env
    ) {
      this.production = this.env.production
      this.registerModel = {pipedriveCustomerDetailModel : {}}
      this.isURLChecked = JSON.parse(localStorage.getItem('IsUrlChecked') || '{}');
      
        this.paymentReceived = localStorage.getItem('paymentReceived') || '{}';
        this.thankyou = localStorage.getItem('thankyou') || '{}';
        this.greet = localStorage.getItem('greet') || '{}';
        this.whatnext = localStorage.getItem('whatnext') || '{}';
        this.message = localStorage.getItem('message') || '{}';
        this.btnText = localStorage.getItem('btnText') || '{}';
        this.formData = {}
        
        router.events.subscribe((val) => {
          if(val instanceof NavigationEnd){
            var script = document.querySelector('.awin')
            script?.remove()
            if (!val.url.startsWith('/paymentsuccess')) {
              localStorage.removeItem('pipedrive')
              localStorage.removeItem('mbillsobj')

              // Meta tracking
              var metaParent = document.getElementsByClassName("meta-pixel")[0];
              // Remove child if any
              if (metaParent.childElementCount > 0)
              {
                metaParent.removeChild(metaParent.children[0]);
              }
            } 
          }
      })
    
    }    

  ngOnInit(): void {
  this.getSelectedPlan();

  document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
    this.route.queryParams.subscribe(x => {
      this.chargeId = x['chargeId']
      this.customerId = x['custId']
      this.orderAmount = x['orderamount']
      this.checkoutId = x['id']
    })
    
    this.isURLChecked = JSON.parse(localStorage.getItem('IsUrlChecked') || '{}');
    if(this.checkoutId){
      if(this.isURLChecked ==  "true"){ 
        this.transSearchByPaymentId();
      }
    }
  }

// OB Funcs
  getOpenToken() {
    return this.equifaxService.getOpenBankingToken().subscribe(x => {
      this.token = x.access_token
      if(this.production == true){
        this.actionurl = `https://signup.consents.online/api/token/redirect`
      } else {
        this.actionurl = `https://signup.consents-dev.online/api/token/redirect`
      }
    })
  }

  btnclick(){
    this.form.nativeElement.submit()
  }

  public getSelectedPlan(){
    this.selectedPlan = this.sharedService.getSelectedPackagePlan();
    console.log(this.selectedPlan);
    if (this.selectedPlan == this.creditFilePackageId) {

        this.isCreditFileSelected = true;
    }
    localStorage.removeItem('packagePlan');
  }

  createTotalProcessingPayment(){
    var email = this.registerModel.pipedriveCustomerDetailModel.emailAddress
    localStorage.setItem('email',email== undefined?'': email)
    var obj= {
      amount: Number(this.registerModel.pipedriveCustomerDetailModel.value).toFixed(2).toString(),
      Email: this.registerModel.pipedriveCustomerDetailModel.emailAddress
    }
    this._totalProcessingService.createPaymentProcessing(
      obj).subscribe(resp => {
      this.checkoutId = resp.id
      let script = this._renderer2.createElement('script');
      
      script.src = `${this.env.totalProcessingIntent}/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`;
      this._renderer2.appendChild(document.body, script);
    })

  }

  transSearchByPaymentId(): void {
    var mbillsobj = localStorage.getItem('mbillsobj')
    var pipedriveobj = localStorage.getItem('pipedrive')
             if(pipedriveobj != null){
              var parsed = JSON.parse(pipedriveobj)
              this.registerModel.pipedriveCustomerDetailModel = parsed
             }
    var data = this.mbillsService.getsetregisterModell()
    const id = this.checkoutId;
    
    this.totalProcessingService.GetPaymentStatus(id,this.registerModel.pipedriveCustomerDetailModel.simPlanId ).subscribe(result => {
       if (result.result?.code  === '000.000.000' || result.result?.code === '000.100.110') {
        let value = result.customer.amount
        fbq('track', 'Purchase', {value: value, currency: 'GBP'});
        localStorage.setItem("IsUrlChecked", JSON.stringify('false')); 
        var script = document.createElement("script"); // create a script tag
        script.setAttribute("class","awin");
        script.innerHTML = "dataLayer.push({'transactionTotal':\""+this.registerModel.pipedriveCustomerDetailModel.value+"\", 'transactionCurrency': \"GBP\", 'transactionID': \""+result.id+"\",'transactionPromoCode': \"\",'event': \"awin.dl.ready\"});";

        document.head.appendChild(script);
        
        // Meta tracking
        var metaParent = document.getElementsByClassName("meta-pixel")[0];
        // Remove child if any
        if (metaParent.childElementCount > 0)
        {
          metaParent.removeChild(metaParent.children[0]);
        }
        // Add Tag
        var metaChild = metaParent.appendChild(document.createElement('div'));
        metaChild.innerHTML+="fbq('track', 'Purchase',{value:"+this.registerModel.pipedriveCustomerDetailModel.value+", currency:'GBP'});"

            this.pipedriveService.getUserById(result.customer.email).subscribe(x => {
                if(x.amlResult == 1){
                  this.obStatus = true
                  this.formData.externalRef = x.emailAddress
                  this.formData.title = x.title
                  this.formData.firstName = x.firstName
                  this.formData.lastName = x.lastName
                  this.formData.dateOfBirth = x.dateOfBirth
                  this.formData.email = x.emailAddress
                  this.formData.telephone = x.telephone
                  this.formData.billingPostcode = x.billingPostcode
                  this.formData.city = x.billingCity
                  this.formData.billingStreet1 = x.billingStreet1
                  this.getOpenToken()
                  this.paymentReceived = 'Payment Received'
                  this.thankyou = 'Thank you for choosing Boshhh as your network'
                  this.greet = 'Welcome to Boshhh'
                  this.whatnext = 'What happens next'
                  this.message = 'Congratulations your credit has been approved. Connect your bank now to access your report'
                  this.btnText = 'Connect To Bank'
                } else {
                  this.paymentReceived = 'Payment Received'
                  this.thankyou = 'Thank you for choosing Boshhh as your network'
                  this.greet = 'Welcome to Boshhh'
                  this.whatnext = 'Congratulations! Welcome to boshhh! well done for taking the first steps to improve your credit score and have the life you deserve!'
                  this.message = 'In the next few days you will receive your new boshhh sim card in the post Along with a easy guide on how to activate your sim, move your number over and start your credit building journey on our app!'
                  this.btnText = 'Back to Home'
                }
              })
          
          if(this.production == true){
            this.registerModel.pipedriveCustomerDetailModel.stageId = 26
            }else{
            this.registerModel.pipedriveCustomerDetailModel.stageId = 26
            }
            this.registerModel.pipedriveCustomerDetailModel.isPaid = true
            this.orderAmount = result.amount
            // this.registerModel.pipedriveCustomerDetailModel.paymentAmount = Number(result.amount)
          this.registerModel.pipedriveCustomerDetailModel.paymentStatus = result.result.description
          this.registerModel.pipedriveCustomerDetailModel.isPaid = true
          this.registerModel.pipedriveCustomerDetailModel.paymentTime = result.timestamp
          this.registerModel.pipedriveCustomerDetailModel.value = Number(this.registerModel.pipedriveCustomerDetailModel.value)
          this.toastr.success('Payment Status', result.result?.description);  

          this.paymentReceived = 'Payment Received'
                  this.thankyou = 'Thank you for choosing Boshhh as your network'
                  this.greet = 'Welcome to Boshhh'
                  this.whatnext = 'What happens next'
                  this.message = 'You will receive a URL to your current number in order to activate your new Boshhh SIM and setup your account. We have also sent your Boshhh purchase to your email'
                  this.btnText = 'Back to Home'
   
      }
      else {
        localStorage.setItem("IsUrlChecked", JSON.stringify('true')); 
        this.paymentReceived = 'Payment Failed'
        this.thankyou = 'Please try again to choose Boshhh as your network'
        this.greet = 'Payment Denied'
        this.whatnext = 'Reason of Payment failure'
        this.btnText = 'Back to Home'
        this.message = result.result.description
        this.orderAmount = result.amount
        this.createTotalProcessingPayment()
        this.errorModal.nativeElement.click()
        if(this.production == true){
          this.registerModel.pipedriveCustomerDetailModel.stageId = 42
          }else{
          this.registerModel.pipedriveCustomerDetailModel.stageId = 42
          }
          // this.registerModel.pipedriveCustomerDetailModel.paymentAmount = Number(result.error.payment_intent?.amount)
          this.registerModel.pipedriveCustomerDetailModel.paymentStatus = result.result.description
          this.registerModel.pipedriveCustomerDetailModel.paymentTime = ''
          this.registerModel.pipedriveCustomerDetailModel.value = Number(this.registerModel.pipedriveCustomerDetailModel.value)

          var x = localStorage.getItem('email')
          if(x!==null){
            this.registerModel.pipedriveCustomerDetailModel.emailAddress = x
          }
          
          // todo: return user with a payment error.
          this.pipedriveService.paymentDeclined(this.customerEmail)
            .subscribe(result => {
            });
        this.toastr.error('Payment Status', 'Error in processing payment');
      }
      localStorage.setItem("paymentReceived", (this.paymentReceived).toString()); 
      localStorage.setItem("thankyou",(this.thankyou).toString()); 
      localStorage.setItem("greet", (this.greet).toString()); 
      localStorage.setItem("whatnext",(this.whatnext).toString()); 
      localStorage.setItem("message",(this.message).toString()); 
      localStorage.setItem("btnText",(this.btnText).toString()); 
    });
}

}
