<div class="demo-card-wide mdl-card mdl-shadow--2dp">
  <div>
    <h2 class="mdl-card__title-text">Did we step into the kitchen?</h2>
    <h6 class="mdl-card__title-text">We heard you would like to Unsubscribe Boshhh marketing</h6>
  </div>

  <!-- Contact Form -->
  <form [formGroup]="unsubscribeFormGroup">
    <mat-form-field appearance="outline">
      <mat-label>Enter Your Email:</mat-label>
      <input matInput formControlName="email" type="email" required>
    </mat-form-field>
    <div class="text-end btn-next">
      <button class="bosh-btn-primary mt-3" (click)="unsubscribeEmail()" mat-button type="submit">Set Password</button>
    </div>
  </form>
</div>