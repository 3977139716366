import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignalRService } from '../../shared/services/SignalR.service';

@Component({
  selector: 'app-agent-success',
  templateUrl: './agent-success.component.html',
  styleUrls: ['./agent-success.component.css']
})
export class AgentSuccessComponent implements OnInit {

  activeStage: number
  email: string;
  leadCreated: boolean
  linkSent: boolean
  linkOpened: boolean
  PaymentSucccess: boolean
  PaymentFailed: boolean

  constructor(private signalRService: SignalRService,
    private route: ActivatedRoute) {
      this.email = this.route.snapshot.queryParams['email'];
    }

  ngOnInit() {
    this.listenenToPipedriveEvents()
  }

  listenenToPipedriveEvents(){
    this.signalRService.startConnection()
    this.signalRService.listeningToPipeDriveEvent()
    this.signalRService.getDataAsObservable().subscribe(x => {
      if(x.email === this.email){
        console.log(x)
        if(x.stage === 1){
          this.leadCreated = true
        } else if(x.stage === 2){
          this.linkSent = true
        } else if(x.stage === 3){
          this.linkOpened = true
        } else if(x.stage === 4){
          this.PaymentFailed = false
          this.PaymentSucccess = true
        } else if(x.stage === 5){
          this.PaymentSucccess = false
          this.PaymentFailed = true
        }
      }
    })
  }
}
