<form [formGroup]="addressFormGroup" class="address-form">
  <ng-template matStepLabel>Bash:</ng-template>
  <div class="row align-items-end">
    <div class="col-md-6 mb-3">
      <mat-form-field appearance="outline">
        <mat-label>Postcode:</mat-label>
        <input matInput formControlName="billingPostcode" maxlength="8" pattern="^[A-Za-z0-9_ ]*" style="text-transform:uppercase" required>
        <mat-error style="margin-top: 16px !important" *ngIf="this.addressFormGroup.get('billingPostcode')?.invalid">{{getErrorMessage(this.addressFormGroup.get('billingPostcode'),'billingPostcode')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 pb-2">
      <a
        mat-button
        color="primary"
        class="col-12 mt-1 mb-3 btn-postcode-lookup"
        (click)="this.doPostcodeLookup()">Postcode Lookup</a>
    </div>
  </div>
  <div *ngIf="loading$ | async" class="mx-auto d-flex justify-content-center load">
    <div style="background: #fff;width: 200px;height: 70px;border-radius: 7px;">
      <img class="loader-svg" src="assets/images/Bean Eater-1s-200px.svg" alt="loading..." />
    </div>
  </div>
  <a
    mat-button
    
    class="col-12 fw-bold manual-address-section"
    *ngIf="!showAddressDetails" (click)="doEnterAddressManually()">or Enter address manually</a>

  <div *ngIf="showAddressDetails">
    <div class="col-12 p-0">
      <div *ngIf="!manualStreet">
        <mat-form-field  appearance="outline">
          <mat-label>Address line 1:</mat-label>
          <mat-select #mySelect  formControlName="billingStreet1">
            <mat-option *ngFor="let a of addressLookupResponse.thoroughfares" [value]="a.name">{{a.name}}</mat-option>
          </mat-select>
        <mat-error style="margin-top: 16px !important" *ngIf="this.addressFormGroup.get('billingStreet1')?.invalid">{{getErrorMessage(this.addressFormGroup.get('billingStreet1'),'billingStreet1')}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field *ngIf="manualStreet" appearance="outline">
          <mat-label>Address line 1:</mat-label>
          <input matInput formControlName="billingStreet1">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Address line 2:</mat-label>
          <input matInput formControlName="billingLocality">
        </mat-form-field >
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Town/City:</mat-label>
          <input matInput formControlName="billingCity">
        <mat-error style="margin-top: 16px !important" *ngIf="this.addressFormGroup.get('billingCity')?.invalid">{{getErrorMessage(this.addressFormGroup.get('billingCity'),'billingCity')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>County:</mat-label>
          <input matInput formControlName="billingCounty">
        <mat-error style="margin-top: 16px !important" *ngIf="this.addressFormGroup.get('billingCounty')?.invalid">{{getErrorMessage(this.addressFormGroup.get('billingCounty'),'billingCounty')}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>


</form>
