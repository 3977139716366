<div class="about-us py-3">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="block d-flex justify-content-center flex-column text-center">
          <img src="assets/images/shake-hand.png" alt="gaurentee-acceptance">
          <p class="">100% guaranteed acceptance</p>
          <h2 class="fw-bold">Switch, keep your number, boost your score!</h2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="block d-flex justify-content-center flex-column text-center">
          <img src="assets/images/mobile-wifi.png" alt="mobile-wifi">
          <p class="">Our coverage is the bomb!</p>
          <h2 class="fw-bold"> We are unbeaten for coverage in the UK!</h2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="block d-flex justify-content-center flex-column text-center">
          <img src="assets/images/meter.png" alt="meter">
          <p class="">12 months to better credit</p>
          <h2 class="fw-bold">We update credit reference agencies on your payments</h2>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="block d-flex justify-content-center flex-column text-center">
          <img src="assets/images/mobile-shake.png">
          <p class="">WIDE RANGE OF DEVICES</p>
          <h2 class="fw-bold">Find the right mobile device for you</h2>
        </div>
      </div> -->
    </div>
  </div>
</div>