import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import {
  IAddressLookupResponse
} from "../../../../shared/components/account-stepper/address-details/address-lookup-response.model";
import { BehaviorSubject } from "rxjs";
import { AddressLookupService } from "../../../../shared/services/address-lookup.service";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent implements OnInit {
  @ViewChild('mySelect') mySelect : any;
  @Input() formGroupName: string;
  manualStreet: boolean = false;
  showAddressDetails: boolean;
  addressLookupResponse: IAddressLookupResponse;
  addressFormGroup: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  postcodeCheck: boolean = true;
  postcodeerror: string;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private fb: FormBuilder,
    private addressLookupService: AddressLookupService
  ) {}

  ngOnInit(): void {
    this.addressFormGroup = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.initialFormSetup();
  }

  initialFormSetup(): void {
    this.showAddressDetails = false;
    this.manualStreet = false;

    this.addressLookupResponse = {
      county: '',
      locality: '',
      town: '',
      postCode: '',
      thoroughfares: [{
        name: ''
      }]
    }
    this.addressLookupResponse.thoroughfares = [{
      name: ''
    }
    ];
  }

  doEnterAddressManually(): void {
    this.showAddressDetails = true;
    this.manualStreet = true;
  }

  doEnterStreetManually(): void {
    this.manualStreet = true;
    this.addressFormGroup.controls['billingStreet1'].setValue('');
  }

  getErrorMessage(control: any, name: string){
    if(!control.dirty){
      return 'Field is required'
    }
    else if(control.errors.required){
      return 'Field is required'
    }
    else if(control.errors.wrong){
      return this.postcodeerror
    }
    return 'Only alpha numeric  allowed'
  }

  doPostcodeLookup(): void {

    if (this.addressFormGroup.controls['billingPostcode'].value) {
      this.loading$.next(true);
      this.showAddressDetails = true;
      this.manualStreet = false;
      this.addressLookupService.post(this.addressFormGroup.controls['billingPostcode'].value)
        .subscribe(result => {
          this.addressLookupResponse = result;
          this.addressFormGroup.setValue({
            billingStreet1: '',
            billingPostcode: this.addressLookupResponse.postCode,
            billingLocality: this.addressLookupResponse.locality,
            billingCity: this.addressLookupResponse.town,
            billingCounty: this.addressLookupResponse.county
          });
          this.postcodeCheck = true
          this.showAddressDetails = true;
          this.mySelect.open();
        }, error => {
          this.loading$.next(false);
          this.postcodeerror = 'Wrong postcode'
          this.addressFormGroup.controls['billingPostcode'].setErrors({'wrong': true})
          // this.addressFormGroup.controls['billingPostcode'].setErrors({'incorrect': true})
          this.manualStreet = true
          this.postcodeCheck = false
        }, () => {
          this.loading$.next(false);
          this.mySelect.open();
        });
    } else {
      this.addressFormGroup.controls['billingPostcode'].markAsTouched();
    }
  }
}
