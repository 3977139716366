<div class="container">
  <div class="alert alert-danger mt-5" role="alert" *ngIf="isURLExpired">
    <h4 class="alert-heading">URL Is expired</h4>
    <p>URL is expired. Please try another URL</p>
  </div>
  <div class="content-page-container text-center" *ngIf="accountNumber">
    <h2 class="pt-5">Setup Your New Password</h2>
    <div>
      <form [formGroup]="changePasswordFormGroup">
        <mat-form-field appearance="outline">
          <mat-label>Set Your New Password:</mat-label>
          <input matInput formControlName="newPassword" type="password" required>
        </mat-form-field>
        <div class="text-end btn-next">
          <button class="bosh-btn-primary mt-3" (click)="setPassword()" mat-button type="submit">Set Password</button>
        </div>
      </form>
    </div>
  </div>

</div>
