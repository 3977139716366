import { ModuleWithProviders, NgModule } from '@angular/core';
import { BoshhhStripeService } from "./shared/services/boshhh-stripe.service";
import { AddressLookupService } from "./shared/services/address-lookup.service";
import { MbillsService } from "./shared/services/mbills.service";

// @ts-ignore
@NgModule({
  imports: [

  ],
  declarations: [

  ],
  exports: [

  ]
})
export class BoshhhModule {
  public static forRoute(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: BoshhhModule,
      providers: [
        BoshhhStripeService,
        {
          provide: 'env',
          useValue: environment,
        },
        AddressLookupService,
        {
          provide: 'env',
          useValue: environment,
        },
        MbillsService,
        {
          provide: 'env',
          useValue: environment,
        },
      ],
    };
  }
}
