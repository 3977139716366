import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { TotalProcessingService } from '../../shared/services/total-processing.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-test-total-processing-payment',
  templateUrl: './test-total-processing-payment.component.html',
  styleUrls: ['./test-total-processing-payment.component.css']
})
export class TestTotalProcessingPaymentComponent implements OnInit {
  checkoutId:any;
  price:any = 5.0;

  constructor(
    private  _renderer2: Renderer2,
    private _totalProcessingService: TotalProcessingService,
    private router: Router,
    // @ts-ignore
  @Inject('env') private env:any
  ) {}

  classFlag = false;
  ngOnInit(){
   if (screen.width <= 768) {
    this.classFlag = true;
  } else {
    this.classFlag = false;
  } 
  document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)

  this.createTotalProcessingPayment();

}



createTotalProcessingPayment(){
  this._totalProcessingService.createPaymentProcessing(
    this.price).subscribe(resp => {
      console.log(resp);
    this.checkoutId = resp.id
    let script = this._renderer2.createElement('script');
    script.src = `${this.env.totalProcessingIntent}/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`;
    this._renderer2.appendChild(document.body, script);
  })
}

  onFormSubmit() {
    const id = this.checkoutId; // Replace with your ID value
    this.router.navigate(['/paymentsuccess'], { queryParams: { id } });
  }

}
