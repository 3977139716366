import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {

  constructor() { }

  classFlag = false;
    ngOnInit(){
     if (screen.width <= 768) {
      this.classFlag = true;
    } else {
      this.classFlag = false;
    } 
  }

}
