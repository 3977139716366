import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-file',
  templateUrl: './credit-file.component.html',
  styleUrls: ['./credit-file.component.css']
})
export class CreditFileComponent implements OnInit {

  constructor() { }

  classFlag = false;
    ngOnInit(){
     if (screen.width <= 768) {
      this.classFlag = true;
    } else {
      this.classFlag = false;
    } 
  }

}
