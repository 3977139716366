import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { MatDrawer } from "@angular/material/sidenav";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() useHelloFooter: boolean;
  restrictfooter: boolean = false
  isAdminPanel: boolean = false

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  public innerWidth: any;

  production: boolean = false;
  showsecondfooter: boolean = false

  constructor(
    private loader: LoaderService,
    
    // @ts-ignore
    @Inject('env') private env,
    private router: Router
  ) {
    this.production = this.env.production;

    router.events.subscribe((val) => {
      if(val instanceof NavigationStart){
        if (val.url.startsWith('/register')) {
          this.restrictfooter = true;
          this.showsecondfooter = true
        } 
        else if(val.url.startsWith('/pre/signup')){
          this.restrictfooter = true;
          this.showsecondfooter = true
        }
        else if(val.url.startsWith('/purchase/purchaseform')){
          this.restrictfooter = true;
          this.showsecondfooter = true
        }
        else if(val.url.startsWith('/account')){
          this.restrictfooter = true;
          this.isAdminPanel = true;
          this.showsecondfooter = false
          if(val.url == '/account/dashboard'){
            this.loader.show()
          }
        }
        else{
          this.restrictfooter = false
          this.showsecondfooter = false
        }
      }

    })

    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.loader.hide()

      }})
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    
  }

  public navigate (route: string, doToggle: boolean): void {
    if (doToggle)
      this.drawer.toggle();

    // console.log('using custom route');
    this.router.navigateByUrl(route)
    
  }
}
