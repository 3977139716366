<div class="">
  <div class="">
      <div class="">
          <div class="payment">
              <div class="row payment_header text-light p-3 ">
                  <div class="col-md-6 col-lg-6 d-flex align-items-center d-flex">
                      <img class=" me-2" src="assets/images/payment-check.svg" width="60px" />
                      <div>
                          <h4 class="m-0 f-14">{{this.paymentReceived}}!</h4>
                          <h3 class="m-0 f-18 fw-bold text-left">{{this.greet}}! </h3>
                          <p class="f-12 m-0">{{this.thankyou}}!</p>
                      </div>
                  </div>
                  <div class="col-md-6 col-lg-6 d-flex flex-column py-3">
                      <h4 class="fw-bold text-left m-0">Congratulations!</h4>
                      <h5 class="fw-bold text-left m-0">Welcome to boshhh! well done for taking the first steps to improve your credit score and have the life you deserve!</h5>
                      <p class="text-left">In the next few days you will receive your new boshhh sim card in the post Along with a easy guide on how to activate your sim, move your number over and start your credit building journey on our app!! </p>
                      <button class=" text-dark btn btn-round btn-light" (click)="navigate('/login')">Login</button>
<!-- 
                      <div *ngIf="!obStatus; else elseif" class="col-12 btn-white ">
                          <button class=" text-dark btn btn-round btn-light" [routerLink]="['/']">{{ this.btnText }}</button>
                      </div>
                      <ng-template #elseif>
                          <div class="col-12 btn-white">
                              <button class=" text-dark btn btn-round btn-light" (click)="btnclick()">{{ this.btnText }}</button>
                          </div>
                          <form #formref hidden id="ourForm" action={{this.actionurl}} method="POST" target="_blank">
                              <input name="client" value="boshhh" />
                              <input name="externalRef" value={{this.formData.externalRef}} />
                              <input name="firstname" value={{this.formData.firstName}} />
                              <input name="lastName" value={{this.formData.lastName}} />
                              <input name="email" value={{this.formData.email}} />
                              <input name="telephone" value={{this.formData.telephone}} />
                              <input name="postcode" value={{this.formData.billingPostcode}} />
                              <input name="completeUrl" value="https://boshhh.com">
                              <input name="cancelUrl" value="https://boshhh.com">
                          </form>
                      </ng-template> -->
                  </div>
              </div>
          </div>
      </div>

  </div>
</div>