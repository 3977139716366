import {Component, Inject} from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'lib-jumbotron',
  template: `
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="slider1 slider">
          <div class="container">
            <a [routerLink]="['/register']">
            <img src="/assets/images/cover-slider1.png" alt="Los Angeles"></a>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider2 slider">
          <div class="container">
            <a [routerLink]="['/register']">
              <img src="/assets/images/cover-slider2.png" alt="Los Angeles"></a>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="slider3 slider">
          <div class="container">
            <a [routerLink]="['/register']">
              <img  class="mb-3"  src="/assets/images/cover-slider3.png" alt="Los Angeles"></a>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      
    </a>
  </div>
  
    
  `,
  styles: [`
    a:hover{
      cursor:pointer;
    }
    .slider{
      height: 430px;
      display: flex;
      align-items: end;
    }
    .slider .container a{
      display: flex;
      justify-content: center;
    }
    .slider img{
      width: 600px;
    }
    @media only screen and (max-width: 767px) {
      .slider{
        height: 240px;
      }
      .slider img{
          width: 70%;
      }
    }

    @media only screen and (min-width: 1050px) {
        .slider img{
          width: 700px;
        }
    }
    @media only screen and (max-width: 450px) {
      .slider img{
        width: 95%;
      }
    }
    .slider1{
      Background: linear-gradient(-120deg, rgba(249,237,50,1) 0%, rgba(247,148,29,1) 100%);

      background-size: cover;
      background-repeat: no-repeat;
    }
    .slider2{
      Background: linear-gradient(-120deg, rgba(81,210,147,1) 0%, rgba(58,42,175,1) 100%);
      background-size: cover;
      background-repeat: no-repeat;
    }
    .slider3{
      Background: linear-gradient(-120deg, rgba(225,37,181,1) 0%, rgba(238,67,90,1) 100%);
      background-size: cover;
      background-repeat: no-repeat;
    }
  `]
})
export class JumbotronComponent {
  production: boolean = false;
  constructor(
    private router: Router,
    // @ts-ignore
    @Inject('env') private env
  ) {
    this.production = this.env.production;
  }

  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
