import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IIPAddress } from "../models/ip-address.model";

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {

  constructor(
    private http: HttpClient
  ) { }

  public getIPAddress() {

    return this
      .http
      .get<IIPAddress>('https://api.ipify.org/?format=json');
  }


  public getNewIPAddress():any {
      return this.http.get('/api/?format=json');
  
    }

}
