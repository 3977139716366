<div class="hero-section py-5">
  <div class="container">
    <div class="row content text-light py-3">
      <div class="col-md-12">
        <p class="fw-normal my-2">Affiliate & Partners</p>
        <h1 class="fs-1 heading">Welcome to the Boshhh Affiliate Program!</h1>
        <div class="d-flex justify-content-between p-relative">
          <div>
            <h3 class="my-0">Quick facts about our Affiliate Program:</h3>
            <div class="checklist my-2">
              <p>Get your 12 month sim contract</p>
              <p>Make on-time payments</p>
              <p>Media Content Pool</p>
              <p>Product Discounts for your Audience</p>
              <p>
                Reliable & 100% secure Tracking from world’s renowned affiliate
                platform
              </p>
              <p>Fast and personal support for you</p>
            </div>
          </div>
      
        </div>
      </div>
  
    </div>
  </div>
</div>

<div class="bottom_text">
  <div class="container">
    <div class="row content text-light py-3">
      <div class="col-md-12 col-sm-12 text-dark">
        <p>
          We are excited to introduce our affiliate program and invite you to
          become a part of our team. Our program is designed to create a
          mutually beneficial partnership that will help both of us grow our
          businesses and reach new heights. As an affiliate partner, you will
          have the opportunity to earn commissions on sales generated through
          your unique referral link. We offer competitive commission rates and
          have a wide range of products that you can promote to your audience.
          Our program is free to join and easy to use. Simply receive your
          referral link and start promoting our products to your audience
          through your website, social media channels, email campaigns, and
          other marketing channels.
        </p>
        <h4 class="fw-bolder fs-4">Benefits of our Affiliate Program:</h4>
        <h4 class="">High Commission Rates:</h4>
        <p>
          We offer competitive commission rates that will help you earn more
          money.
        </p>

        <h4>Easy to Use:</h4>
        <p>
          Our program is easy to use, and you can get started promoting our
          products in just a few minutes.
        </p>

        <h4>Dedicated Support:</h4>
        <p>
          Our affiliate team is dedicated to providing you with the support you
          need to be successful in promoting our products.
        </p>
        <h4>Real-Time Tracking:</h4>
        <p>
          You can track your commissions in real-time and access your
          performance data at any time.
        </p>
        <p class="mt-2">
          Joining our affiliate program is easy. Simply register here or Contact
          Us and start promoting our products. We look forward to working with
          you and growing our businesses together.
        </p>
        <a href="https://ui.awin.com/awin/merchant/56183/profile">
          <img src="/assets/images/awin.png" width="300px">
        </a>
      </div>
    </div>
  </div>
</div>