import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../shared/services/loader.service';

// THIS PAYMNET WIDGET IS FOR TESTING PURPOSES
// declare var PaymentWidgets: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements  AfterViewInit{
  constructor(
    private router: Router,
    private loader: LoaderService,
  ) {

  }
  classFlag = false;
  ngOnInit(){
    this.loader.show()
   if (screen.width <= 768) {
    this.classFlag = true;
  } else {
    this.classFlag = false;
  } 
  document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)

  // THIS PAYMNET WIDGET IS FOR TESTING PURPOSES
  // PaymentWidgets.load();
}
ngAfterViewInit() {
  this.loader.hide()
}
  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }

  public goToRegister() {
    localStorage.setItem('packagePlan', 'price_1Mt6rhE8CpEsx2nl5oAL20HE'); // set credit file plan
    this.router.navigate(['/registerPackage']);
  }
 
}
