import { Component, ElementRef, NgZone, ViewChild } from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/overlay";

@Component({
  selector: 'lib-sticky-footer',
  template: `
    <div *ngIf="show" id="sticky-footer">
      <div class="row px-4" >
        <div class="col-12 d-flex justify-content-center align-items-center boshhh-my-credit-score bt white-button-credit">
            <a class="d-flex" [routerLink]="['/register']">
            <!-- <img alt="build my credit score button" src="/assets/images/build-my-credit-score-now-button.png" class="col-8 mx-auto" style="max-width: 230px;"> -->
            <button class="header-btn">Choose your plan</button>
            </a>
        </div>
      </div>
    </div>
  `,
  styles: [`
    #sticky-footer {
      width: 100%;
      background-color: transparent;
      z-index: 100;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
    }
    #sticky-footer button{
      filter: drop-shadow(0 0 0.4rem #9A9999);
      margin: auto;
    }


    .boshhh-my-credit-score a{
      width: 100%;
      text-decoration: none;
    }
    .boshhh-my-credit-score img{
      margin: auto 0;
      border-radius: 40px;
      box-shadow: 0 0.5rem 11.6px #9c9898 !important;
    }

    .row{
        display: flex;
        flex-wrap: nowrap;
        padding: 20px;
        justify-content: center;
    }
    @media (min-width: 992px){
      #sticky-footer button{ 
        z-index: 99999;
        cursor: pointer !important;
        position: fixed;
        display: block;
        bottom: 20px;
        right: 50px;
        filter: drop-shadow(0 0 0.4rem #9A9999);
      }
    }
  `]
})
export class StickyFooterComponent {

  show: boolean = false;

  stickFooterElement: HTMLElement;
  @ViewChild('stream') set stickyFooterRef(ref: ElementRef<HTMLAudioElement>) {
    if (ref !== undefined) {
      this.stickFooterElement = ref.nativeElement;
    }
  }

  constructor(
  
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.scrollDispatcher.scrolled().subscribe((cdk: any) => {
      this.zone.run(() => {
        if (cdk.getElementRef().nativeElement != undefined) {
          const scrollPosition = cdk.getElementRef().nativeElement.scrollTop;
          this.show = scrollPosition > 100;
        }
      });
    });
  }
}
