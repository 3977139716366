<div class="">
    <style>@-webkit-keyframes spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}@-moz-keyframes spin{0%{-moz-transform:rotate(0)}100%{-moz-transform:rotate(360deg)}}@keyframes spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}.spinner{position:fixed;top:0;left:0;width:100%;height:100%;z-index:1003;background: #000000;overflow:hidden}  .spinner div:first-child{display:block;position:relative;left:50%;top:50%;width:150px;height:150px;margin:-75px 0 0 -75px;border-radius:50%;box-shadow:0 3px 3px 0 rgba(255,56,106,1);transform:translate3d(0,0,0);animation:spin 2s linear infinite}  .spinner div:first-child:after,.spinner div:first-child:before{content:'';position:absolute;border-radius:50%}  .spinner div:first-child:before{top:5px;left:5px;right:5px;bottom:5px;box-shadow:0 3px 3px 0 rgb(255, 228, 32);-webkit-animation:spin 3s linear infinite;animation:spin 3s linear infinite}  .spinner div:first-child:after{top:15px;left:15px;right:15px;bottom:15px;box-shadow:0 3px 3px 0 rgba(61, 175, 255,1);animation:spin 1.5s linear infinite}</style>
    <div id="nb-global-spinner" class="spinner" style="display: none;">
        <div class="blob blob-0"></div>
        <div class="blob blob-1"></div>
        <div class="blob blob-2"></div>
        <div class="blob blob-3"></div>
        <div class="blob blob-4"></div>
        <div class="blob blob-5"></div>
    </div>
    <div class="">
        <div class="">
            <div class="payment">
                <div class="row payment_header text-light p-3 ">
                    <div class="col-md-6 col-lg-6 d-flex align-items-center d-flex">
                        <img class=" me-2" src="assets/images/payment-check.svg" width="60px" />
                        <div>
                            <h4 class="m-0 f-14">{{this.paymentReceived}}!</h4>
                            <h3 class="m-0 f-18 fw-bold text-left">{{this.greet}}! </h3>
                            <p class="f-12 m-0">{{this.thankyou}}!</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 d-flex flex-column py-3">
                        <h4 class="fw-bold text-left m-0">{{this.whatnext}}?</h4>
                        <p class="text-left">{{this.message}}! </p>


                        <div *ngIf="!obStatus; else elseif" class="col-12 btn-white ">
                            <button class=" text-dark btn btn-round btn-light" [routerLink]="['/']">{{ this.btnText }}</button>
                        </div>
                        <ng-template #elseif>
                            <div class="col-12 btn-white ">
                                <button class=" text-dark btn btn-round btn-light" (click)="btnclick()">{{ this.btnText }}</button>
                            </div>
                            <form #formref hidden id="ourForm" action={{this.actionurl}} method="POST" target="_blank">
                                <input name="client" value="boshhh" />
                                <input name="externalRef" value={{this.formData.externalRef}} />
                                <input name="firstname" value={{this.formData.firstName}} />
                                <input name="lastName" value={{this.formData.lastName}} />
                                <input name="email" value={{this.formData.email}} />
                                <input name="telephone" value={{this.formData.telephone}} />
                                <input name="postcode" value={{this.formData.billingPostcode}} />
                                <input name="completeUrl" value="https://boshhh.com">
                                <input name="cancelUrl" value="https://boshhh.com">
                            </form>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="isCreditFileSelected">
            <div class="row justify-content-between xs-column-reverse">
                <div class="col-md-5 col-lg-5">
                    <div class="py-3">
                        <h3>Upgrade your experience?</h3>
                        <p>Praesent euismod justo eu urna ornare, at lacinia purus lobortis. Nam mollis dictum arcu, id
                            faucibus nibh maximus in.</p>
                        <p><b>Why upgrade to a SIM plan?</b></p>

                        <div class="package-design-2">
                            <div class="specs">
                                <div class="check-img">
                                    <img src="/assets/images/check.svg">
                                </div>
                                <p class="text">Get <b>unlimited </b>calls and texts</p>
                            </div>
                            <div class="specs">
                                <div class="check-img">
                                    <img src="/assets/images/check.svg">
                                </div>
                                <p class="text">Free access to<b> Boshhh Credit Booster</b></p>
                            </div>

                            <div class="specs">
                                <div class="check-img">
                                    <img src="/assets/images/check.svg">
                                </div>
                                <p class="text">Get a contact
                                    <b>in your own name</b>
                                </p>
                            </div>
                            <div class="specs">
                                <div class="check-img">
                                    <img src="/assets/images/check.svg">
                                </div>
                                <p class="text">Free access to <b>tools to improve your finances</b></p>
                            </div>
                            <div class="specs">
                                <div class="check-img">
                                    <img src="/assets/images/check.svg">
                                </div>
                                <p class="text">Free access to <b>Boshhh Smart Match</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 col-lg-7">

                    <app-upgrade-package
                        [email]="this.registerModel.pipedriveCustomerDetailModel.emailAddress!"></app-upgrade-package>
                </div>
            </div>
        </div>
    </div>
</div>
<button #errorModal data-toggle="modal" data-target="#exampleModal" data-backdrop="false" hidden></button>
<div class="modal fade" id="exampleModal" tabindex="-200" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog" style="margin-top: 100px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Payment Declined</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>

                </button>
            </div>
            <div class="modal-body">
                <p style="color: red;">{{message}}</p><br>
                <p><b>Please Try again using different card</b></p>

                <form #ref action="/paymentsuccess" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div> -->
        </div>
    </div>
</div>

<script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async></script>
<script type="text/javascript">
    (function (t, a, p) {
        t.TapfiliateObject = a; t[a] = t[a] || function () {
            (t[a].q = t[a].q || []).push(arguments)
        }
    })(window, 'tap');
    tap('create', '38102-90b502', { integration: "stripe" });
    tap('conversion', this.chargeId, this.orderAmount, { customer_id: customerId });
</script>