import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ISelectedPackage } from "./selected-package.model";

@Component({
  selector: 'lib-selected-package',
  template: `
    <div *ngIf="selectedPackage">
      <h3>Your SIM Package</h3>
    <mat-card class="p-1">
      <mat-card-content>
        <div class="row mt-3">
          <div class="col-3 pr-0 d-block d-sm-none">
            <img alt="SIM Card" src="/assets/images/brand-sim-card-icon.png" class="col-12 sim-icon">
          </div>
          <div class="col-9">
            <ul class="package-details">
              <li>
                <small [innerHTML]="selectedPackage.name">

                </small>
              </li>
              <li>
                <small>
                  (£{{selectedPackage.monthlyAmount}} p/m)
                </small>
              </li>
              <li>
                <small>
                  <strong>Setup Fee: </strong>£{{initialSetupFee}}
                </small>
              </li>
              <li>
                <small>
                  <strong>Initial Payment Total: </strong>£{{initialPaymentTotal}}
                </small>
              </li>
            </ul>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    </div>
  `,
  styles: [`
    .sim-icon {
      max-width: 100px;
    }

    @media (min-width: 992px) {
      .package-details li {
        list-style: none;
        display: inline;
      }
    }
  `],
})
export class SelectedPackageComponent implements OnInit, OnChanges {
  @Input() packageId: string;
  selectedPackage: ISelectedPackage;
  initialSetupFee: number = 0;
  initialPaymentTotal: string;

  constructor(
    // @ts-ignore
    @Inject('env') private env
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    // todo: This needs to be refactored.
    this.initialSetupFee = this.env.initialSetupFee;
    if (this.packageId === this.env.priceIdOne) {
      this.selectedPackage = {
        name: '<strong>SIM: </strong>10GB DATA<br>Unlimited Minutes & Texts',
        monthlyAmount: 22.75
      }
    } else if (this.packageId === this.env.priceIdTwo) {
      this.selectedPackage = {
        name: '<strong>SIM: </strong>30GB DATA<br>Unlimited Minutes & Texts',
        monthlyAmount: 26.75
      }
    } else if (this.packageId === this.env.priceIdThree) {
      this.selectedPackage = {
        name: '<strong>SIM: </strong>100GB DATA<br>Unlimited Minutes & Texts',
        monthlyAmount: 32.75
      }
    }
    this.initialPaymentTotal = ((+this.initialSetupFee + +this.selectedPackage.monthlyAmount).toFixed(2));
  }
}
