import { Component } from '@angular/core';

@Component({
  selector: 'lib-frequently-asked-questions',
  template: `
    <div class="text-center pt-5 container" id="faq">

      <h1 style="margin: 1rem 0;">
        FAQs.
      </h1>
      <mat-accordion class="example-headers-align text-start " multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                What is the coverage like with Boshhh?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Wherever it can, Boshhh connects to 5G, 4G, and 3G networks. 2G mobile devices are incompatible with Boshhh. Before using your device to access our 5G network, make sure it is 5G-compatible.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                Can I keep my number?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          
            <p class="text-black">Contact your network provider to get a PAC code. You can do this via phone, web or by texting “PAC” to 65075. After that, you have 30 days to move your phone number to your new sim. Once the 30 days expires, you will need to retrieve a new code. </p>
            <p class="text-black">Thanks to our collab with your current network provider, the transfer will take place by the end of the following working day.</p>
            <p class="text-black">If you already have an active Boshhh sim, you won’t experience any service interruption during the transfer but you might need to restart your device for it to take effect.</p>
            
          
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                Does the cost increase every year?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Plans that roll over for 30 days may be cancelled at any time. This means that during the course of your contract, you are never required to accept price hikes. That is what we call BOSHHH.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                How do I get in contact with you?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Head to our contact page for various ways to contact us.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                Can I leave Boshhh?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
          Remember that if you're unsure about leaving you can always speak to us to find out your options. Please do not hesitate to get in touch with our customer support staff if there is anything we can do to help or improve.
          </p>
        </mat-expansion-panel>
        
      </mat-accordion>
    </div>
  `,
  styles: [`
    .text-green-gradient {
      background: -webkit-linear-gradient(45deg, #009245, #003f37);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `]
})
export class FrequentlyAskedQuestionsComponent {
  constructor() {}
}
