	
  <div class="hero-section">
  
    <div class="container">
      
      <div class="row content text-light py-3">
        <div class="col-md-6 col-sm-6">
          <p class="fw-normal my-2"> Partner with Boshhh</p>
          <h1>Grow your business as a Boshhh partner.		</h1>
          <h5>Let’s work together to make a positive impact for our customers</h5>
          <div class="d-flex justify-content-between p-relative">
            <div>
            <div class="btn-container py-2" >
                <button class="bosh-btn-primary px-5 w-100">Join Now</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
          <div class="desktop-img ">
            <img src="/assets/images/partners.jpeg" alt="partners-desktop">
          </div>
        </div>
  
      </div>
    </div>
  </div>
  
  <!-- sublimation -->
	<section class="sublimation">
		<div class="container pt-5">
      <h3 class="text-center">Boshhh select partners working together to provide excellent solutions to our customer. </h3>
      <p class="text-center">Boshhh clever mobile is a credit boosting mobile phone network. Our goal is to make a positive impact for our customer through partnerships.</p>
      
			<div class="row py-3">
				<div class="col-md-4 sublimation-img my-3 justify-content-center d-flex align-items-center">
            <img src="/assets/images/suits-me-logo.png" width="300px">
				</div>
				<div class="col-md-8">
					<h3> 	Getting an account with poor credit can be hard, not with Suits Me!
					</h3>
					<P>
						Open a Suits Me account in less than 3 minutes with no credit checks or photo ID required and get instant access to a UK account for your wages or benefit payments.
					</P>
          <p>All Suits Me accounts offer amazing banking like features including a mobile app, contactless Mastercard debit card, dedicated customer support, cash back and exclusive discounts.</p>
          <p>It’s time to open an account that suits you!</p>
					<a href="https://suitsmecard.com/apply?utm_source=Boshhh&utm_medium=CPA&utm_campaign=Boshhh"><button class="bosh-btn-primary px-5">Join Now</button></a>
				</div>
			</div>
			<div class="row py-3">
				<div class="col-md-4 sublimation-img my-3 justify-content-center d-flex align-items-center">
			<img src="/assets/images/monefi__dark_pink.png" width="300px">
				</div>
				<div class="col-md-8">
					<h3> 	
						Monefi, the smart choice for your finances. 
					</h3>
					<p>
						At Monefi, we're not just your typical finance brokerage – we're your trusted companion by your side every step of the way throughout your financial life. Driven by technology and guided by experts, we seamlessly merge digital solutions and add the human touch to finance. Whether it's a loan, mortgage, insurance or anything in between, Monefi's got you covered.
					</p>
					<p></p>
					<button class="bosh-btn-primary px-5" (click)="goToRegister()">Join Now</button>
				</div>
		</div>
			<div class="row py-3">
						<div class="col-md-4 sublimation-img my-3 justify-content-center d-flex align-items-center">
							<div class="sublimation-img my-3">
					<img src="/assets/images/efx_hr_logo_horizontal.png" width="300px">
				</div>
						</div>
						<div class="col-md-8">
							<h3> 	Get the credit you deserve
							</h3>
							<p>
								Through our partnership with Equifax we are able to report our customers payments each month. Allowing our customers sit back and watch their credit score climb. Boshhh gives our users FREE access to their Equifax credit file.
							</p>
							<a href="https://developer.equifax.co.uk/"><button class="bosh-btn-primary px-5" >Join Now</button></a>
						</div>
			</div>
			<div class="row py-3">
					<div class="col-md-4 sublimation-img my-3 justify-content-center d-flex align-items-center">
				<img src="/assets/images/mode.png" width="300px">
					</div>
					<div class="col-md-8">
						<h3> Bullying in UK schools deeply affects many students
						</h3>
						<p>
							Leading to distress, social isolation, and, tragically, even suicide. The phenomenon of cyberbullying, defined in 2005 by Belsey as using technology for repeated hostile actions against others, has added a new dimension to the problem. Recognizing the gravity of these issues, Boshh!! CEOs are committed to supporting young individuals against these threats.
						</p>
						<button class="bosh-btn-primary px-5" (click)="goToRegister()">Join Now</button>
					</div>
			</div>
			<div class="row py-3">
				<div class="col-md-4 sublimation-img my-3 justify-content-center d-flex align-items-center">
			<img src="/assets/images/Bolesworth_Primary_Logo.png" width="300px">
				</div>
				<div class="col-md-8">
					<h3> 	Boshhh is proud to be the main sponsor of Britain’s Biggest Motocross Festival Live At Bolesworth
					</h3>
					<p>The Arenacross Tour heads outdoors from 15 – 17 September for an amazing three-day festival of racing, live music, freestyle motocross, shops, bars, family fun and so much more!</p>
					<p>Arenacross is a show like no other. It’s a high-octane mix of indoor Motocross racing and Freestyle Motocross (FMX) madness and is packed to the rafters with all-round family fun and frenzied entertainment. It’s guaranteed to keep you on your feet yelling and hollering for more!</p>
					<button class="bosh-btn-primary px-5" (click)="goToRegister()">Join Now</button>
				</div>
		</div>
		</div>
	</section>
