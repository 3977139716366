import { AfterViewInit, Component, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '../../shared/services/loader.service';


// THIS PAYMNET WIDGET IS FOR TESTING PURPOSES
// declare var PaymentWidgets: any;

@Component({
  selector: 'app-hidden-prices-landing-page',
  templateUrl: './hidden-prices-landing-page.component.html',
  styleUrls: ['./hidden-prices-landing-page.component.css']
})
export class HiddenPricesLandingPageComponent implements  AfterViewInit{
  dialogBox: any
  isTextRevealed = false;

  constructor(
    private renderer: Renderer2, private el: ElementRef,
    private router: Router,
    private cdRef:ChangeDetectorRef,
    private loader: LoaderService
  ) {

  }
  classFlag = false;
  ngOnInit(){
   if (screen.width <= 768) {
    this.classFlag = true;
  } else {
    this.classFlag = false;
  } 
  document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)

  // THIS PAYMNET WIDGET IS FOR TESTING PURPOSES
  // PaymentWidgets.load();
}
revealText() {
    // Replace 'my-element' with the class of the element you want to change
    const container = this.el.nativeElement.querySelector('.fading-text');
    const gredient = this.el.nativeElement.querySelector('.white-gredient');

    if (container) {
      // Use Renderer2 to change the CSS
      this.renderer.setStyle(container, 'height', '100%');
      this.renderer.setStyle(container, 'overflow', 'auto');
      this.renderer.setStyle(gredient, 'display', 'none');
      // Add any other CSS changes you need
    }
}
ngAfterViewInit() {
  this.loader.hide()
  this.cdRef.detectChanges();
  
}

  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }

  public hideDialog(){
    this.dialogBox = document.getElementById('dialogBox');
    this.dialogBox.hidden = true
  }

  public goToRegister() {
    localStorage.setItem('packagePlan', 'price_1Mt6rhE8CpEsx2nl5oAL20HE'); // set credit file plan
    this.router.navigate(['/register/packages']);
  }
  public goSimActivate() {
    this.router.navigate(['/activate']);
  }
  public goSimLogin() {
    this.router.navigate(['/login']);
  }
 
}
