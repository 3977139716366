import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-credit-builder-plus',
  templateUrl: 'credit-builder-plus.component.html',
  styles: [`
    .get-started-button:hover {
      color: #3316a5;
    }
  `],
})
export class CreditBuilderPlusComponent implements OnInit {
  @Input() showButton: boolean = true;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {}

  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
