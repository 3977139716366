import { Injectable } from '@angular/core';
import { Product } from '../models/Products'
@Injectable()
export class GlobalService {
product: Product[]
constructor() {
this.product = [
      {
        id: 1,
        name : 'Samsung A50 64gb Black - As New',
        upfrontAmount: 30.00,
        monthlyAmount: 22.25,
        inStock: true,
        discount: 50,
        imgURL: 'sa50',
      },
      {
        id: 2,
        name : 'Samsung A51 128gb Black - As New',
        upfrontAmount: 38.00,
        monthlyAmount: 28.75,
        inStock: true,
        discount: 50,
        imgURL: 's51',
      },
      {
        id: 3,
        name : 'Samsung S10E 128gb Black - As New',
        upfrontAmount: 41.00,
        monthlyAmount: 31.25,
        inStock: true,
        discount: 50,
        imgURL: 's10e',
      },
      {
        id: 4,
        name : 'Apple iPhone 8 256gb - As New',
        upfrontAmount: 47.00,
        monthlyAmount: 36.25,
        inStock: true,
        discount: 50,
        imgURL: 'iphone-8',
      },
      {
        id: 5,
        name : 'Apple iPhone XS 64gb - As New',
        upfrontAmount: 61.00,
        monthlyAmount: 46.25,
        inStock: true,
        discount: 50,
        imgURL: 'xs-black',
      },
      {
        id: 6,
        name : 'Apple iPhone XS 256gb - As New',
        upfrontAmount: 70.00,
        monthlyAmount: 53.00,
        inStock: true,
        discount: 50,
        imgURL: 'xs-white',
      },
      {
        id: 7,
        name : 'Apple iPhone SE 2020 64gb - As new',
        upfrontAmount: 43.00,
        monthlyAmount: 32.50,
        inStock: true,
        discount: 50,
        imgURL: 'se',
      },

    ];
}
}
