import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.css']
})
export class CustomerSupportComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }


goBack(){
  this.router.navigate(['/account/dashboard'])
}
}
