<div class="container content-page-container">
  <h2>Refund policy</h2>

  <h3>Returns policy:</h3>

  <p>
    If purchased online or over the phone our airtime or mobile phone contract
    can be cancelled within a cooling off period of 14 days from receipt of the
    goods by you. Please note handsets must be returned in a resalable condition
    and any handset returned outside of the 14 day cancellation period cannot be
    disconnected earlier than the end of the contract period.
  </p>

  <p>
    The 14 day cancellation period does not apply to goods or services purchased
    in store.
  </p>

  <h3>Return Process:</h3>

  <p>
    All returns and cancellations require authorisation, which can be obtained
    by calling 03333 444353. Please note that unsolicited returns and/or
    handsets that are not in resalable condition will not be processed and will
    be returned to the contract holder.
  </p>

  <p>
    If there is an associated airtime agreement, you will remain liable to pay
    for the line rental until the unused phone and all other goods we supplied
    to you are received back by us in their original packaging. You are also
    liable for any out of bundle charges and anything else not included in your
    tariff’s monthly allowance incurred during this time.
  </p>

  <p>
    You must return your mobile phone within 14 days of receipt if you are not
    entirely happy with it. Please note if we do not receive your handset within
    this time frame, we will not be able to disconnect it after this period. In
    order to receive your refund, and cancel any contract phones, we ask that
    you return the entire goods in the original condition with all the
    packaging, along with any free gifts and/or promotional items sent out with
    the order.
  </p>

  <p>
    The customer is fully responsible for the goods until we receive the goods
    back. If there is anything missing from the kit, or the goods are in any way
    damaged, the customer will be charged accordingly.
  </p>

  <p>
    If you should return a phone to us we recommend you use ‘Royal Mail Special
    Delivery’ as this will insure your product up to £500.
  </p>

  <p>
    The set-up cost of £7.99 is non-refundable. We cannot refund for items
    downloaded onto mobile phones or SIM cards such as ringtones, logos, games,
    or content which is lost due to faults or returning of products. We cannot
    make reimbursements for any loss of data, such as phone book or personal
    information that is stored on your mobile phone or SIM card and subsequently
    lost due to a fault or request to return the product. We recommend you back
    up any data stored on your mobile phone as often as possible, to avoid such
    issues.
  </p>

  <p>
    Boshhh Ltd will use reasonable endeavours to replace and return faulty goods
    within 7 days of receipt of such faulty goods from the customer.
  </p>
</div>
