<div class="container  py-5" id="container">
  
  <div class="login-container row center">
    <div class="repayment">
      <h3>Hi {{ this.userName }}</h3>
      <div *ngIf="this.isCallcenter else stripePayment">
        <p>Welcome to Boshhh Clever Mobile! </p>
        <p>In order to complete your sim purchase please complete the payment information below</p>
        <p>If you have any questions feel free to email us at <a href="mailto:hello@boshhh.com"> hello@boshhh.com </a>       </p>
        <p>Payable Amount: <b>£{{ this.amount }}</b></p>
        <p *ngIf="this.isCallcenter">Payable amount on selected date {{ paymentDate }}: <b>£{{ this.fullAmount }}</b></p>
      </div>
      <ng-template #stripePayment>
        <p>We need you to update you payment information in order to continue you Boshhh mobile service! If you have any questions feel free to email us at hello@boshhh.com</p>
        <p>If you have any questions feel free to email us at <a href="mailto:hello@boshhh.com"> hello@boshhh.com </a>       </p>
        <p>Payable Amount: <b>£{{ this.amount }}</b></p>
      </ng-template>
    </div>
    <div *ngIf="loading$ | async" class="mx-auto d-flex justify-content-center load">
      <div style="background: #fff;width: 200px;height: 70px;border-radius: 7px;">
          <img class="loader-svg" src="assets/images/Bean Eater-1s-200px.svg" alt="loading..." />
      </div>
    </div>
      <form method="get" c #form hidden target="iFrame" action={{this.actionurl}} id=form1 name=form1>
        <input #input1 type="text" id="PSPID" name="PSPID">
        <input #input2 type="text" name="ALIAS" id="ALIAS">
        <input #input3 type="text" name="ALIASUSAGE" id="ALIASUSAGE">
        <input #input4 type="text" id="ORDERID" name="ORDERID">
        <input #input5 type="text" id="AMOUNT" name="AMOUNT">
        <input #input6 type="text" id="CURRENCY" name="CURRENCY">
        <input #input7 type="text" id="CN" name="CN">
        <input #input8 type="text" id="EMAIL" name="EMAIL">
        <input #input9 type="text" name="OWNERZIP" id="OWNERZIP">
        <input #input10 type="text" name="OWNERADDRESS" id="OWNERADDRESS">
        <input #input11 type="text" name="OWNERCTY" id="OWNERCTY">
        <input #input12 type="text" name="OWNERTOWN" id="OWNERTOWN">
        <input #input13 type="text" name="OWNERTELNO" id="OWNERTELNO">
        <input #input14 type="text" id="SHASIGN" name="SHASIGN">
        <input #input15 type="text" id="ACCEPTURL" name="ACCEPTURL">
        <input #input16 type="text" id="DECLINEURL" name="DECLINEURL">
        <input #input17 type="text" id="EXCEPTIONURL" name="EXCEPTIONURL">
        <input #input18 type="text" id="CANCELURL" name="CANCELURL">
          <br>
            <input #input19 type="submit" value="SUBMIT" id='submit2' name='submit2'>
      </form>
        <iframe #iframe (load)="uploadDone()" id="iFrame" name="iFrame" src={{this.actionurl}} target="_parent" height="700px" width="100%" >
          <p>Your browser does not support iframes.</p>
        </iframe>
  </div>
</div>