import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http"
import { PipedriveService } from "../../shared/services/pipedrive.service";
@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {
  unsubscribeFormGroup: FormGroup;
  email: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private pipeDriveService: PipedriveService
  ) { }

  ngOnInit() {
    this.unsubscribeFormGroup =
            this.fb.group({
              email: ['', Validators.required],
            });
  }
  unsubscribeEmail(): void {
    
    
    this.pipeDriveService.unsubscribeEmail(
      this.unsubscribeFormGroup.controls['email'].value)
      .subscribe( resp => {
        console.log( resp);
        if(resp.result == 'Unsubscribed Successfully!'){
        this.toastr.success('Your Email Unsubscribed Successfully','Congratulation!' );
        this.router.navigate(['unsubscribe-success']);

      }else{
        this.toastr.error('Error', 'Failed to Unsubscribe');
          this.toastr.error(resp.result, 'Error');
          this.unsubscribeFormGroup.reset();
      }
      
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          this.toastr.error('Failed to Unsubscribe', 'Error');
          this.toastr.error('Email Not Found. Please enter a valid/registered email.', 'Error');
          this.unsubscribeFormGroup.reset();
        }
        if (error.status === 500) {
          this.toastr.error('Error', 'Failed to Unsubscribe');
          this.toastr.error('Try again', 'Try again to unsubscribe your email please');
          this.unsubscribeFormGroup.reset();
        }
      }
      
    });
  }
    
}
