import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AddressLookupService } from "../../../shared/services/address-lookup.service";

@Component({
  selector: 'app-sim-plan-hidden-price',
  templateUrl: './sim-plan-hidden-price.component.html',
  styleUrls: ['./sim-plan-hidden-price.component.css']
})
export class SimPlanHiddenPriceComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @Input() useEventEmitter: boolean;
  @Output() selectedPackage = new EventEmitter<string>();
  @ViewChild('scrollContent') scrollContent: ElementRef;
  @ViewChild('carousel', { static: true }) carousel: ElementRef;
  private intervalId: any;
  currentIndex = 0;
  makeSectionActive = 1;

  packagePlans: any;
  sortedPackagePlans: any;
  production: boolean = false;

  constructor(
    // @ts-ignore
    @Inject('env') private env,
    private adressLookupService: AddressLookupService,
    private router: Router
  ) {
    this.production = this.env.production
  }

  
ngOnInit() {
  this.adressLookupService.getPackages().subscribe(
    resp => {
      
      this.packagePlans = resp
      // console.log(this.packagePlans);
      this.packagePlans = this.packagePlans.filter( (x: any) => x.Categoryid == 2)
      this.packagePlans.sort((a: any, b: any) => {
        if (a.Name === '10 Gb Ultra Fast') { return -1; }
        if (b.Name === '10 Gb Ultra Fast') { return 1; }
        if (a.Name === '30GB Ultra Fast') { return -1; }
        if (b.Name === '30GB Ultra Fast') { return 1; }
        if (a.Name === '100 GB Ultra Fast') { return -1; }
        if (b.Name === '100 GB Ultra Fast') { return 1; }
        return 0;
      });
      // console.log(this.packagePlans);
    })
  
}


  ngAfterViewInit() {
    this.intervalId = setInterval(() => {
      const container = this.scrollContent.nativeElement;
      container.scrollLeft = 262
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  selectedPlan(packageId: string): void {
    this.router.navigateByUrl(`/register?packageId=${packageId}`);
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0);
  }

  onScroll(event: Event) {
    clearInterval(this.intervalId);

    const leftDirection:any = (event.target as HTMLElement).scrollLeft;

    if (leftDirection < 197) {
      this.makeSectionActive = 1;
    }

    if (leftDirection >= 197) {
        this.makeSectionActive = 2;
    }
    
    if (leftDirection >= 265){
        this.makeSectionActive = 3;
    }
  }

  scrollToElement(id:any){
   
    if (id === 1 ){
      this.scrollContent.nativeElement.scrollLeft = 0;
    }
    
    if (id === 2) {
      this.scrollContent.nativeElement.scrollLeft = 210;
    }

    if (id === 3) {
      this.scrollContent.nativeElement.scrollLeft = 400;
    }

    // if (id === 4) {
    //   this.scrollContent.nativeElement.scrollLeft = 690;
    // }
  }

  restrictToTwoDecimals(price:any) {
    const fixedPrice = Number(price);
    return fixedPrice.toFixed(2);
  }

}
