import { Component, EventEmitter, Input, OnInit, Inject, Renderer2,  Output, ViewChild } from "@angular/core";
import { AddressLookupService } from "../../../shared/services/address-lookup.service";
import { MbillsService } from "../../../shared/services/mbills.service";
import { ToastrService } from "ngx-toastr";
import { TotalProcessingService } from "../../../shared/services/total-processing.service";
import { SharedService } from "../../../shared/services/shared.service";
import { PipedriveService } from "../../../shared/services/pipedrive.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-upgrade-package',
  templateUrl: './upgrade-package.component.html',
  styleUrls: ['./upgrade-package.component.css']
})
export class UpgradePackageComponent implements OnInit {
  @Input() selectedPackageId: string;
  @Output() packageInfo = new EventEmitter();
  @Input() email : string
  selectedPackage: any
  packagePlans: any
  packageBoldOnPlans: any
  showOne: boolean;
  isPopular: 26.75;
  production: any;
  checkoutId: any;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  creditCardType: string;
  packagePlan: any;
  amount: any;
  registerModel: any;
  




  constructor(private adressLookupService: AddressLookupService,
    private mbills: MbillsService,
    private toastr: ToastrService,
    private _totalProcessingService: TotalProcessingService,
    private  _renderer2: Renderer2,
    private sharedService: SharedService,
    private pipeDriveService: PipedriveService,
    private router: Router,

    // @ts-ignore
    @Inject('env') private env:any) 
    {
      this.production = this.env.production
    }
  

  ngOnInit() {
    this.adressLookupService.getPackages().subscribe(
      resp => {
        this.packagePlans = resp
        if(this.selectedPackageId != null && this.selectedPackageId != undefined){
          this.showOne = true
      }
      
      this.packagePlans = this.packagePlans.filter( (x: any) => x.Categoryid == 3)

      })
  }

  selectedPlan(data: any){
    this.packagePlan = data;
    const fixedPrice = Number(data.Price);
    
    this.amount = fixedPrice.toFixed(2).toString();
    var email = localStorage.getItem('email')
    localStorage.removeItem('email')
    var obj= {
      amount: this.amount,
      Email: email
    }
    this._totalProcessingService.createPaymentProcessing(
      obj).subscribe(resp => {
      this.checkoutId = resp.id
      let script = this._renderer2.createElement('script');
      
      script.src = `${this.env.totalProcessingIntent}/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`;
      this._renderer2.appendChild(document.body, script);
    })
    // this.upgradePlan(data);
  }

  hideModal(){
    const modal:any = document.getElementById("myModal");
    modal.style.display = "none";
  }

  
  UpsellOrder(data:any){
    var account = localStorage.getItem('acno')
    var accountnumber = ''
    localStorage.removeItem('acno')
    if(account != null && account != undefined){
      accountnumber = JSON.parse(account)
    }
    const payloadData = {
      // FOR TEST
     "accountNo" : accountnumber,
     "productId" : this.packagePlan?.ProductId?.toString(),
   }
 
   this.mbills.upsellOrderAPI(payloadData).subscribe(
     resp => {
      this.toastr.success('Package Added',resp.response_message);
      this.router.navigate(['/'])
        console.log('Success');
        console.log(resp);
     },
     err => {
      this.toastr.error('Error','Could not add package');
       console.log('FAILED');
       console.log(err);
     }
   )

   this.packageInfo.emit(data)
 }

  upgradePlan(data:any){
     // TEST RECORD
     const payloadData = {
      "accountNumber" : 'BOS100142',
      "cli" : '07756022773',
      "packageId" : data.mbillsPackageId,
    }
  
    this.mbills.changePackagePlan(payloadData).subscribe(
      resp => {
        if (resp.responseStatus) {
          this.toastr.error('Error',resp.responseStatus.reason);
        }
        else {
          // this.toastr.success('Success','Package Plan updated')
        }
      },
      err => {
        console.log('FAILED');
        console.log(err);
        this.toastr.error('Error','Something wrong. Please try again later')
      }
    )

    this.packageInfo.emit(data)
  }

  restrictToTwoDecimals(price:any) {
    const fixedPrice = Math.floor(Number(price) * 100) / 100;
    return fixedPrice.toFixed(2);
  }

 
}
