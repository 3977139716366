import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-account-setup-complete',
  templateUrl: 'account-setup-complete.component.html',
  styles: [`
   
  `],
})
export class AccountSetupCompleteComponent implements OnInit {
  constructor(
    private router: Router,
  ) {
    
  }

  ngOnInit(): void {}

  doLogin(): void {
    this.router.navigate(['login']);
  }
}
