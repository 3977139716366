import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MbillsService } from '../../../shared/services/mbills.service';
import { HttpErrorResponse } from "@angular/common/http"
import { Router } from '@angular/router';
import { LoaderService } from '../../../shared/services/loader.service';

@Component({
  selector: 'app-activate-sim',
  templateUrl: './activate-sim.component.html',
  styleUrls: ['./activate-sim.component.css']
})
export class ActivateSimComponent implements OnInit {
  activesim: FormGroup;

  constructor(private fb: FormBuilder,
    private mbills: MbillsService,
    private toastr: ToastrService,
    private router: Router,
    private loader: LoaderService
    ) { }

  ngOnInit() {
    this.initform()
  }

  initform(){
    this.activesim = this.fb.group({
      email: ["", [Validators.email, Validators.required]],
      sim: [ "", [ Validators.required ]],
    });
  }

  activate(){
    this.loader.show()
    this.mbills.activeSim(this.activesim.value).subscribe( resp => {
      if(resp.result.provisioningStatus == 'SUCCESS'){
        this.router.navigate(['/pending'])
        this.activesim.reset();
      }else{
        this.toastr.error(resp.result.provisioningStatus,resp.result.failureReason)
      }
      this.loader.hide()
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 400) {
          this.toastr.error("No Record Found.Please enter registered email address","Incorrect Email")
          this.activesim.reset();
        }
        if (error.status === 500) {
          this.toastr.error("Please try after a few moments","Something went wrong")
          this.activesim.reset();
        }
      }
      this.loader.hide()
    });
  }

toLogin(){
  this.router.navigate(['/login'])
}
}

