
  <div class="row">
     <div class="col-md-6 mx-auto mt-5">
        <div class="payment">
           <div class="payment_header">
              <div class="check"><img src="assets/images/boshhh_icon.png" height="50px" width="50px" alt="boshhh-icon"/></div>
           </div>
           <div class="content">
              <h1>An error occurred while Payment</h1>
              <p>There is an error in your payment 
                <br> Please check details
                <p>Sorry for the Inconvenience</p>
              <button style="background-color: #c31818;" [routerLink]="['/']">Go back to Home</button>
           </div>
           
        </div>
     </div>
  </div>
    

  <!-- <div class="row stripe-payment">
    <div class="col-md-5 col-lg-5">
        <div>
            <p class="text-gray">Payable Amount</p>
            <h2>$34.74</h2>
        </div>
        <div class="d-flex justify-content-between my-3">
            <div class="d-flex align-items-center">
                <div class="bosh-thumbnail"></div>
                <div>
                    <p> <b>30GB Data</b></p>
                    <p class="text-gray"><small>Unlimited Calls, Texts</small></p>
                </div>
            </div>
            <div>
                <h4><b>£26.75</b></h4>
            </div>
        </div>
        <div class="d-flex justify-content-between my-3">
            <div class="d-flex align-items-center">
                <div class="bosh-thumbnail"></div>
                <div>
                    <p> <b>Setup Fee</b></p>
                    <p class="text-gray"><small>Initial setup fee only first time</small></p>
                </div>
            </div>
            <div>
                <h4><b>£7.99</b></h4>
            </div>
        </div>
        <div class="d-flex justify-content-between my-3 ps-5">
            <p> <b>Subtotal</b></p>
            <p> <b>£34.74</b></p>
        </div>
        <div class="d-flex justify-content-between my-3 ps-5">
            <p> <b>Sales Tax (0%)</b></p>
            <p> <b>£00.00</b></p>
        </div>
        <div class="d-flex justify-content-between my-3 ps-5">
            <p> <b>Total</b></p>
            <p> <b>£34.74</b></p>
        </div>
    </div>
    <div class="col-md-7 col-lg-7">
        <h4>Stipe Form here</h4>
    </div>
</div> -->