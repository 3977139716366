
<div class="bg-white p-4 account-details">
    <form [formGroup]="helpForm" (ngSubmit)="submit()">
        <div class="inner-content">            
            <div class="row">
                <h3 class="page-heading">Create a new ticket</h3>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-3">
                        <label for="queryType" class="form-label">Ticket Type:</label>
                        <select formControlName="ticketType" (change)="changeType($event)" id="queryType" name="queryType" class="form-control">
                          <option value="0">Please Select</option>
                          <option *ngFor="let opt of this.tickets" value="{{opt.id}}">{{opt.description}}</option>
                      </select>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-3">
                        <label for="message" class="form-label">Details</label>
                        <textarea  rows="5" type="text" class="form-control" id="message" name="message" formControlName="notes"></textarea>
                    </div>
                </div>
            <small [hidden]="formValidity" style="color: red;" class="text-start">Please fill all the details to submit the ticket</small>
            <div class="d-flex justify-content-end mt-3">
              <button class="btn bosh-btn-primary bosh-btn-secondary" type="submit">Submit ticket</button>
          </div>
        </div>
      </div>
    </form>
  </div>