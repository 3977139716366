import { Injectable } from '@angular/core'
import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http'
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor( private loader: LoaderService
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const token = localStorage.getItem('UserContext')
        var ttoken
        if(token !== null){
            ttoken = JSON.parse(token)
        }
        let tokenizedRequest = req.clone({
            setHeaders:{
                Authorization: `Bearer ${ttoken}`
            }
        })
        return next.handle(tokenizedRequest).pipe(tap(
            (response: HttpEvent<any>) => {
                this.loader.hide()
              },
            (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    localStorage.removeItem('UserContext')
                    if(window.location.pathname == '/login'){

                    }
                    else{
                        window.location.href = "/login";
                    }
                }
            }
        }) )
    }
}