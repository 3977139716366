import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-and-faqs',
  templateUrl: 'how-to-and-faqs.component.html'
})
export class HowToAndFaqsComponent implements OnInit {
  constructor() {}

  ngOnInit(){
    
    window.scrollTo(0, 0);
  }
}
