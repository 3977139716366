<div class="container  py-2" id="container">
  <div class="login-container row">
    <div class="col-md-6 col-sm-6 overlay-panel md-flex-center">
      <div class="overlay-right text-center py-3">
        <img src="/assets/images/credit-score-meter.png" width="250px">
        
        <!-- <p class="mt-3">Enter your details and start journey with us!</p> -->
        
      </div>
    </div>  
    <div class="col-md-6 col-sm-6 md-flex-center py-5">
      <div class="form-container w-100">
        <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="d-flex flex-column text-center">
          <p class="f-14 fw-bold">Sign into your account to access your credit file and start your credit boosting journey today</p>
          <input type="email" placeholder="Email" formControlName="email" name="user_email"  required/>
          <input type="password" placeholder="Password" formControlName="password" name="user_password"  required/>
          <p class="f-12 mb-2 mb-0 text-center text-wrap line-height-1">Boshhh has sent you an email to set your password. Search  <b>"Boshhh - Set Password"</b> in your inbox</p>
          <a class="my-3" (click)="navigate('/reset-account-setup')" style="cursor: pointer;">Forgot your password?</a>
          <button type="submit" class="bosh-btn-primary" [disabled]="!this.loginForm.valid">Sign In</button>
          <p class="my-2"><small>or</small></p>
          <p class="m-0 btn btn-border-success"  (click)="navigate('/register')" >Click here to Sign Up Now</p>
        </form>
      </div>  
    </div>
  </div>
</div>