import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: 'app-unsubscribe-success',
  templateUrl: './unsubscribe-success.component.html',
  styleUrls: ['./unsubscribe-success.component.css']
})
export class UnsubscribeSuccessComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }
  doLogin(): void {
    console.log("clicked");
    this.router.navigate(['/']);
  }
}
