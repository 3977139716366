import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Inject, OnDestroy, Renderer2 } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { IAddressLookupResponse } from "../../shared/components/account-stepper/address-details/address-lookup-response.model";
import { IRegisterModell } from "../../shared/models/register.model";
import { AddressLookupService } from "../../shared/services/address-lookup.service";
import { BoshhhStripeService } from "../../shared/services/boshhh-stripe.service";
import { IpAddressService } from "../../shared/services/ip-address.service";
import { MbillsService } from "../../shared/services/mbills.service";
import { PipedriveService } from "../../shared/services/pipedrive.service";
import { IRegisterModel } from "../register/register.model";
import { ActivatedRoute } from "@angular/router";
import { TotalProcessingService } from "../../shared/services/total-processing.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-agentform',
  templateUrl: './agent-form.component.html',
  styleUrls: ['./agent-form.component.css'],
})
export class AgentformComponent implements OnInit, OnDestroy {
  registerModel: IRegisterModel;
  public loading$ = new BehaviorSubject<boolean>(false);
  @ViewChild('mySelect') mySelect : any;
  
  validEmail: boolean = false
  validPhone: boolean = false
  loadintent: boolean = false
  ipAddress: string;
  dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  bishFormGroup: FormGroup;
  bashFormGroup: FormGroup;
  addressFormGroup: FormGroup;

  titleError: boolean;
  production: any;
  postcodeerror: string;

  selectedHandsetName: any
  @Output() emitselectedPkg = new EventEmitter();
  pkgs: any
  pkgVal: any
  pkgName: any

  stripCustomerId: string;
  stripeId: string;
  boshhhFormGroup: FormGroup;
  registerModell: IRegisterModell;

  documentId: string;
  customerDetailFormGroup: FormGroup;
  billingAddressFormGroup: FormGroup;
  cardDetailsFormGroup: FormGroup;
  packageDetailsFormGroup: FormGroup;
  stripeDetailsFormGroup: FormGroup;
  initfee: number = 7.99
  

  acceptForm: FormGroup;
  acceptedTerms: boolean = false;
  clientSecret: any;
  @Input() customerName: any;
  @Input() customerEmail: any;
  @Input() customerAddress: any;
  @Input() customerPhone: any;
  @ViewChild('tgl') toggle: ElementRef;


  public paymentProcessing$ = new BehaviorSubject<boolean>(false);
  public noPaymentProcessing$ = new BehaviorSubject<boolean>(true);

  paymentErrorResponse?: string;
  packagePlan: any;
  finalprice: any = 0.00;
  showAgreementError: boolean;
  chargeId: any;
  orderAmount: number;
  showAddressDetails: boolean;
  manualStreet: boolean;
  addressLookupResponse: IAddressLookupResponse;
  postcodeCheck: boolean;
  callCenterAgentName: any;
  checkoutId: any;
  show: boolean = false;
  selectedPackageId: string | undefined;
  callCenterName: string | null;
  payToday: boolean;


  
  constructor(
    private fb: FormBuilder,
    private ipAddressService: IpAddressService,
    private pipeDriveService: PipedriveService,
    private el: ElementRef,
    private addresslookup: AddressLookupService,
    private toastr: ToastrService,
    private boshhhStripeService: BoshhhStripeService,
    private rt: ActivatedRoute,
    private router: Router,
    private _totalProcessingService: TotalProcessingService,
    private  _renderer2: Renderer2,
    public datepipe: DatePipe,

    // @ts-ignore
    @Inject('env') private env) {
    this.production = this.env.production
    this.callCenterName = this.rt.snapshot.paramMap.get('org')
    this.callCenterAgentName = this.rt.snapshot.paramMap.get('name')
  }

  ngOnInit(): void {
    this.getIPAddress();
    this.initializeRegisterModel();
    
    this.initialFormSetup();

    this.initializeBishForm();
    this.initializeBashForm();
    this.acceptForm = this.fb.group({});
    this.bishFormGroup.get('emailAddress')?.valueChanges.subscribe((x: any) => {
      if(this.bishFormGroup.get('emailAddress')?.valid){
        this.checkRepeatedEmail()
      }
    })
  }
  initializeRegisterModel(): void {
    this.registerModel = {
      pipedriveCustomerDetailModel: {

      }
    }
  }

  ngAfterViewInit(): void {
    var x = document.getElementById('chat-widget-container')
    if(x != null){
      x.style.display = "none";
    }
  }

  private initializeBishForm(): void {
    this.bishFormGroup = this.fb.group({
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dateOfBirth: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[^_]+$')]],
      telephone: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
      PaymentDate: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[^_]+$')]]
    });
  }
  checkTodayDate(event: any){
    if(event.target.checked === true){
      this.payToday = true
      var date = new Date()
      let latest_date =this.datepipe.transform(date, 'dd/MM/yyyy');
      this.bishFormGroup.controls['PaymentDate'].setValue(latest_date)
      this.bishFormGroup.controls['PaymentDate'].disable()
    } else {
      this.payToday=false
      this.bishFormGroup.controls['PaymentDate'].reset()
      this.bishFormGroup.controls['PaymentDate'].enable()
    }
    
  }

  private initializeBashForm(): void {
    this.addressFormGroup = this.fb.group({
        billingPostcode: ['', Validators.required],
        billingStreet1: ['', Validators.required],
        billingLocality: [''],
        billingCity: ['', Validators.required],
        billingCounty: ['', Validators.required]
    });
    this.bashFormGroup = this.fb.group({
      addressFormGroup: this.addressFormGroup
      });
  }

  checkvalidity(){
    if(!this.bishFormGroup.valid || !this.bashFormGroup.valid) {
      this.causeValidation();
      this.causeValidationbash();
      this.toastr.error('Fields Required!', 'Please fill the form to proceed further');
    }
    else{
      this.loadintent = true
      this.registerModel.pipedriveCustomerDetailModel.firstName = this.bishFormGroup.get('firstName')?.value
          this.registerModel.pipedriveCustomerDetailModel.title = this.bishFormGroup.get('title')?.value
          this.registerModel.pipedriveCustomerDetailModel.lastName = this.bishFormGroup.get('lastName')?.value
          this.registerModel.pipedriveCustomerDetailModel.emailAddress = this.bishFormGroup.get('emailAddress')?.value
          this.registerModel.pipedriveCustomerDetailModel.telephone = this.bishFormGroup.get('telephone')?.value
          this.registerModel.pipedriveCustomerDetailModel.dateOfBirth = this.bishFormGroup.get('dateOfBirth')?.value
          this.registerModel.pipedriveCustomerDetailModel.billingStreet1 = this.addressFormGroup.get('billingStreet1')?.value
          this.registerModel.pipedriveCustomerDetailModel.billingLocality = this.addressFormGroup.get('billingLocality')?.value
          this.registerModel.pipedriveCustomerDetailModel.billingCounty = this.addressFormGroup.get('billingCounty')?.value
          this.registerModel.pipedriveCustomerDetailModel.billingPostcode = this.addressFormGroup.get('billingPostcode')?.value
          this.registerModel.pipedriveCustomerDetailModel.billingCity = this.addressFormGroup.get('billingCity')?.value
          this.registerModel.pipedriveCustomerDetailModel.callCenterAgentName = this.callCenterAgentName
          this.registerModel.pipedriveCustomerDetailModel.simPlanId = this.selectedPackageId?.toString()
          this.registerModel.pipedriveCustomerDetailModel.simPlan = this.pkgName
          this.registerModel.pipedriveCustomerDetailModel.value = this.finalprice
          this.registerModel.pipedriveCustomerDetailModel.isCallCenterLead = true
          this.registerModel.pipedriveCustomerDetailModel.registrationStage = 'Boshhh'
          this.registerModel.pipedriveCustomerDetailModel.callCenterName = this.callCenterName? this.callCenterName: ''
          this.registerModel.pipedriveCustomerDetailModel.PaymentDate = this.bishFormGroup.get('PaymentDate')?.value 
          if(this.production){
            this.registerModel.pipedriveCustomerDetailModel.stageId = 98
          }else{
            this.registerModel.pipedriveCustomerDetailModel.stageId = 98
          }
      this.pipeDriveService.customerDetails(this.registerModel.pipedriveCustomerDetailModel).subscribe(
        res => {
          this.toastr.success('Congratulations!', 'User has been Added successfully');
          
        }, err => {
          this.toastr.error('User not Added!', 'There is some error while adding User');
        }
      )
      if(this.production){
        this.router.navigateByUrl('/agent/'+this.callCenterName+'/'+this.callCenterAgentName)
          window.location.reload();
      }else{
        this.router.navigate(['/agent/success'], {queryParams: {email: this.registerModel.pipedriveCustomerDetailModel.emailAddress}})
      }
    }
  }

 






  checkRepeatedPhone(event: any){
    if(event.target.value.length >= 11){
    if(this.bishFormGroup.getRawValue().telephone != '' && this.bishFormGroup.getRawValue().telephone != null && this.bishFormGroup.getRawValue().telephone != undefined){
    this.pipeDriveService.checkRepetation('GetByMobile',this.bishFormGroup.getRawValue().telephone).subscribe(
      rep => {
        this.validPhone = rep
        if(this.validPhone == true){
          this.bishFormGroup.controls['telephone'].setErrors({'incorrect': true})
          
        }
      }
    )
    }else{
      this.validPhone = false
    }
   }
  }

  checkRepeatedEmail(){
    this.validEmail = false
    if(localStorage.getItem('CurrentUserEmail') == null && localStorage.getItem('CurrentUserEmail') == undefined){
    if(this.bishFormGroup.getRawValue().emailAddress != '' && this.bishFormGroup.getRawValue().emailAddress != null && this.bishFormGroup.getRawValue().emailAddress != undefined){
    this.pipeDriveService.checkRepetation('EmailExists',this.bishFormGroup.getRawValue().emailAddress).subscribe(
      rep => {
        this.validEmail = rep
        if(this.validEmail){
          this.bishFormGroup.controls['emailAddress'].setErrors({'incorrect': true})
        }
        
      }
    )
    }
  }
  }


  pkgInfo(event: any){
    this.selectedPackageId = event.ProductId
    this.pkgName = event.Name
    this.pkgVal = event.Price
    if(this.payToday){

      this.finalprice = Number(this.pkgVal)
      this.finalprice = Number(this.finalprice.toFixed(2))
    } else {

      this.finalprice = Number(0.01)
    }
    this.registerModel.pipedriveCustomerDetailModel.value = this.pkgVal
    this.emitselectedPkg.emit(this.selectedPackageId)
  }

  getIPAddress(): void {
    this.ipAddressService.getIPAddress()
      .subscribe(result => {
        this.ipAddress = result.ip;
      });
  }

  initialFormSetup(): void {
    this.showAddressDetails = false;
    this.manualStreet = false;

    this.addressLookupResponse = {
      county: '',
      locality: '',
      town: '',
      postCode: '',
      thoroughfares: [{
        name: ''
      }]
    }
    this.addressLookupResponse.thoroughfares = [{
      name: ''
    }
    ];
  }

  showError(code: any, message: any) {
    this.toastr.error(code, message);
  }

  showSuccess() {
    this.toastr.success('Payment Recieved!', 'Your payment has been recieved successfully');
  }

  doEnterAddressManually(): void {
    this.showAddressDetails = true;
    this.manualStreet = true;
  }

  doEnterStreetManually(): void {
    this.manualStreet = true;
    this.addressFormGroup.controls['billingStreet1'].setValue('');
  }

  doPostcodeLookup(): void {

    if (this.addressFormGroup.controls['billingPostcode'].value) {
      this.loading$.next(true);
      this.showAddressDetails = true;
      this.manualStreet = false;
      this.addresslookup.post(this.addressFormGroup.controls['billingPostcode'].value)
        .subscribe(result => {
          this.addressLookupResponse = result;
          this.addressFormGroup.setValue({
            billingStreet1: '',
            billingPostcode: this.addressLookupResponse.postCode,
            billingLocality: this.addressLookupResponse.locality,
            billingCity: this.addressLookupResponse.town,
            billingCounty: this.addressLookupResponse.county
          });
          this.postcodeCheck = true
          this.showAddressDetails = true;
          this.mySelect.open();
        }, error => {
          this.loading$.next(false);
          this.postcodeerror = 'Wrong postcode'
          this.addressFormGroup.controls['billingPostcode'].setErrors({'wrong': true})
          // this.addressFormGroup.controls['billingPostcode'].setErrors({'incorrect': true})
          this.manualStreet = true
          this.postcodeCheck = false
        }, () => {
          this.loading$.next(false);
          this.mySelect.open();
        });
    } else {
      this.addressFormGroup.controls['billingPostcode'].markAsTouched();
    }
  }
  getErrorMessage(control: any, name: string){
    if(!control.dirty){
      return 'Field is required'
    }
    if(name === 'telephone'){
      if(this.validPhone){
        return 'This phone is already connected to an account'
      }
      if(control.errors.minlength){
        return 'must contain 11 digits'
        }
      return 'Only digits allowed'
    }
    if(name === 'emailAddress'){
      if(this.validEmail){
        return 'This email is already connected to an account'
      }
      return 'Enter correct Email'
    }
    return 'Only alphabets allowed'
  }

  checkDOB(DOB: any){
    var date = DOB.value.split('/')
    var dateOfBirth = new Date(date[2]+'-' +date[1]+'-'+date[0])
    var CurrentDate = new Date();
    if(dateOfBirth.toString() === 'Invalid Date'){
      DOB.setErrors({'incorrect': true})
      return 'Enter correct date'
    }
    else if(dateOfBirth > CurrentDate){
      DOB.setErrors({'incorrect': true})
      return 'Enter correct date'
    }
    else{
      return 'Field is required'
    }
  }

  titleerrorWipe(){
    this.titleError = false
  }

  doAcceptedTerms(acceptedTerms: any) {
    this.showAgreementError = !acceptedTerms
    this.acceptedTerms = acceptedTerms;
  }

  causeValidation(): void {
    var fst = true
    Object.keys(this.bishFormGroup.controls).forEach( field => {
      const control = this.bishFormGroup.get(field);
      if (control instanceof FormControl) {
        if(field === 'title' && control.dirty === false){
          this.titleError = true
        }
        if(field !=='title' && control.dirty === false && control.valid === false && fst === true){
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + field + '"]');
          invalidControl.focus();
          fst = false
        }
        control.markAsTouched({onlySelf: true});
        if(control.invalid){
          control.setErrors({'incorrect': true})
        }

      }
    });
  }

  causeValidationbash(): void {
    var fst = true
    Object.keys(this.addressFormGroup.controls).forEach( field => {
      const control = this.addressFormGroup.get(field);
      if (control instanceof FormControl) {
        if(control.dirty === false && control.valid === false && fst === true){
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + field + '"]');
          invalidControl.focus();
          fst = false
        }
        control.markAsTouched({onlySelf: true});
        if(control.invalid){
        control.setErrors({'incorrect': true})
        }
      }
    });
  }

  ngOnDestroy() {
    var x = document.getElementById('chat-widget-container')
    if(x != null){
      x.style.display = "block";
    }
 
}
}
