import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  paymentReceived: string = ''
  thankyou: string = ''
  greet: string = ''
  whatnext: string = ''
  message: string = ''
  btnText: string = ''
  data: any;

  
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { 
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        var script = document.querySelector('.awin')
        script?.remove()
        if (!val.url.startsWith('/payment-success')) {
          // Meta tracking
          var metaParent = document.getElementsByClassName("meta-pixel")[0];
          // Remove child if any
          if (metaParent.childElementCount > 0)
          {
            metaParent.removeChild(metaParent.children[0]);
          }
        } 
      }
  })
  }

  ngOnInit() {
    this.data = this.route.data.subscribe(x => {
      var amount = localStorage.getItem('amount')
      var value = Number(amount)
       localStorage.removeItem('amount')
      if(x['res'].result?.code === '000.000.000' || x['res'].result?.code === '000.100.110'){
        var script = document.createElement("script"); // create a script tag
        script.setAttribute("class","awin");
        script.innerHTML = "dataLayer.push({'transactionTotal':\""+value+"\", 'transactionCurrency': \"GBP\", 'transactionID': \""+x['res'].id+"\",'transactionPromoCode': \"\",'event': \"awin.dl.ready\"});";

        document.head.appendChild(script);
        
        // Meta tracking
        var metaParent = document.getElementsByClassName("meta-pixel")[0];
        // Remove child if any
        if (metaParent.childElementCount > 0)
        {
          metaParent.removeChild(metaParent.children[0]);
        }
        // Add Tag
        var metaChild = metaParent.appendChild(document.createElement('div'));
        metaChild.innerHTML+="fbq('track', 'Purchase',{value:"+value+", currency:'GBP'});"

        if(window.performance.navigation.type === 1){
          this.router.navigate(['/'])
        }
      } else {
        if(window.performance.navigation.type === 1){
          this.router.navigate(['/'])
          return
        }
        this.router.navigate(['payment-failed'], { queryParams: { message: x['res'].result?.description }})
      }
    });
    
    this.setMessages()
  }

  private setMessages(){
    this.paymentReceived = 'Payment Received'
    this.thankyou = 'Thank you for choosing Boshhh as your network'
    this.greet = 'Welcome to Boshhh'
    this.whatnext = 'Congratulations!Welcome to boshhh! well done for taking the first steps to improve your credit score and have the life you deserve!'
    this.message = 'In the next few days you will receive your new boshhh sim card in the post Along with a easy guide on how to activate your sim, move your number over and start your credit building journey on our app!'
    this.btnText = 'Back to Home'
  }

  public navigate (router: string): void {
    this.router.navigateByUrl(router)
    
  }

}
