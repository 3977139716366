import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-of-practice',
  templateUrl: 'code-of-practice.component.html'
})
export class CodeOfPracticeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
