import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-returns-policy',
  templateUrl: 'returns-policy.component.html',
  styles: [],
})
export class ReturnsPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
