
      <div class="hero-section mobile-section" >
        <div class="container ">
            <div class="row ">
                <div class="col-md-6 col-sm-6 d-flex align-items-center">
                    <div class="">
                        <h1>Phones you love</h1>
                        <p>Shop for the latest, top brands like iPhone and Samsung. These products are professionally tested and certified, and all come with a 6-month warranty. Get that!
                        </p>
                        <button *ngIf="linkEnabled" (click)="navigate('/mobiles?hidepanel=true')" class="btn btn-primary">Pre-Order Phone</button>
                    </div>
                </div>
                <!-- <div class="col-md-6 col-sm-6 slider-img" (click)="navigate('/mobiles')"> -->
                <div class="col-md-6 col-sm-6 slider-img">
                  <a *ngIf="linkEnabled" (click)="navigate('/mobiles?hidepanel=true')">
                    <img class="hero-img" src="/assets/images/hero-img.png">
                  </a>
                  <img *ngIf="!linkEnabled" class="hero-img" src="/assets/images/hero-img.png">
                </div>
            </div>
        </div>
    </div>
