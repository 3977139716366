import { Component, OnInit } from '@angular/core';
import { CommonModule} from '@angular/common'

@Component({
  selector: 'app-mobiles-store',
  templateUrl: './mobiles-store.component.html',
  styleUrls: ['./mobiles-store.component.css']
})
export class MobilesStoreComponent implements OnInit {
  products: any
  constructor(
    
  ) { }

  ngOnInit() {

    this.products = [
      {
        'name': 'Iphone XS-White',
        'discount': '20',
        'imgURL': 'xs-white',
        'monthlyAmount': '23.50',
        'upfrontAmount': '150.99',
    },
    {
      'name': 'Iphone XS-Gold',
      'discount': '20',
      'imgURL': 'xs-gold',
      'monthlyAmount': '23.50',
      'upfrontAmount': '150.99',
    },
    {
      'name': 'Iphone XS-Black',
      'discount': '20',
      'imgURL': 'xs-black',
      'monthlyAmount': '23.50',
      'upfrontAmount': '150.99',
    },{
      'name': 'Samsung-Se',
      'discount': '20',
      'imgURL': 'se',
      'monthlyAmount': '23.50',
      'upfrontAmount': '150.99',
    },{
      'name': 'Samsung-Sa50',
      'discount': '20',
      'imgURL': 'sa50',
      'monthlyAmount': '23.50',
      'upfrontAmount': '150.99',
    }
    ,{
      'name': 'Samsung-S51',
      'discount': '20',
      'imgURL': 's51',
      'monthlyAmount': '23.50',
      'upfrontAmount': '150.99',
    }
    ,{
      'name': 'Samsung-S10e',
      'discount': '20',
      'imgURL': 's10e',
      'monthlyAmount': '23.50',
      'upfrontAmount': '150.99',
    }
  ]

  console.log(this.products)





}
}
