import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { IRegisterModell } from "../models/register.model";

@Injectable({
  providedIn: 'root'
})
export class MbillsService {
  setregisterModellObject: any
  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public register(body: IRegisterModell): Observable<any> {
    return this
      .http
      .post<IRegisterModell>(`${this.env.mbillsApi}/api/register`,
        body,
        this.httpOptions
      );
  }

  public setAccount(accountNumber: string, newPassword: string): Observable<any> {
    return this
      .http
      .post<IRegisterModell>(`${this.env.pipedriveApi}/api/MBills/Reset-Password?accountNumber=${accountNumber}&password=${newPassword}`,
        '',
        this.httpOptions
      );
  }

  public setPassword(accountNumber: string, newPassword: string): Observable<any> {
    return this
      .http
      .post<IRegisterModell>(`${this.env.pipedriveApi}/api/MBills/set-Password?accountNumber=${accountNumber}&password=${newPassword}`,
        '',
        this.httpOptions
      );
  }

  public getdashboard(accountNo: string): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/DashBoard_login?accountNumber=${accountNo}`, this.httpOptions)
  }

  public getCLI(accountNo: string): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/GetMobileNo?accountNumber=${accountNo}`, this.httpOptions)
  }

  public CreatePortInAsync(data: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/CreatePortInAsync`,data, this.httpOptions)
  }

  public getUsage(accountNo: string, CLI: string): Observable<any>{
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/GetUsageAsync?accountNumber=${accountNo}&cli=${CLI}`, this.httpOptions)
  }

  public getInvoices(accountNo: string): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/GetInvoiceAsync?accountNumber=${accountNo}`, this.httpOptions)
  }

  public getUserProfile(accountNo: string): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/GetContactDetailsAsync?accountNumber=${accountNo}`, this.httpOptions)
  }

  public getUserAddressProfile(accountNo: string): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/GetAddressAsync?accountNumber=${accountNo}`, this.httpOptions)
  }

  public editUserProfile(user: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/UpdateUserAddress`, user, this.httpOptions)
  }

  public changePassword(passObj: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/ChangePasswordAsync`, passObj, this.httpOptions)
  }

  public getPlanAsync(data: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/GetPlanAsync?accountNumber=${data.accountNumber}&cli=${data.cli}`, this.httpOptions)
  }

  public getBoltOn(): Observable<any> {
    return this.http
    .get(`${this.env.pipedriveApi}/api/MBills/GetItems`, this.httpOptions)
  }

  public addBoltOn(pkg: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/AddBoltOnAsync`, pkg, this.httpOptions)
  }

  public addBoltOnPayment(acNo: string, amount: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/CreateBoltonPaymentAsync?accountNumber=${acNo}&amount=${amount}`, this.httpOptions)
  }

  public activeSim(simobj: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/TRMOActivate?email=${simobj.email}&sim=${simobj.sim}`, this.httpOptions)
  }

  public getTickettypes(): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/TicketType/TicketTypesAsync`, this.httpOptions)
  }

  public postHelp(helpObj: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/Tickets/CreateTicketAsync`,helpObj, this.httpOptions)
  }

  public parentaccess(obj: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/CreateParentControl`,obj , this.httpOptions)
  }
  public getAccNoFromToken(){
    var token = localStorage.getItem('UserContext') 
        if(token !== null){
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      var name = JSON.parse(jsonPayload)
      return name.name;
  }
}

  public changePackagePlan(data: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/MBills/ChangePackageAsync`,data, this.httpOptions)
  }

  public upsellOrderAPI(data: any): Observable<any> {
    return this.http
    .post(`${this.env.pipedriveApi}/api/Sticky/UpsellOrder?productId=${data?.productId}&accountNo=${data?.accountNo}`,data, this.httpOptions)
  }
  
  public setregisterModell(obj: any){
    this.setregisterModellObject = obj
  }
  public getsetregisterModell(){
    return this.setregisterModellObject
  }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };
}
