<!-- <div id="mini-slider" class="carousel mini-slider theme-background" data-bs-ride="carousel">
  <div class="mobile-view">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#mini-slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#mini-slider" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#mini-slider" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner stepper-slider">
      <div class="carousel-item active">
        <p>14 Day money back guarantee</p>
      </div>
      <div class="carousel-item">
        <div class="container">
          <p>5G Ready at no extra cost</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="container">
          <p>Boost Your Credit Score with Equifax</p>
        </div>
      </div>
      <div class="carousel-item">
        <div class="container">
          <p>Worldwide roaming available</p>
        </div>
      </div>
    
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#mini-slider" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#mini-slider" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div class=" desktop-view container">
    <div class="row">
      <div class="col-md-3 col-sm-3 md-flex-center">
        <img src ="/assets/images/wifi.png" alt="wifi">
        <p>Unbeaten for coverage in the UK</p>
      </div>
      <div class="col-md-3 col-sm-3 md-flex-center">
        <img src="/assets/images/bar-chart.png"  alt="bar-char">
        <p>5G Ready at no extra cost</p>
      </div>
      <div class="col-md-3 col-sm-3 md-flex-center">
        <img src="/assets/images/smartphone.png"  alt="smart-phone">
        <p>Boost Your Credit Score with Equifax</p>
      </div>
      <div class="col-md-3 col-sm-3 md-flex-center">
        <img src="/assets/images/globe.png"  alt="globe">
        <p>Worldwide roaming available</p>
      </div>
    </div>



  </div>
</div> -->

<div id="mini-slider" class="mini-slider theme-background">
  <div class="container text-center">
    <p>Worldwide roaming available</p>
  </div>
</div>