<div class="payment payment_header">
  <div class=" container">
    <div class="row  text-light p-3 ">
      <div class="col-md-6 col-lg-6 d-flex align-items-center d-flex">
        <img class=" me-2" src="assets/images/payment-check.svg" width="60px" />
        <div>
          <h4 class="f-20">Congratulations!</h4>
          <p class="f-16 mb-2">We have submitted your lead.</p>
          <p class="f-16 m-0">Thank you for choosing Boshhh as your network</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 d-flex flex-column py-3">
        <h4 class="fw-bold text-left m-0">Welcome to Boshhh</h4>
        <p class="text-left">What happens next?</p>
        <p class="text-left">We have sent a message to you client. Get contected with your client.Make payment and
          unlock Boshhh features </p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <!-- Section: Timeline -->
  <section class="py-5">
    <ul class="timeline">
      <li class="timeline-item mb-5" [ngClass]="{ active: leadCreated }">
        <h5 class="fw-bold">Lead Created</h5>
        <p class="text-muted">
          Your lead has been created in our system successfully
        </p>
      </li>

      <li class="timeline-item mb-5" [ngClass]="{ active: linkSent }">
        <h5 class="fw-bold">Link Sent</h5>
        <p class="text-muted">
          We have sent a SMS with payment page link to customer. Please call your client and ask him/her to click on link sent via sms to registered number.
        </p>
      </li>

      <li class="timeline-item mb-5" [ngClass]="{ active: linkOpened }">
        <h5 class="fw-bold">Client Opened Link</h5>
        <p class="text-muted">
          Client has click on link we sent the via SMS to registered number. Please ask your client to proceed payment through form.
        </p>
      </li>

        <li class="timeline-item mb-5" [ngClass]="{'active': PaymentSucccess , 'reject': PaymentFailed}" >
          <h5 class="fw-bold" [hidden]="PaymentFailed">Payment Successful</h5>
            <h5 class="fw-bold" *ngIf="PaymentFailed">Payment Failed</h5>
          <p class="text-muted" [hidden]="PaymentFailed">
            Hurray! Your client's payment has been received successfully.
          </p>
          <p class="text-muted" *ngIf="PaymentFailed">
            Unfortunately! Your client's has been failed. Please ask your client to check balance in his/her bank account and try again. id feugiat tortor rhoncus vitae. Ut suscipit vulputate
            mattis.
          </p>
        </li>
      <!-- </ng-container>
      <ng-container *ngIf="PaymentFailed">
      <li class="timeline-item mb-5 reject">
          <h5 class="fw-bold">Payment Failed</h5>
        <p class="text-muted">
          Unfortunately! Your client's has been failed. Please ask your client to check balance in his/her bank account and try again. id feugiat tortor rhoncus vitae. Ut suscipit vulputate
          mattis.
        </p>
      </li>
      </ng-container> -->
    </ul>
  </section>
  <!-- Section: Timeline -->
</div>
