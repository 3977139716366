<!-- Popup code hidden Start-->
<div class="p-relative d-none">
  <div class="model-container" id="dialogBox">
    <div class="container h-100 d-flex align-items-center justify-content-center">
      <div class="mode-body bg-light py-5 px-4 text-center">
          <!-- <h3>BEFORE YOU GO...</h3>
          <h2 class="fw-bold mb-2">UNLOCK 20% OFF</h2> -->
          <img  src="/assets/images/boshhh-sim.jpeg" width="60" alt="Boshhh Sim Card" >
          <h4 class="text-gray fw-bold my-3">Sign up now just £2.99 for 14 days. Unlimited mins Unlimited text 10GB Data</h4>
          <button class="bosh-btn-primary" (click)="goToRegister()">Get it now</button>
          <!-- <p class="text-center py-2 mb-0">Applied to all items</p> -->
          <p class="text-center text-gray no-thanks border-1 py-3 m-0" (click)="hideDialog()">No Thanks</p>
      </div>
    </div>
  </div>
</div>
<!-- Popup code hidden Start-->

<div class="hero-section" [ngClass]="classFlag ? 'pb-2' : 'pt-0'">
  <p class="fw-normal text-center f-14 m-0"> Roam around Europe on us</p>
  <div class="container">
    <div class="row content text-light pb-3">
      <div class="col-md-6 col-sm-6">
        <h1>The network that BOOSTS your credit score!</h1>
        <h3>Powered by  <b>Equifax</b> and <b>EE</b> the Uk's most reliable network</h3>
        <div class="d-flex justify-content-between p-relative">
          <div>
            
            <!-- <p class="my-0"><b>Boshhh™️ Credit BOOSTER</b> can improve your score with Equifax.</p> -->
            <div class="checklist my-2">
              <p>Guaranteed acceptance</p>
              <p>Boost your credit score              </p>
              <p>Access your credit file</p>
              <p>Keep your number</p>
              <!-- <p>Mobile handsets</p> -->
            </div>
          </div>
          <div class="mobile-img">
            <img src="/assets/images/phone-desktop.png" alt="phone-desktop">
          </div>
        </div>
        
        <div class="d-flex justify-content-between ">
          <div class="btn-container py-2 px-1 w-50 " >
            <p class="m-0 f-12 text-center">New to Boshhh</p>
            <button class="btn bosh-btn-primary f-14" (click)="goToRegister()">Boost My Credit</button>
            <div class="cards  d-flex justify-content-center mt-2">
              <div class="d-flex">
                <img class="me-2" src="/assets/images/visa.svg" alt="visa-card"> 
                <img src="/assets/images/mastercard.svg" alt="master-card"> 
              </div>
            </div>
          </div>
          <div class="btn-container py-2 px-1 w-50" >
            <p class="m-0 f-12 text-center">Already have a SIM</p>
            <button class="btn bosh-btn-primary btn-border-success text-success f-14" (click)="goSimActivate()">Activate SIM</button>
          </div>
        </div>
        
      </div>
      <div class="col-md-6 col-sm-6 d-flex justify-content-center">
        <div class="desktop-img ">
          <img src="/assets/images/phone-desktop.png" alt="phone-desktop">
        </div>
      </div>
      
    </div>
 
  </div>
</div>
<!-- <div class="popup-banner d-flex justify-content-center">
  <img class="desktop-view" src="/assets/images/web_banner.png" alt="boshhh-banner" width="50%">
  <img class="mobile-view" src="/assets/images/mobile_banner.png" alt="boshhh-banner" width="100%">
</div> -->
<div class="container equifax-content">
  <div class="journey-process d-flex align-items-center flex-column " id="boshhh-journey">
  <!-- <h2 class="fw-bold pt-3 text-gredient">The clever mobile network</h2> -->
  <img class="pt-3" src="/assets/images/tagline.png" width="330" alt="boshhh-tagline">
    <div class="">
      <div class=" stepper-slider">
        <div class="">
          <div class="block block-1">
            <!-- <h2 class="text-center bg-number">01</h2> -->
            <img src="/assets/images/stepper/bish-1.png" alt="stepper-bish" width="150px">
            <!-- <h2 class="fw-bolder text-gredient">Bish!</h2> -->
            <div class="content">
                <!-- <h3 class="fw-bold text-gray">Choose a plan that fits your needs</h3> -->
                <p class="text-gray gray-box mb-0 "> Boshhh is the worlds first mobile network thats designed to increase your credit score. We make your SIM card your credit building super power by generating a credit agreement. If you cant get credit you can't build credit, you can with Boshhh.                </p>
            </div>
          </div>
        </div>
        
          <div class="">
            <div class="block block-2">
              <div class="d-flex images justify-content-between p-relative">
                <!-- <img class="arrow-right" src="/assets/images/curve-arrow.png" alt="boshh-arrow-right"> -->
                <img class="bash-sticker" src="/assets/images/stepper/bash-1.png" alt="stepper-bash" width="150px">
              </div>
              <!-- <h2 class="fw-bolder text-gredient">Bash!</h2> -->
              <div class="content">
                  <!-- <h3 class="fw-bold text-gray text-end">Access to your Equifax credit file </h3> -->
                  <p class="text-gray gray-box  mb-0"> 4 easy steps! Pick one of our SIM plans, make your first payment, receive your welcome pack and finally, sign up with our app to see your credit report. Its as easy as that, we do the rest.                  </p>
              </div>
            </div>
          </div>
        
          <div class="">
            <div class="block block-3">
              <div class="d-flex images justify-content-between p-relative">
                <img class="bosh-sticker" src="/assets/images/stepper/boshhh-1.png" alt="stepper-boshhh" width="150px">
                <!-- <img class="arrow-right" src="/assets/images/curve-arrow-left.png" alt="boshh-arrow-right"> -->
              </div>
              <div class="content">
                  <!-- <h3 class="fw-bold text-gray">Positive impact on your credit file!</h3> -->
                  <p class="text-gray gray-box mb-0 ">Financial freedom. We report your on time payments straight to our partners at equifax and you will be able to see your credit score skyrocket as the months fly by.</p>
              </div>
            </div>
          </div>
      </div>
    </div>
    <p class="mt-2 text-center text-gray mb-0"><b>P.S Want to keep your current number? Bring it along! At Boshhh we've got you covered.</b></p>
    <div class="btn-container mt-4 w-100" (click)="goToRegister()">
      <button class="bosh-btn-primary ">See our plans</button>
    </div>
  </div>
</div>
<div class="pt-2 text-center container equifax-content ">
  <!-- <div class="inner-content">
    <h2 class="mb-2 fw-bolder text-gray">
      Your full  <img  src="/assets/images/efx_hr_logo_horizontal.png" width="165px"> credit report going back 6 years! Giving you COMPLETE access to everything any creditor or bank can see!
    </h2>
  </div> -->
  <app-credit-file></app-credit-file>
  <p class="text-gray">Create your wishlist when you join us, when your credit score is ready we will let you know, wether it’s a new sofa, car or even a house we will help, guide and support you to a better future.</p>

  <div class=" cursorptr rounded fading-text position-relative d-none" (click)="revealText()">
    <div class="bg-green p-3 ">
      <h2 class="text-light m-0">What's the point?</h2>
      <h2 class="m-0">👇</h2>
    </div>
    <app-what-the-point></app-what-the-point>
    <div class="white-gredient position-absolute"></div>
</div>
  
  

</div>
<div class="mobile-section-container bg-yellow d-none">
  <div class="container">
    <div class="row justify-content-between py-3">
      <div class="w-70 d-flex justify-content-center flex-column align-items-center">
        <img src="assets/images/mobile-banner.svg" width="100%">
        <p class="fw-bold f-14 mb-0 mt-3 text-center line-h-14">All our handsets are pre loved 😍 and friendly to the environment ♻️</p>
      </div>
      <div class="w-30 d-flex justify-content-center align-items-center">
        <img src="assets/images/iphone-13.png" width="100%">
      </div>
    </div>
  </div>
</div>

<div class="bg-light-gray my-3 ">
  <div class="container text-center py-4 partner-logo">
    <h3 class="">Our members enjoy discounts and deals with many highstreet vendors.</h3>
    <h3 class="">With over 75 partners you can save a bundle</h3>
    <div class="d-flex flex-wrap  justify-content-between">
        <div class="xs-4 logo">
          <img src="/assets/images/spons-1.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-2.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-3.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-4.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-5.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-6.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-7.svg" >
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-8.svg">
        </div>
        <div class="xs-4 logo">
          <img src="/assets/images/spons-9.svg">
        </div>
    </div>
    <div class="btn-container mt-4" (click)="goToRegister()">
      <button class="bosh-btn-primary ">See our plans</button>
    </div>
  </div>
</div>
<!-- <app-sim-plan-hidden-price></app-sim-plan-hidden-price> -->
<div id="faq">

  <app-qa-section-hidden></app-qa-section-hidden>
</div>




