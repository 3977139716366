<div class="container">
  <div class="content-page-container">
    <h2 hidden>Let's Get Started Building Your Credit Score</h2>
  </div>
  <form [formGroup]="bishFormGroup" class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <h3 class="py-1">Select Title</h3>
      <mat-button-toggle-group #tgl (click)="titleerrorWipe()" appearance="standard" formControlName="title" class="mt-3 col-12" required>
        <mat-button-toggle [value]="'Mr'" style="width:100%">Mr</mat-button-toggle>
        <mat-button-toggle [value]="'Mrs'" style="width:100%">Mrs</mat-button-toggle>
        <mat-button-toggle [value]="'Miss'" style="width:100%">Miss</mat-button-toggle>
        <mat-button-toggle [value]="'Ms'" style="width:100%">Ms</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <!-- <div [ngStyle]="{'border': (this.titleError) ? '2px solid red':''}"> -->
      <p *ngIf="this.titleError" class="text-danger">Please select title</p>
    <!-- </div> -->
    
    <div class="col-lg-6 col-md-6 col-sm-6">
      <mat-form-field appearance="outline">
        <mat-label>First name:</mat-label>
        <input type="text" matInput formControlName="firstName" pattern="[a-zA-Z ]*" required>
        <mat-error style="margin-top: 16px !important" *ngIf="this.bishFormGroup.get('firstName')?.invalid">{{getErrorMessage(this.bishFormGroup.get('firstName'),'firstName')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <mat-form-field appearance="outline">
        <mat-label>Last name:</mat-label>
        <input matInput formControlName="lastName" pattern="[a-zA-Z ]*" required>
        <mat-error style="margin-top: 16px !important" *ngIf="this.bishFormGroup.get('lastName')?.invalid">{{getErrorMessage(this.bishFormGroup.get('lastName'),'lastName')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <mat-form-field appearance="outline">
        <mat-label>Date of birth:</mat-label>
        <input type="tel" matInput [textMask]="{mask: dateMask}" (blur)="checkDOB(this.bishFormGroup.get('dateOfBirth'))" placeholder="DD/MM/YYYY" formControlName="dateOfBirth">
        <mat-error style="margin-top: 16px !important" *ngIf="this.bishFormGroup.get('dateOfBirth')?.invalid">{{checkDOB(this.bishFormGroup.get('dateOfBirth'))}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <mat-form-field appearance="outline">
        <mat-label>Telephone:</mat-label>
        <input type="text" matInput formControlName="telephone" pattern="\d*" maxlength="11" minlength="11" required (keyup)="checkRepeatedPhone($event)" (keyup)="checkRepeatedPhone($event)">
        <mat-error style="margin-top: 16px !important" *ngIf="this.bishFormGroup.get('telephone')?.invalid">{{getErrorMessage(this.bishFormGroup.get('telephone'),'telephone')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Email address:</mat-label>
        <input type="email" matInput formControlName="emailAddress" required (blur)="checkRepeatedEmail()">
        <!-- <mat-error style="margin-top: 16px !important" *ngIf="this.validEmail == true">This email is already connected to an account</mat-error> -->
        <mat-error style="margin-top: 16px !important" *ngIf="this.bishFormGroup.get('emailAddress')?.invalid">{{getErrorMessage(this.bishFormGroup.get('emailAddress'),'emailAddress')}}</mat-error>
      </mat-form-field>
      <!-- <div *ngIf="this.validEmail" class="mat-error ng-tns-c36-8 ng-star-inserted">
        <p class="errMsg text-danger p-0 m-0">This email is already connected to a account</p>
      </div> -->
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="check-box">
      <input class="form-check-input" type="checkbox" (click)="checkTodayDate($event)" id="payment_confirmation">
      <label class="form-check-label" for="payment_confirmation">
        Pay Today.
      </label>
    </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <mat-form-field appearance="outline">
        <mat-label>Payment Date:</mat-label>
        <input type="tel" matInput [textMask]="{mask: dateMask}" (blur)="checkDOB(this.bishFormGroup.get('dateOfBirth'))" placeholder="DD/MM/YYYY" formControlName="PaymentDate">
        <mat-error style="margin-top: 16px !important" *ngIf="this.bishFormGroup.get('dateOfBirth')?.invalid">{{checkDOB(this.bishFormGroup.get('dateOfBirth'))}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="col-lg-12" [formGroup]="bashFormGroup">
    <h3 class="py-3">Please Enter your address details</h3>
    <app-address formGroupName="addressFormGroup"></app-address>
  </div>

  <!---------------------------------------------------- bish yaha tk ------------------------------------------------------------------------>
  

  <div class="row">
  
  

    <div class="col-lg-12">
      <h3 class="py-3">Plan <span class="text-grey">Which is best for you?</span></h3>
      <app-agent-package-selector (packageInfo)="pkgInfo($event)"></app-agent-package-selector>
    </div>
  </div>

  <!-- <div class="bash_product">
    <h3 class="py-3 text-center">Pre order your handset (Optional)</h3>
      <app-purchase-handset-selector (mobileInfo)="phoneinfo($event)"></app-purchase-handset-selector>
  
  </div> -->

  <!---------------------------------------------------- bosh yaha tk ------------------------------------------------------------------------>
  


  <a
  mat-button
  style="margin-top: 20px !important;"
  color="primary"
  class="bosh-btn-primary col-12 mt-1 pt-2 pb-2 mb-5" (click)="checkvalidity()">Proceed</a>


<div class="form-footer">
  <div class="cards  d-flex justify-content-center mt-2">
    <div class="d-flex justify-content-center">
      <img class="me-2" src="/assets/images/visa.svg">
      <img src="/assets/images/mastercard.svg">
    </div>
  </div>
</div>
</div>