
<div class="row align-items-end package-container purchase-form  flex-md-row-reverse">
  <div class="package-design-2  col-md-4 col-sm-4 col-lg-4 my-2 " *ngFor="let plan of packagePlans; index as i">
    <div *ngIf="plan.name != 'Credit File'">
      
      <div class="plan-block"> 
        <div *ngIf="plan.Name   == '30GB Data' " class="most-popular-tag">
          <p class="m-0"><span>MOST </span> <span>POPULAR</span></p>
        </div>
        <div *ngIf="plan.Name   == '100GB Data' " class="most-value-tag">
          <p class="m-0">MOST VALUE</p>
        </div>
        <div class="">
          <h2 class="package-name">{{ plan.Name }}</h2>
        </div>
        <div class="d-flex align-items-center">
          <p class="price">£{{ plan.Price }}</p>
          <p class="per-month text-center">Per Month</p>
        </div>
        <div class="specs">
          <div class="check-img">
            <img src="/assets/images/check.svg">
          </div>
          <p class="text">Get <b>unlimited </b>calls and texts</p>
        </div>
        <div class="specs">
          <div class="check-img">
            <img src="/assets/images/check.svg">
          </div>
          <p class="text">Free access to<b> Boshhh Credit Booster</b></p>
        </div>
       
        <div class="specs">
          <div class="check-img">
            <img src="/assets/images/check.svg">
          </div>
          <p class="text">Free access to your <b>credit report</b></p>
        </div>
        <div class="specs">
          <div class="check-img">
            <img src="/assets/images/check.svg">
          </div>
          <p class="text">Free access to <b>tools to improve your finances</b></p>
        </div>
        <div class="specs">
          <div class="check-img">
            <img src="/assets/images/check.svg">
          </div>
          <p class="text">Free access to <b>Boshhh Smart Match</b></p>
        </div>
        <input type="radio"  name="package_name" id="package_id-{{ i }}" value="{{ plan.stripePriceId }}"> 
        <label  for="package_id-{{ i }}" (click)="selectedPlan(plan)" class="bosh-btn-radio bosh-btn-primary text-center "  style="background-color: none">
          Select Plan
        </label>

        <div class="cards d-flex justify-content-center mt-2">
          <div class="d-flex  justify-content-center">
            <img class="me-2" src="/assets/images/visa.svg">
            <img src="/assets/images/mastercard.svg">
          </div>
      </div>
     
    </div>
  </div>
  <!-- <h3 class="py-3">Plan <span class="text-grey">Bold On</span></h3>
  <div class="package-design-2" *ngFor="let plan of packageBoldOnPlans; index as i">
    <input type="radio"  name="package_name1" id="package_id{{ i }}" value="package{{ i }}" > 
    <label for="package_id{{ i }}" (click)="selectedPlan(plan.mbillsPackageId, plan.stripePriceId)" class="plan-details d-flex align-items-center justify-content-between">
      <div class="specs">
        <h2>{{ plan.name }}</h2>
        </div>
      <div class="price">
        From <b>£{{ plan.priceIncVAT }}</b>
      </div>
    </label>
  </div> -->
</div>
