<div class="container content-page-container">
  <h3>Recurring </h3>

  <p>
    This recurring policy applies to users who have subscribed to one of the following Boshhh services:
  </p>
  <ul>
    <li>1.	10 GB Ultra Fast Sim Plan (£19.99)</li>
    <li>2.	30 GB Ultra Fast Sim Plan (£24.99)</li>
    <li>3.	100 GB Ultra Fast Sim Plan (£29.99)</li>
    <li>4.	Boshhh Credit File (£14.99)</li>
  </ul>

  <p>By subscribing to any of these services, you agree to the terms and conditions set forth in this policy.</p>

  <p><b>  1. Services and Automatic Charges</b></p>
  

  <p>When you subscribe to one of the services listed above, your credit card will be automatically charged on a recurring basis according to the pricing plan you have selected. The specific service and associated charge are as follows:</p>

  <ul>
    <li>1.	10 GB Ultra Fast Sim Plan (£19.99)</li>
    <li>2.	30 GB Ultra Fast Sim Plan (£24.99)</li>
    <li>3.	100 GB Ultra Fast Sim Plan (£29.99)</li>
    <li>4.	Boshhh Credit File (£14.99)</li>
  </ul>
  <p><b>2. Recurring Dates</b></p>
  <p>Your card will be charged one month after the activation date of your Sim plan or the sign-up date for the Boshhh Credit File. The charge will continue to recur on the same date every month, unless the service is cancelled as outlined in Section 4 of this policy.</p>

  <p><b>4. Automatic Charge Amounts</b></p>

  <p>The amounts charged to your card will be as follows:</p>


  <ul>
    <li>1.	10 GB Ultra Fast Sim Plan (£19.99)</li>
    <li>2.	30 GB Ultra Fast Sim Plan (£24.99)</li>
    <li>3.	100 GB Ultra Fast Sim Plan (£29.99)</li>
    <li>4.	Boshhh Credit File (£14.99)</li>
  </ul>

  <p>These amounts will remain consistent throughout the duration of your subscription, unless otherwise modified in accordance with the terms and conditions of the service.</p>

  <p><b>5. Cancellation Policy</b></p>

  <p>To cancel your recurring service, you must email cancellation@boshhh.com. If you are outside of the terms of your contract, no additional charges will apply. However, if you are still within the terms of your contract, you will be required to pay the remaining outstanding balance to exit the contract.</p>

  <p>Please ensure that you include your full name, account number, and the specific service you wish to cancel in your cancellation email. This will ensure that your request is processed promptly and accurately.</p>

  <p>By subscribing to any of the above-listed services, you agree to the terms and conditions set forth in this recurring policy.</p>








  <p>The handset is loaned to you on a 12 month credit agreement and will remain the property of Boshhh LTD until the final payment is made </p>

  <ul>
    <li>
      <a role="button" (click)="navigate('/call-record-policy')">Call Record Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/calls-data-roaming-charges')"
      >Calls Data Roaming Charges</a
      >
    </li>
    <li>
      <a role="button" (click)="navigate('/refund-policy')">Refund Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/code-of-practice')">Code Of Practice</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/how-to-and-faqs')">How To And FAQ's</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/privacy-policy')">Privacy Policy</a>
    </li>
    <li class="">
      <a class="text-decoration-none font-size-sm text-gray-dark" role="button" (click)="navigate('/recurring-policy')">Recurring Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/cookie-policy')">Cookie Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/returns-policy')">Returns Policy</a>
    </li>
  </ul>
</div>
