// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentConfiguration: 'DEVELOPMENT',
  addressLookupApi: 'https://app-admin-api-boshhh-dev-001.azurewebsites.net',
  fetchifyPostCodeApi: 'http://pcls1.craftyclicks.co.uk/json/basicaddress',
  mbillsApi: 'https://func-boshhh-mbills-dev-001.azurewebsites.net',
  pipedriveApi: 'https://app-admin-api-boshhh-dev-001.azurewebsites.net',
  stripeApi: 'https://func-boshhh-stripe-dev-001.azurewebsites.net',
  termsApi: 'https://func-boshhh-terms-prod-001.azurewebsites.net',
  priceIdOne: 'price_1MH3fdE8CpEsx2nld9rkNwwF',
  priceIdTwo: 'price_1MH3gRE8CpEsx2nlrdBAGL5R',
  priceIdThree: 'price_1MH3gwE8CpEsx2nl0gvVlDkL',
  stripeProdID: 'price_1Me0igE8CpEsx2nlEaSipVFs',
  initialSetupFee: 7.99,
  stripePublishableKey: 'pk_test_51LzeVtE8CpEsx2nlxBP5DH5tCqofm4Gf1L05NdXbtHq1oQJWmbtYMIDEcNl2xAosHAVwq2wT2eHrWZ3IlKnx4doU00FmpfiRXs',
  pdfMonkeyToken: 'Bearer tjC9v7vJehCBPweHUxCxDCkhoke-yY9y',
  fetchifyPostCodeKey: '29314-66287-8a0b3-08aea',
  totalProcessingIntent: 'https://eu-test.oppwa.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
