import { Component } from '@angular/core';
import { IStepperCalculator } from '../../shared/models/stepper-calculator.model';
import { Router } from '@angular/router';

// THIS PAYMNET WIDGET IS FOR TESTING PURPOSES
// declare var PaymentWidgets: any;

@Component({
  selector: 'app-stepper-calculator',
  templateUrl: './stepper-calculator.component.html',
  styleUrls: ['./stepper-calculator.component.css']
})
export class StepperCalculatorComponent{
  value: any;
  type: any;
  
  stepperCalculator: IStepperCalculator
  constructor(
    private router: Router
  ) {
    
    
    this.stepperCalculator = {} as IStepperCalculator
  }
  classFlag = false;
  ngOnInit(){
    this.stepperCalculator.billPerMonth = 20
  }

  MoveNext(param: any, stepper: any){
    this.stepperCalculator.contractorOrPAYG = param
    this.type = param
    console.log(this.stepperCalculator);
    stepper.next()
  }

  CSRatingFunc(param: any, stepper: any){
    this.stepperCalculator.CSRating = param
    console.log(this.stepperCalculator);
    stepper.next()
  }

  CScheckerFunc(param: any, stepper: any){
    this.stepperCalculator.CSchecker = param
    

   this.value =  this.stepperCalculator.billPerMonth + this.stepperCalculator.CSRating

  this.value = (this.value * 12)
  this.value = (this.value - 191.88).toFixed(2)
  if(this.value > 0){
    console.log(this.value);
    console.log("value greater");
  }else{
    console.log("value lower");
  }
  console.log(this.stepperCalculator);
    stepper.next()
  }

  public goToRegister() {
    this.router.navigate(['/register/packages']);
  }
 
}
