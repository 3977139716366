import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-sim',
  templateUrl: './pending-sim.component.html',
  styleUrls: ['./pending-sim.component.css']
})
export class PendingSimComponent implements OnInit {

  constructor(
    ) { }

  ngOnInit() {
    

  }
}
