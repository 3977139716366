
<div class="content-page-container">
  <h2>How do I cancel a contract within 14 days online?</h2>

  <p>Where you have a contract with us or have taken a Product</p>

  <h3>To cancel a contract by email:</h3>

  <p>Fill in our contact us on: 03333 4444 15 or email us on: returns@boshhh.com</p>

  <p>Once your form has been received and processed, our cancellations team will get in touch with you to advise how to return your equipment if you have any and next steps.</p>

  <h3>How do I return a product to Boshhh?</h3>

  <p>Once you’ve contacted us to confirm your return, we’ll send you a pre-addressed silver bag, which is tracked and insured to offer protection against loss and damage.</p>

  <p>Follow these instructions to ensure your return is processed as quickly as possible:</p>

  <ul>
    <ol>Let us know you intend to make a return by contacting us within 14 days of receiving your order</ol>
    <ol>New, unused items must be sealed when they’re returned</ol>
    <ol>Make sure any theft or loss-protection apps on your device are disabled (e.g. Find My Phone on iPhone or iPad)</ol>
    <ol>Ensure all items, including accessories and free promotional items, are returned undamaged. You may be charged for damaged items</ol>
  </ul>

  <h3>What is the postage cost to return an item?</h3>

  <p>You're responsible for the postage cost of returning equipment. However, please keep proof of posting and we’ll refund up to the price of a standard delivery charge.</p>

  <h3>How does the cooling-off period work?</h3>

  <p>You’ve got 14 days to return your purchases to us after your contract starts and if you cancel your contract, you’ll be charged line rental and anything used while you had the plan and our services.</p>

  <p>You’ll need to pay for the postage for anything you return to us and if we get something back that’s damaged or missing some accessories, we might make deductions from your refund.</p>

  <p>
    If you choose to cancel the agreement after the 14-day cooling off period there may be a cancellation fee of 14 days use of the service.
  </p>

  <h3>Can I return a device I've purchased through a device credit agreement if I'm not happy with it?</h3>

  <p>Yes, a 14-day cooling off period will apply. During this period, you may call us on: 03333 4444 15 to return the device as long as it’s in a condition that is suitable under the returns policy. Upon return, your plan will be amended to be equivalent to your old plan, and your device credit agreement will be cancelled.</p>

  <h3>Can I cancel my device credit agreement but keep the device?</h3>

  <p>Yes, you have a 14-day Right to Withdraw from your device credit agreement. Within this period, you can cancel the device credit agreement but keep the device by paying for it in full within 30 days of exercising your Right to Withdraw.</p>


</div>
