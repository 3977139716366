import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./pages/home/home.component";
import { CreateAccountComponent } from "./pages/create-account/create-account.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";
import { CookiePolicyComponent } from "./pages/cookie-policy/cookie-policy.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { CallRecordPolicyComponent } from "./pages/call-recording-policy/call-record-policy.component";
import { CallsDataRoamingChargesComponent } from "./pages/calls-data-roaming-charges/calls-data-roaming-charges.component";
import { CodeOfPracticeComponent } from "./pages/code-of-practice/code-of-practice.component";
import { HowToAndFaqsComponent } from "./pages/how-to-and-faqs/how-to-and-faqs.component";
import { RefundPolicyComponent } from "./pages/refund-policy/refund-policy.component";
import { MobilesStoreComponent } from "./components/mobiles-store/mobiles-store.component";
import { ReturnsPolicyComponent } from "./pages/returns-policy/returns-policy.component";
import { AccountSetupComponent } from "./pages/account-setup/account-setup.component";
import { AccountSetupCompleteComponent } from "./pages/account-setup/account-setup-complete.component";
import { MobilesDetailComponent } from "./pages/mobiles/mobiles-detail.component";
import { MobilesNewComponent } from "./pages/mobiles/mobiles-new.component";
import { MobilesRefurbishedComponent } from "./pages/mobiles/mobiles-refurbished.component";
import { MobileOrderComponent } from "./pages/mobiles/mobile-order.component";
import {HeaderSectionComponent} from './pages/mobiles/header-section/header-section.component'
import { ProductDetailComponent } from "./pages/mobiles/product-detail/product-detail.component";
import { OrderFormComponent } from "./pages/mobiles/order-form/order-form.component";
import { RegisterComponent } from "./pages/register/register.component";
import { paymentsuccessComponent } from './pages/register/payment-success/payment-success.component';
import { SignupStepperComponent } from './pages/user-registeration/signupParent/signupStepper.component';
import { PurchaseformComponent } from './pages/purchase-form/purchaseform.component';
import { paymentexistComponent } from './pages/payment-exist/payment-exist.component';

import { CreditReportLandingPageComponent } from './pages/credit-report-landing-page/credit-report-landing-page.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { UnsubscribeSuccessComponent } from './components/unsubscribe-success/unsubscribe-success.component';
import { WhatThePointComponent } from './components/what-the-point/what-the-point.component';


import { LandingPageComponent } from "./components/landing-page/landing-page.component";

import { LoginComponent } from "./components/login/login.component";

import { SiteMapComponent } from "./components/site-map/site-map.component"

import { AuthgaurdGuard } from './authgaurd.guard';
import { BillingComponent } from './components/account-panel/billing/billing.component';
import { ActivateSimComponent } from './components/account-panel/activate-sim/activate-sim.component';
import { TestTotalProcessingPaymentComponent } from './components/test-total-processing-payment/test-total-processing-payment.component';
import { ResetPasswordSetupComponent } from './pages/reset-password-setup/reset-password-setup.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { RecurringPolicyComponent } from './pages/recurring-policy/recurring-policy.component';
import { StripeRepaymentComponent } from './components/stripe-repayment/stripe-repayment.component';
import { AgentformComponent } from './pages/agent-form/agent-form.component';
import { AgentSuccessComponent } from './pages/agent-success/agent-success.component';
import { PendingSimComponent } from './components/account-panel/pending-sim/pending-sim.component';

import { PartnersCompanyComponent } from './components/partners-company/partners-company.component';
import { paymentfaildComponent } from './pages/payment-failed/payment-faild.component';
import { PendingSimStatusComponent } from './components/pending-sim-status/pending-sim-status.component';
import { paymenterrorComponent } from './pages/payment-error/payment-error.component';
import { paymentcancelComponent } from './pages/payment-cancel/payment-cancel.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';

import { AffiliateCompanyComponent } from './components/affiliate-company/affiliate-company.component';
import { PaymentResolverService } from './resolver/payment.resolver.service';

import { CustomerSupportComponent } from './components/customer-support/customer-support.component'

import { ObCompleteComponent } from './components/ob-complete/ob-complete.component'
import { ObCancelComponent } from './components/ob-cancel/ob-cancel.component'




const routes: Routes = [
  {
    path: '',
    loadChildren:()=> import('./components/hidden-prices-landing-page/register-hidden-prices/register-hidden-prices.module')
    .then(module => module.RegisterHiddenPriceModule)
  },
  // {
  //   path: '',
  //   component: LandingPageComponent
  // },
  
  {
    path: 'test-total-processing-payment',
    component: TestTotalProcessingPaymentComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'activate',
    component: ActivateSimComponent
  },

  {
    path: 'support',
    component: CustomerSupportComponent
  },
  {
    path: 'ob-complete',
    component: ObCompleteComponent
  },
  {
    path: 'ob-cancel',
    component: ObCancelComponent
  },
  {
    path: 'pendingactivation',
    component: PendingSimComponent
  },
  {
    path: 'unsubscribe',
    component: UnsubscribeComponent
  },
  {
    path: 'company/partners',
    component: PartnersCompanyComponent
  },
  {
    path: 'company/affiliate',
    component: AffiliateCompanyComponent
  },
  {
    path: 'our-story',
    component: WhatThePointComponent
  },
  {
    path: 'unsubscribe-success',
    component: UnsubscribeSuccessComponent
  },
  
  {
    path:'account',
    canActivate:[AuthgaurdGuard],
    loadChildren:()=> import('./components/account-panel/accountWrapper.module')
    .then(module => module.AccountWrapperModule)
  },
  {
    path: 'mobiles',
    component: MobilesStoreComponent,
  },
  {
    path: 'sitemap',
    component: SiteMapComponent,
  },
  {
    path: 'credit-report',
    component: CreditReportLandingPageComponent
  },
  {
    path:'',
    loadChildren:()=> import('./pages/register/register.module')
    .then(module => module.RegisterModule)
  },
  {
    path:'',
    loadChildren:()=> import('./components/registerPackage/RegisterPackage.module')
    .then(module => module.RegisterPackageModule)
  },
  {
    path:'',
    loadChildren:()=> import('./pages/registerSuitsme/RegisterSuitsme.module')
    .then(module => module.RegisterSuitsmeModule)
  },
  {
    path: 'purchase',
    loadChildren:()=> import('./pages/purchase-form/purchaseform.module')
    .then(mod => mod.PurchaseformModule)
  },
  {
    path:'',
    loadChildren:()=> import('./components/register-affiliate/register-affiliate.module')
    .then(module => module.RegisterAffiliateModule)
  },
  {
    path: 'pre',
    loadChildren:()=> import('./pages/user-registeration/signup.module')
    .then(mod =>mod.SignupModule)
  },
  {
    path: 'paymentsuccess',
    component: paymentsuccessComponent
  },
  {
    path: 'payment-failed',
    component: paymentfaildComponent
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
    resolve: {res: PaymentResolverService}
  },
  {
    path: 'paymentexist',
    component: paymentexistComponent
  },
  {
    path: 'payment-error',
    component: paymenterrorComponent
  },
  {
    path: 'payment-cancel',
    component: paymentcancelComponent
  },
  {
    path: 'pending',
    component: PendingSimStatusComponent
  },
  {
    path: 'billing',
    component: BillingComponent
  },
  {
    path: 'striperepayment/:id',
    component: StripeRepaymentComponent
  },
  {
    path: 'p/:id',
    component: StripeRepaymentComponent
  },
  {
    path: 'agent/:org/:name',
    component: AgentformComponent
  },
  {
    path: 'agent/success',
    component: AgentSuccessComponent
  },
  {
    path: 'header-section',
    component: HeaderSectionComponent
  },
  {
    path: 'product-detail/:id',
    component: ProductDetailComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'recurring-policy',
    component: RecurringPolicyComponent
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent
  },
  {
    path: 'returns-policy',
    component: ReturnsPolicyComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'call-record-policy',
    component: CallRecordPolicyComponent
  },
  {
    path: 'calls-data-roaming-charges',
    component: CallsDataRoamingChargesComponent
  },
  {
    path: 'code-of-practice',
    component: CodeOfPracticeComponent
  },
  {
    path: 'faqs',
    component: HowToAndFaqsComponent
  },
  {
    path: 'refund-policy',
    component: RefundPolicyComponent
  },
  {
    path: 'account-setup-complete',
    component: AccountSetupCompleteComponent
  },
  {
    path: 'account-setup',
    component: AccountSetupComponent
  },
  {
    path: 'reset-account-setup',
    component: ResetPasswordSetupComponent
  },
  {
    path: 'reset-account-password',
    component: SetPasswordComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
