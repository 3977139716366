import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class EquifaxService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

 
  public getOpenBankingToken(): Observable<any> {
    return this
      .http
      .get<any>(`${this.env.pipedriveApi}/api/Equifax/GetAccessTokenOB`, this.httpOptions);
  }

  public getScore(): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/Equifax/GetScore`, this.httpOptions);
  }
}
