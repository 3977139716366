
        <div class="">
          <a [routerLink]="['/register/']" [queryParams]="{handsetId:product.id}">
            <div class="product-card">
              <div class="position-relative">
                <div class="discount">
                  <span><span class="fw-bold fs-20 me-1">{{ product.discount }}%</span><span>OFF</span></span>

                </div>
              </div>
              <h2 class="h2 text-loud-bold mt-5">{{ product.name }}</h2>
              <div class="product-detail d-flex justify-content-around align-items-center">
                <div class="img_block">
                  <img src="/assets/images/{{ product.imgURL }}.png">
                </div>
                <div class="product-details">
                  <div class="py-2 my-2 colors_pallete d-flex align-items-center">
                    <a href="#">
                      <div class="bg-success"></div>
                    </a>
                    <a href="#">
                      <div class="bg-danger"></div>
                    </a>
                    <a href="#">
                      <div class="bg-primary"></div>
                    </a>
                    <a href="#">
                      <div class="bg-secondary"></div>
                    </a>
                    <a href="#">
                      <div class="bg-info"></div>
                    </a>
                  </div>

                  <div class="installment">
                    <p class="m-0 text-gray fs-12">12 monthly payments</p>
                    <div class="mt-3 d-flex justify-content-between">
                      <div>
                        <p class="fw-bolder"><b> £{{ product.monthlyAmount.toFixed(2) }} </b> <br>
                          <span class="text-gray">a month</span>
                        </p>
                      </div>
                      <div>
                        <p class="fw-bolder"><b> £{{ product.upfrontAmount.toFixed(2) }}</b> <br>
                          <span class="text-gray">Upfront</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="stock my-3">
                    <img class="me-2" src="/assets/images/tick.png" width="18px">
                    <span>Available in stock</span>
                  </div>
                </div>
              </div>
              <button class="btn gop-btn-green my-3"> Pre-Order Phone</button>
              <div class="button-container">

              </div>
            </div>
          </a>
</div>
