import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMbillInvoice } from '../../../shared/models/mbillsInvoice.model';
import { MbillsService } from '../../../shared/services/mbills.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  mbillInvoces: IMbillInvoice[] = []
  public loading$ = new BehaviorSubject<boolean>(false);
  constructor(private mbills: MbillsService) { }

  ngOnInit() {
    this.loading$.next(true);
    this.getInvoices()
  }

  getInvoices(){
    const accNo = this.mbills.getAccNoFromToken()
    if(accNo !== null){
      this.mbills.getInvoices(accNo).subscribe(
        resp => {
          this.mbillInvoces = resp
          this.loading$.next(false);
        }
      )
    }
  }
}
