import { Component, OnInit, Renderer2, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TotalProcessingService } from '../../shared/services/total-processing.service';
import { PipedriveService } from '../../shared/services/pipedrive.service';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../../shared/services/loader.service';
import { PaymentGatewayService } from '../../shared/services/PaymentGateway.service';
import { BehaviorSubject } from "rxjs";
import { SignalRService } from '../../shared/services/SignalR.service';
@Component({
  selector: 'app-stripe-repayment',
  templateUrl: './stripe-repayment.component.html',
  styleUrls: ['./stripe-repayment.component.css']
})
export class StripeRepaymentComponent implements OnInit {

  checkoutId: any;
  userId: string;
  userName: string;
  userPackage: string;
  userEmail: any;
  amount: any;
  fullAmount: any;
  paymentDate: any;
  isCallcenter: boolean = false;
  actionurl: string;
  production: boolean;

  public loading$ = new BehaviorSubject<boolean>(false);

  @ViewChild('form') form: any
  @ViewChild('iframe') iframe: any 
  @ViewChild('input1') input1: any;  
  @ViewChild('input2') input2: any;  
  @ViewChild('input3') input3: any;  
  @ViewChild('input4') input4: any;  
  @ViewChild('input5') input5: any;  
  @ViewChild('input6') input6: any;  
  @ViewChild('input7') input7: any;  
  @ViewChild('input8') input8: any;  
  @ViewChild('input9') input9: any;  
  @ViewChild('input10') input10: any;  
  @ViewChild('input11') input11: any;  
  @ViewChild('input12') input12: any;  
  @ViewChild('input13') input13: any;  
  @ViewChild('input14') input14: any;    
  @ViewChild('input15') input15: any;  
  @ViewChild('input16') input16: any;  
  @ViewChild('input17') input17: any;  
  @ViewChild('input18') input18: any;  
  @ViewChild('input19') input19: any;  

  constructor(
    private _totalProcessingService: TotalProcessingService,
    private  _renderer2: Renderer2,
    private pipedriveservice: PipedriveService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private router: Router,
    private loader: LoaderService,
    private signalr: SignalRService,
    private paymentService: PaymentGatewayService,

    // @ts-ignore
    @Inject('env') private env:any
  ) {
    this.userId = activatedRoute.snapshot.params['id']
    this.production = this.env.production
  }

  ngOnInit() {
    if(this.production == true){
      this.actionurl = `https://payments.epdq.co.uk/ncol/prod/orderstandard_utf8.asp`
    } else {
      this.actionurl = `https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp`
    }
    this.loader.show()
    localStorage.setItem("IsUrlChecked", JSON.stringify('true'));
    this.getUserDetails()
    this.pipedriveservice.userlanded(this.userId).subscribe(x => x)
    this.listenenToPipedriveEvents()
  }

  getUserDetails(){
    
    this.pipedriveservice.getPartialUserbyid(this.userId).subscribe( x => {
      if(x.item.isBarclay){
        this.router.navigateByUrl('/paymentexist')
      } else {
      var date = new Date()
      const paymentdate = x.item.paymentDate
      const latest_date =this.datepipe.transform(date, 'dd/MM/yyyy');
      if(x.item.iscallCenter == true){
        paymentdate === latest_date ? this.amount = x.item.price : this.amount = 0.01
        this.isCallcenter = true

        this.fullAmount = x.item.price
        this.paymentDate = x.item.paymentDate
      } else {
        this.amount = x.item.price
      }
      this.userEmail = x.item.email
      this.userName = x.item.name
      this.userPackage = x.item.packageId
      this.createTotalProcessingPayment()
    }
    })
  }

  listenenToPipedriveEvents() {
    this.signalr.startConnection()
    this.signalr.listeningToPaymentEvent()
    this.signalr.getDataAsObservable().subscribe(x => {
      if(x.email === this.userEmail){
        
         if(x.stage === 4){
          //paymentsuccess
            this.router.navigate(['/payment-success']);
        } else if(x.stage === 5){
          //paymentFailed
          this.router.navigate(['/payment-failed']);
        }
      }
    })
  }

  createTotalProcessingPayment(){
    var obj= {
      amount: this.amount.toString(),
      Email: this.userEmail
    }
    // this._totalProcessingService.createPaymentProcessing(
    //   obj).subscribe(resp => {
    //   this.checkoutId = resp.id
    //   let script = this._renderer2.createElement('script');
      
    //   script.src = `${this.env.totalProcessingIntent}/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`;
    //   this._renderer2.appendChild(document.body, script);
    // })
    this.loading$.next(true)
    this.paymentService.prepareCheckout(obj).subscribe(async x => {
      const combinedData = new Uint8Array(atob(x.payload).split('').map(char => char.charCodeAt(0)));

      // Extract IV and encrypted data
      const iv = combinedData.slice(0, 16); // Assuming the IV size is 16 bytes
      const encryptedBytes = combinedData.slice(16);
    
      // Import the key
      const importedKey = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode('U388taTOnzVLBiXd9Q5wraEZpnrRDCTQ'),
        { name: 'AES-CBC', length: 256 },
        false,
        ['decrypt']
      );
    
      // Decrypt
      const decryptedData = await crypto.subtle.decrypt(
        {
          name: 'AES-CBC',
          iv: iv
        },
        importedKey,
        encryptedBytes
      );
    
      // Convert the decrypted ArrayBuffer to a UTF-8 string
      const decryptedText = new TextDecoder().decode(decryptedData);
      const data = JSON.parse(decryptedText)
      data.AMOUNT = Number(data.AMOUNT)
      
      this._renderer2.setAttribute(this.input1.nativeElement, 'value', data.PSPID)
      this._renderer2.setAttribute(this.input2.nativeElement, 'value', data.ALIAS)
      this._renderer2.setAttribute(this.input3.nativeElement, 'value', data.ALIASUSAGE)
      this._renderer2.setAttribute(this.input4.nativeElement, 'value', data.ORDERID)
      this._renderer2.setAttribute(this.input5.nativeElement, 'value', data.AMOUNT)
      this._renderer2.setAttribute(this.input6.nativeElement, 'value', data.CURRENCY)
      this._renderer2.setAttribute(this.input7.nativeElement, 'value', data.CN)
      this._renderer2.setAttribute(this.input8.nativeElement, 'value', data.EMAIL)
      this._renderer2.setAttribute(this.input9.nativeElement, 'value', data.OWNERZIP)
      this._renderer2.setAttribute(this.input10.nativeElement, 'value', data.OWNERADDRESS)
      this._renderer2.setAttribute(this.input11.nativeElement, 'value', data.OWNERCTY)
      this._renderer2.setAttribute(this.input12.nativeElement, 'value', data.OWNERTOWN)
      this._renderer2.setAttribute(this.input13.nativeElement, 'value', data.OWNERTELNO)
      this._renderer2.setAttribute(this.input14.nativeElement, 'value', x.result)
      this._renderer2.setAttribute(this.input15.nativeElement, 'value', data.ACCEPTURL)
      this._renderer2.setAttribute(this.input16.nativeElement, 'value', data.DECLINEURL)
      this._renderer2.setAttribute(this.input17.nativeElement, 'value', data.EXCEPTIONURL)
      this._renderer2.setAttribute(this.input18.nativeElement, 'value', data.CANCELURL)
      this.submitPaymentForm();
      ///////////////////////////////////////
      this.iframe.nativeElement.hidden = false
    })
    this.loader.hide()
  }
  
  uploadDone(){
    this.loading$.next(false)
  }

  submitPaymentForm(){
    this.form.nativeElement.hidden = true
    this.form.nativeElement.submit()
  }
}
