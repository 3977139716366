<div class="text-center container" id="plans">
  <img class="mb-3" src="/assets/images/logo-inverted.png" width="130px">
  <h2 class="fw-bold text-gray">
    Choose your personalised SIM plan
  </h2>
  <p class="px-3">You will have access to your credit file for free and we offer guidance to help you understand your credit file. Were here to support your clear path towards improving credit. If you are ever in need of assistance, our dedicated Boshhh team are available through live chat or call, lets re build credit together - BOSHHH!</p>
</div>

<div class="d-flex flex-column">
  <div class="row package-container container mx-auto my-2" #scrollContent (scroll)="onScroll($event)">
    <div id="package_plans" class="package-design-2 col-md-4 col-sm-4 col-lg-4" *ngFor="let plan of packagePlans; index as i">
      
    <div>
      <div class="plan-block" style="position: relative;"
      [ngClass]="{'plan-popular': plan.Name === '30GB Data', 'plan-value': plan.Name === '100GB Data'}">

      <div *ngIf="plan.Name   == '30GB Ultra Fast' " class="most-popular-tag">
        <p class="m-0">Credit Booster 30</p>
      </div>
      <div *ngIf="plan.Name   == '10 Gb Ultra Fast' " class="basic-tag">
        <p class="m-0">Credit Booster 10</p>
      </div>
      <div *ngIf="plan.Name   == '100 GB Ultra Fast' " class="most-value-tag">
        <p class="m-0">Credit Booster 100</p>
      </div>

     
      <div class="mt-25">
        <h2 [attr.id]="'plan_' + i+1" class="package-name d-flex justify-content-between mt-2">{{ plan.Name }}
          <img *ngIf="plan.Name   != 'Credit File'" src="/assets/images/boshhh-sim.jpeg" width="50"
            alt="Boshhh Sim Card" style="position:absolute; top: 10px; right: 10px;">
        </h2>
      </div>
      <p class="m-0">Get unlimited calls and texts</p>
      <!-- <div class="d-flex align-items-center" *ngIf="plan?.Sku   == '4640'">
        <p class="price">£5.00</p>
        <p class="per-month text-center">Per Month</p>
      </div>
      <div class="d-flex align-items-center" *ngIf="plan?.Sku   == '4641'">
        <p class="price">£10.00</p>
        <p class="per-month text-center">Per Month</p>
      </div>
      <div class="d-flex align-items-center" *ngIf="plan?.Sku   == '4644'">
        <p class="price">£15.00</p>
        <p class="per-month text-center">Per Month</p>
      </div> -->
    
      <div >
        <div class="border p-2 my-2">
          <div class="specs">
            <div class="check-img">
              <img src="/assets/images/check.svg" alt="check-tick">
            </div>
            <div>
              <p class="text"><img src="/assets/images/efx_hr_logo_horizontal.png" width="80px" alt="equifax-logo"> <b>Credit Booster By Boshhh</b></p>
              <p>We tell equifax every month When you pay your SIM payment to boost your credit Score</p>
            </div>
          </div>
          <div class="specs">
            <div class="check-img">
              <img src="/assets/images/check.svg" alt="check-tick">
            </div>
            <div>
              <p class="text"> <img src="/assets/images/efx_hr_logo_horizontal.png" width="80px" alt="equifax-logo"><b>Credit File</b></p>
              <p>As part of your credit repair SIM Plan we give you full access to your full credit file from Equifax</p>
            </div>
          </div>
          <!-- <div class="specs">
            <div class="check-img">
              <img src="/assets/images/check.svg">
            </div>
            <p class="text">Equifax access to <b>tools to improve your finances</b></p>
          </div>
          <div class="specs">
            <div class="check-img">
              <img src="/assets/images/check.svg">
            </div>
            <p class="text">Equifax access to <b>Boshhh Smart Match</b></p>
          </div> -->
          <div class="text-center d-flex justify-content-center align-items-center">
            
            <h2 class="price">£{{ plan.Price }}</h2>
            <span>Per Month</span>
          </div>
          
        </div>
      </div>
      <input type="radio" name="package_name" id="package_id-{{ i }}" value="{{ plan?.Sku }}">
      <label for="package_id-{{ i }}" (click)="selectedPlan(plan?.Sku)"
        class="bosh-btn-radio bosh-btn-primary text-center">
        Rebuild My Credit
      </label>

      <div class="cards d-flex justify-content-center mt-2">
        <div class="d-flex  justify-content-center">
          <img class="me-2" src="/assets/images/visa.svg" alt="visa-card">
          <img src="/assets/images/mastercard.svg" alt="master-card">
        </div>
      </div>

    </div>
      </div>
    </div>
  </div>

  <div class="dots-container d-flex justify-content-center">
    <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 1}" (click)="scrollToElement(1);"></span>
    <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 2}" (click)="scrollToElement(2);"></span>
    <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 3}" (click)="scrollToElement(3);"></span>
    <!-- <span class="dot" [ngClass]="{'active-dot': makeSectionActive === 4}" (click)="scrollToElement(4);"></span> -->
  </div>


</div>

