import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AccountService } from '../../shared/services/Account.service';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from '../../shared/services/loader.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  loginForm: FormGroup;
  constructor(
    private router: Router,
    private accountservice: AccountService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.initlogin()
    
  }

  initlogin(){
    this.loginForm = this.fb.group({
      email: ["", [Validators.email, Validators.required]],
      password: [ "", [Validators.required ]]
    });
  }

  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
  onLogin(){
    this.loader.show()
    this.accountservice.login(this.loginForm.getRawValue().email, this.loginForm.getRawValue().password).subscribe(
      resp => {
        localStorage.setItem('UserContext', JSON.stringify(resp.token))
        localStorage.setItem('currentuseremail', JSON.stringify(this.loginForm.getRawValue().email))
        
        this.router.navigate(['/account/dashboard'])
      },
      err => {
        this.loader.hide()
        this.toastr.error('Login Failed','Please Check you Email or Password and try again')
      }
    )
  }

}
