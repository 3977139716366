<div class="container">
  <div class="content-page-container text-center">
    <h2 class="pt-5">Enter your Email</h2>
    <div>
      <form [formGroup]="resetPasswordFormGroup">
        <p>Enter your email address and we will send you an email of instructions on how to create a new password</p>
        <mat-form-field appearance="outline">
          <mat-label>Enter your email address</mat-label>
          <input matInput formControlName="email_address" type="email" required>
        </mat-form-field>
        <div class="text-end btn-next">
          <button class="bosh-btn-primary my-3" (click)="doPasswordReset()"  [disabled]="!this.resetPasswordFormGroup.valid" mat-button type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
