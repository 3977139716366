<div class="bg-yellow text-center">
  <h3 class="text-light m-0">Complete our suitablity test 👇</h3>
</div>
<div class="container card px-0">
  <div class=" my-3">
  <!-- <ng-container  *ngIf="!ishideTopLogo">
    <app-stepper-header [step]="currentStep" ></app-stepper-header>

  </ng-container> -->
  
  <mat-stepper orientation="horizontal"
               linear="false" hide-header orientation="horizontal" #stepper>
           
              <mat-step>
               <p class="center text-center">Are you on <b>Contract</b> or <b>PAYG?</b></p>
               <div class="row question question-1">
                <div class="col-md-6 col-xs-6">
                  <button (click)="MoveNext('Contractor', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Contract </button>
                </div>
                <div class="col-md-6 col-xs-6">
                  <button (click)="MoveNext('PAYG', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> PAYG </button>
                </div>
               </div>
               
               
              </mat-step>
              <mat-step>
                <div class="slidecontainer">
                  <p class="text-center">How much is your <b>phone bill</b> per month?</p>
                  <p class="text-center fw-bold h4">£<span id="demo">{{this.stepperCalculator.billPerMonth}}.00</span></p>
                  <input [(ngModel)]="this.stepperCalculator.billPerMonth" type="range" min="10" max="75" class="slider" id="myRange">
                  
                </div>
                
               <button (click)="stepper.next()" class="mt-2 bosh-btn-border bosh-btn-primary"> Next </button>
              </mat-step>
              <mat-step class="question">
                <p class="center  text-center">What is your <b>credit score</b>?</p>
                <button (click)="CSRatingFunc(1, stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Unknown </button>
                <button (click)="CSRatingFunc(2, stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Very Poor </button>
                <button (click)="CSRatingFunc(3, stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Poor </button>
                <button (click)="CSRatingFunc(4, stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Good </button>
                <button (click)="CSRatingFunc(5, stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Very Good </button>
              </mat-step>
              <mat-step class="question">
                <p class="center  text-center">Who do you use to check your <b>credit score</b>?</p>
                <button (click)="CScheckerFunc('10.95', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Equifax</button>
                <button (click)="CScheckerFunc('14.99', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Experian</button>
                <button (click)="CScheckerFunc('14.99', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Transunion</button>
                <button (click)="CScheckerFunc('0', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Clearscore</button>
                <button (click)="CScheckerFunc('19.99', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> Other</button>
                <button (click)="CScheckerFunc('0', stepper)" class="mt-2 bosh-btn-border bosh-btn-primary"> I don't check it</button>
              </mat-step>
              <mat-step>
                <div class="congratulation-area text-center mt-1">
                  <div class="container">
                      <div class="congratulation-wrapper">
                          <div class="congratulation-contents center-text">
                              <!-- <div class="congratulation-contents-icon"> -->
                                  <!-- <img src="/assets/images/congrats_icon.png" width="110px"> -->
                              <!-- </div> -->
                              <h3 class="congratulation-contents-title"> Great news!</h3>
                              <h4 class="congratulation-contents-para" *ngIf="this.value > 0">You can get a credit building SIM contract with Boshhh! </h4>
                              <h4 class="congratulation-contents-para" *ngIf="this.value > 0">You will save £{{ this.value }}* each year!</h4>
                              <p class="congratulation-contents-para">You also have instant free access to your credit file where we will help you understand or dispute any negative impacts you have to get you on your way to a brighter future!</p>
                              <div class="btn-wrapper mt-4">
                                  <a href="javascript:void(0)" class="cmn-btn bosh-btn-primary" (click)="goToRegister()"> JOIN BOSHHH NOW  </a>
                              </div>
                              <p class="congratulation-contents-para mt-4">*We have calculated this based on your sim plan per month of £{{ this.stepperCalculator.billPerMonth }} and the cost of your credit file provider £{{ this.stepperCalculator.CSchecker }}. This saving is calculated over 12 months</p>
                              <small class="text-gray">All savings are calculated against our £15.99 10gb unlimited plan.</small>
                          </div>
                      </div>
                  </div>
              </div>
              </mat-step>
            
  </mat-stepper>
</div>
</div>
   
  

     