
<lib-jumbotron></lib-jumbotron>
<div class="text-center container" style="padding: 1rem 1rem" >
  <div><h3 class="fw-bold" style="text-transform: uppercase;">Guaranteed acceptance</h3></div>
  <h1 class="mb-2 desktop-heading">
    Boshhh™️ Credit BOOSTER can improve your score with
    
  </h1>
  
  <img  src="/assets/images/efx_hr_logo_horizontal.png" width="300px">
  <p>Boshhh! We are in partnership with the UK’s leading credit reference agency, Equifax.</p>
  <p>Boshhh helps customers get back on track. How? We boost a positive influence on credit files by keeping tabs on your on-time payments and reporting the good news back to Equifax. This guaranteed contract sim plan, is the first step to transforming your score.</p>
  <p>Our sim plans are 100% guaranteed acceptance. With a selection of <img src="/assets/images/iphone.png" width="65px"> , <img src="/assets/images/samsung.png"
    width="80px"> and other brands, all plans are tailored and available to you.</p>
  
  <div>
    <img src="/assets/images/equifax.png" width="300px">
  </div>
  <div>
      <img  src="/assets/images/equifax_powered.png" width="140px">
  </div>
</div>
<div class="sticker-section">
  <div class="d-flex flex-wrap">
    <div class="col-md-4 block1">
      <img  src="/assets/images/bish.png">
    </div>
    <div class="col-md-4 block2">
      <img  src="/assets/images/bash.png">
    </div>
    <div class="col-md-4 block3">
      <img  src="/assets/images/boshhh.png">
    </div>
  </div>
</div>



<!-- DESKTOP VIEW -->
<div id="sim-plans">&nbsp;</div>
<div class=" d-lg-block">
  <lib-sim-plans-selector></lib-sim-plans-selector>
</div>
<!-- DESKTOP VIEW END -->

<div *ngIf="!this.production" class="youtube-thumbnail pt-5">
  <div class="container">
    <p class="h1 text-center">How it works</p>
    <div class="d-flex justify-content-center">
      <iframe
          src="https://www.youtube.com/embed/1hMQNmvkFdE?playlist=1hMQNmvkFdE&autoplay=1&mute=0&rel=0">
      </iframe>
    </div>
  </div>
</div>
<!-- <div class="py-5">
  <app-header-section [linkEnabled]="true"></app-header-section>
</div> -->
<div class="text-center py-5 container">
  <h3>Our coverage is the bomb! We are unbeaten for coverage in the UK!</h3>
  <p>*According to Ofcom’s Connected Nations Report Summer 2020. 85% geographic coverage equates to more than 99% of the population.</p>
</div>
<!-- <lib-how-it-works></lib-how-it-works> -->

<lib-frequently-asked-questions></lib-frequently-asked-questions>
