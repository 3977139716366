import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobiles-detail',
  template: ` <p>mobiles-detail works!</p> `,
  styles: [],
})
export class MobilesDetailComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
