import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'lib-footer-boshhh-temp',
  template: `
  
    <div class="footer_container py-5">
      <div class="text-center container ">
        <div class="footer-logo d-flex align-items-start">
          <a class="text-decoration-none  py-3" (click)="navigate('/')">
                <img alt="Logo" src="/assets/images/logo-inverted.png" width="150px" />
          </a>
        </div>
        <div class="row">
        <div class="col-md-4 col-sm-4 md-1 xs-3">
            <div class="fotter-social-icon d-flex justify-content-center my-3">
            <a href="https://www.instagram.com/boshhhuk/">
              <img alt="Instagram" src="assets/images/social-instagram.png"/>
            </a>
            <a href="https://www.facebook.com/people/Boshhh/100087822891371/">
              <img alt="Facebook" src="assets/images/social-facebook.png"/>
            </a>
            <a href="https://www.youtube.com/channel/UC5pybccpBc08dsejUZriaWA">
              <img alt="YouTube" src="assets/images/social-youtube.png"/>
            </a>
            <a href="https://twitter.com/boshhhuk">
              <img alt="Twitter" src="assets/images/social-twitter.png"/>
            </a>
            <a href="https://www.tiktok.com/@boshhhuk">
              <img alt="TikTok" src="assets/images/social-tiktok.png"/>
            </a>
          </div>
            <p class="md-text-left">
              <small>
                In relation to consumer credit, Boshhh Limited is authorised and regulated by the Financial Conduct Authority (Reference Number 990161) 
                <br>
                Registered in England and Wales. No. 13188665.
                <br>
                Cardinal House, 20 St Mary's Parsonage, Manchester M3 2LY
                <br>
                <a href="tel:03333444415">03333444415</a>
                <br>
                <a href="mailto:Hello@boshhh.com">Hello@boshhh.com</a>
              </small>
            </p>
          </div>
          <div class="col-md-4 col-sm-4 md-2 xs-1 mb-0">
            <ul class="list-unstyled mb-0">
              <li class="nav-item ">
                <a class="footer-item text-gray"  (click)="navigate('/our-story')">Our Story</a>
              </li>
              <li class="nav-item ">
                <a class="footer-item text-gray"  (click)="navigate('/activate')">Activate SIM</a>
              </li>
              <li class="nav-item">
                <a class="footer-item text-gray"  (click)="navigate('/login')">Login</a>
              </li>
              <!-- <li class="nav-item ">
                <a class="footer-item text-gray" href="/#faqs" role="button" (click)="navigate('/faq')">FAQ's</a>
              </li> -->
              
              <li class="nav-item ">
                <a class="footer-item text-gray" role="button" (click)="navigate('/company/partners')">Partners</a>
              </li>
              <li class="nav-item m-0">
                <a class="footer-item text-gray" role="button" (click)="navigate('/company/affiliate')">Affiliate</a>
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-sm-4 md-2 mt-0 xs-2">
            <ul class="list-unstyled">  
              
              <li class="nav-item ">
                <a class="footer-item text-gray" role="button" (click)="navigate('/terms-and-conditions')">Terms & Conditions</a>
              </li>

              <li class="nav-item ">
                <a class="footer-item text-gray" role="button" (click)="navigate('/privacy-policy')">Privacy Policy</a>
              </li>
              <li class="nav-item">
                <a class="text-decoration-none font-size-sm text-gray-dark" role="button"  (click)="navigate('/recurring-policy')">Recurring Policy</a>
              </li>
              
              <li class="nav-item ">
                <a class="footer-item text-gray" role="button" (click)="navigate('/cookie-policy')">Cookies Policy</a>
              </li>
              <li class="nav-item ">
                <a class="footer-item text-gray" role="button" (click)="navigate('/sitemap')">Sitemap</a>
              </li>
              
            </ul>
          </div>
        
        </div>
      </div>
    </div>
  `,
  styles: [`
  
      `]
})
export class FooterBoshhhTempComponent implements OnInit {
  
  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {}

  public navigate (route: string): void {
    // console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
  

}
