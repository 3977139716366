<div class="container">
  <div class="content-page-container text-center">
    <div *ngIf="email">
      <h2 class="pt-5">Setup Your New Account</h2>
      <form [formGroup]="changePasswordFormGroup">
        <mat-form-field appearance="outline">
          <mat-label>Set Your New Password:</mat-label>
          <input matInput formControlName="newPassword" type="password" required>
        </mat-form-field>
        <div class="text-end btn-next">
          <button class="bosh-btn-primary mt-3" (click)="setPassword()" mat-button type="submit">Set Password</button>
        </div>
      </form>
    </div>
  </div>

</div>
