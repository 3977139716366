<div class="container py-3 text-center">
  <h2 class="fw-bold my-2"> <b>Our Story</b></h2>
  <p>
    Nearly half of all mobile phone contracts on the high street are declined. That's before even considering overdrafts, loans, and mortgages. We decided to build our own mobile phone network and called it Boshhh! Now, we can offer everyone a 12-month contract.
  </p>
  <p>We can then create a credit agreement. As you know, if you can't get credit, you can't build credit. But with Boshhh, you can. We designed it this way so everyone can access it.  </p>
  <p>Your SIM card is now your superpower, and we are supported by EE, the UK's best-rated network for coverage for the last 8 years. So, there's no compromise on coverage.</p>
  <p>Bringing your number along is easy. Simply text 'PAC' to 65075, and we'll handle the rest.
  </p>
  <p>Keep your number, boost your credit by paying on time, and take back control of your finances. You can access your credit file for free in our app and build the future you deserve using our toolbox of tips and advice.
  </p>
  <p>Also, enjoy a coffee on us. We have over 75 discount codes waiting for you in our app.
  </p>

  <p><b>Boshhh!</b></p>
</div>