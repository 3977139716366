import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { IPipedriveCustomerDetailModel } from "../models/pipedrive-customer-detail.model";
import { IPayment } from "../models/payment.model";

@Injectable({
  providedIn: 'root'
})
export class PipedriveService {
  user: any;

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public customerDetails(body: IPipedriveCustomerDetailModel): Observable<any> {
    return this
      .http
      .post<IPipedriveCustomerDetailModel>(`${this.env.pipedriveApi}/api/pipedrive/AddDeal`,
        body,
        this.httpOptions
      );
  }

  public addProspectCustomFields(body: any,prospectId:any): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/Sticky/AddProspectCustomFieldsValue?prospectId=${prospectId}`,
        body,
        this.httpOptions
      );
  }

  public createOrderWithProspect(body: any): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/Sticky/CreateOrderWithProspect`,
        body,
        this.httpOptions
      );
  }

  public addcheckBoxesProspectCustomFields(body: any,prospectId:any): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/Sticky/AddProspectCustomFieldsValue?prospectId=${prospectId}`,
        body,
        this.httpOptions
      );
  }

  
  public addProspect(body: any, dob:any, title:any): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/Sticky/AddProspect?DOB=${dob}&title=${title}`,
        body,
        this.httpOptions
      );
  }

  public getChargeID(email: any): Observable<any> {
    return this.http.post<any>(`${this.env.pipedriveApi}/api/Stripe/GetChargeId?emailAddress=${email}`,'',this.httpOptions)
  }

  public paymentMade(emailAddress: string): Observable<any> {
    return this
      .http
      .post<IPipedriveCustomerDetailModel>(`${this.env.pipedriveApi}/api/payment-made?emailAddress=${emailAddress}`,
        '',
        this.httpOptions
      );
  }

  public paymentDeclined(emailAddress: string): Observable<any> {
    return this
      .http
      .post<IPipedriveCustomerDetailModel>(`${this.env.pipedriveApi}/api/payment-declined?emailAddress=${emailAddress}`,
        '',
        this.httpOptions
      );
  }

  public callPhp(body: IPayment): Observable<any> {

    // this.downloadPDF();
    /*return this
      .http.post<IPayment>('https://dev.creditize.co.uk/payment-pdf.php', body, this.httpOptions);*/
    var mediaType = 'application/pdf';
    return this.http.post<IPayment>('https://gopgos.com/bosh/contract/contract-pdf.php', body, this.httpOptions)
  }

  public downloadPDF(): any {
    var mediaType = 'application/pdf';
    this.http.post('https://dev.creditize.co.uk/contract-pdf.php', {location: "report.pdf"}, { responseType: 'blob' }).subscribe(
      (response) => {
        var blob = new Blob([response], { type: mediaType });
        // saveAs(blob, 'report.pdf');
        // console.log('blob', blob);
      },
      e => { throwError(e); }
    );
  }

  public getPartialUserbyid(id: string): Observable<any> {
    return this.http.get<any>(`${this.env.pipedriveApi}/api/Registration/CheckUserStatus?userid=${id}`, this.httpOptions)
  }

  public checkRepetation(URL: string, cridential: any): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/registration/${URL}/${cridential}`, this.httpOptions)
  }

  public setPassword(email:string): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/Registration/set-Password?email=${email}`, this.httpOptions)
  }


  public getAccountNumber(email:string,issueTime:string,expiryTime:string): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/Registration/SignUp?email=${email}&issuetime=${issueTime}&expierytime=${expiryTime}`, this.httpOptions)
  }

  public resetPassword(email:string): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/Registration/ForgotPassword?email=${email}`, this.httpOptions)
  }

  public unsubscribeEmail(email:string): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/Registration/Unsubscribe?email=${email}`, this.httpOptions)
  }

  public getUserforCreditreport(): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/Registration/GetUser`, this.httpOptions)
  }
  
  public getChargeId(paymentIntentId: string): Observable<any>{
    return this.http.get<any>(`${this.env.pipedriveApi}/api/stripe/CreateCharge?paymentIntentId=${paymentIntentId}`, this.httpOptions)
  }
  
  public userlanded(email: any): Observable<any>{
    return this.http.get(`${this.env.pipedriveApi}/api/Registration/UserLanded/${email}`)
  }

  public getUserById(email: any): Observable<any>{
    return this.http.get(`${this.env.pipedriveApi}/api/Registration/GetUserbyId/${email}`)
  }

  public setUserDetails(user: any): any {
    this.user = user
  }

  public getUserDetails(){
    return this.user
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };
}
