<div class="reviews py-5 " [ngClass]="classFlag ? '' : 'container'" id="reviews">
  <div class="container">
 
    <div id="reviews" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <!-- <button type="button" data-bs-target="#reviews" data-bs-slide-to="1" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#reviews" data-bs-slide-to="2" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#reviews" data-bs-slide-to="3" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#reviews" data-bs-slide-to="4" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#reviews" data-bs-slide-to="5" aria-label="Slide 5"></button> -->
      </div>
      <div class="carousel-inner reviews-slider">
        <div class="carousel-item active">
          <h2 class="fw-bold">“I just purchased a Boshhh SIM card which arrived the following day. Setting up the SIM
            card was a breeze as I simply inserted the card and followed the instructions on the website. I am now
            excited to begin my journey of improving my credit score and highly recommend switching to Boshhh."</h2>
          <p class="md-text-center">Hollie, Manchester</p>
        </div>
        <!-- <div class="carousel-item">
          <h2 class="fw-bolder">“I was hesitant to switch, but I’m so glad I did! My credit score has never been higher!</h2>
          <p class="md-text-center">John Smith, London</p>
        </div>
        <div class="carousel-item">
          <h2 class="fw-bolder">“I was hesitant to switch, but I’m so glad I did! My credit score has never been higher!</h2>
          <p class="md-text-center">John Smith, London</p>
        </div>
        <div class="carousel-item">
          <h2 class="fw-bolder">“I was hesitant to switch, but I’m so glad I did! My credit score has never been higher!</h2>
          <p class="md-text-center">John Smith, London</p>
        </div>
        <div class="carousel-item">
          <h2 class="fw-bolder">“I was hesitant to switch, but I’m so glad I did! My credit score has never been higher!</h2>
          <p class="md-text-center">John Smith, London</p>
        </div> -->
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#reviews" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#reviews" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>