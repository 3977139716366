<mat-drawer-container class="example-container" fullscreen>

    <mat-drawer #drawer class="example-sidenav" mode="push">
      <mat-toolbar class="white" [style.width.px]="innerWidth">
        <mat-toolbar-row class="">
          
        </mat-toolbar-row>
      </mat-toolbar>
  
    </mat-drawer>
    
    <div class="example-sidenav-content">
      <app-nav-bar></app-nav-bar>
      <div>
      </div>
      
      <div class="router-container">
        <app-mini-slider></app-mini-slider>
        
        <router-outlet></router-outlet>
        
  
        <div *ngIf="useHelloFooter">
          <lib-footer-hello-temp></lib-footer-hello-temp>
        </div>
        <div *ngIf="!useHelloFooter">
        <div *ngIf="!restrictfooter">
          <div *ngIf="!isAdminPanel">
          <lib-footer-boshhh-temp></lib-footer-boshhh-temp>
        </div>
        </div>
      </div>
      
      <div *ngIf="showsecondfooter">
        <div class="inner-footer">
          <div class="container">
            <!-- <div class="rating-section py-2">
              <div class="d-flex justify-content-center align-items-center">
                <div class="star-contaner d-flex justify-content-between">
                  <img src="/assets/images/star-color.svg" width="20px">
                  <img src="/assets/images/star-color.svg" width="20px">
                  <img src="/assets/images/star-color.svg" width="20px">
                  <img src="/assets/images/star-color.svg" width="20px">
                  <img src="/assets/images/star-color.svg" width="20px">
                </div>
                <p class="my-0 ms-3 text-dark"><b>Based on 3,234 reviews</b></p>
              </div>
              <p class="text-dark fw-normal text-center my-2 loved-text">Loved by thousands of people across the UK</p>
            </div> -->
            <p class="text-center fw-light">
                In relation to consumer credit, Boshhh Limited is authorised and regulated by the Financial Conduct Authority (Reference Number 990161) 
            </p>
            <p class="text-center fw-light">
                Registered in England and Wales. No. 13188665.
            </p>
            <p class="text-center fw-light">
              Cardinal House, 20 St Mary's Parsonage, Manchester M3 2LY
            </p>
            <p class="text-center fw-light">
              03333444415
            </p>
            <p class="text-center fw-light">
                <a href="mailto:hello@boshhh.com">hello@boshhh.com</a>
            </p>
          </div>
        </div>
      </div>
      </div>
  
  
    </div>
  
  </mat-drawer-container>
  
  