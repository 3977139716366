import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public login(email: string, password: string): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.pipedriveApi}/api/MBills/LoginAsync?userName=${email}&password=${password}`,
        this.httpOptions
      );
  }

  public postFetchify(postcode: string): Observable<any> {
    return this
      .http
      .get<any>(`${this.env.fetchifyPostCodeApi}?key=${this.env.fetchifyPostCodeKey}&postcode=${postcode}`,
        this.httpOptions
      );
  }

  public prepareCheckout(obj: any): Observable<any> {
    return this
      .http
       .post<any>(`${this.env.pipedriveApi}/api/Barclay/PrepareCheckouts`,obj, this.httpOptions);
  }

  public validateCoupon(obj: any): Observable<any> {
    return this
      .http
       .post<any>(`${this.env.pipedriveApi}/api/Discount/ValidateCoupon`,obj, this.httpOptions);
  }
}
