import { NgModule } from "@angular/core";


import { CommonModule } from "@angular/common";
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLinkWithHref, RouterOutlet } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { TextMaskModule } from "angular2-text-mask";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";

import { AddressComponent } from "./pages/register/bash/address/address.component";



@NgModule({
  declarations: [
   
    AddressComponent,

  ],
  imports: [
    RouterLinkWithHref,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    TextMaskModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterOutlet,
  ],
  providers: [
    FormGroupDirective
  ],
  bootstrap: [],
  exports: [
    AddressComponent,
  ],
})
export class SharedModule { }
