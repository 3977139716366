<div *ngIf="!showOne" class="row align-items-end package-container py-3">
  <div class="package-design-2 col-md-12 col-sm-12 col-lg-12 my-2" *ngFor="let plan of packagePlans; index as i">
    <div *ngIf="plan.Name   != 'Credit File'">

      <div class="plan-block upgrade-package-container" style="position: relative;"
        [ngClass]="{'plan-popular': plan.Name === '30GB Data', 'plan-value': plan.Name === '100GB Data'}">
        <div *ngIf="plan.Name   == '30GB Data' " class="most-popular-tag">
          <p class="m-0"><span>POPULAR</span></p>
        </div>
        <div *ngIf="plan.Name   == '10GB Data' " class="basic-tag">
          <p class="m-0"><span>Basic</span></p>
        </div>
        <div *ngIf="plan.Name   == '100GB Data' " class="most-value-tag">
          <p class="m-0">MOST VALUE</p>
        </div>

        
        <div class="d-flex justify-content-between mt-2 ">
          <div class="d-flex align-items-center">
            <img *ngIf="plan.Name   != 'Credit File'" class="me-2" src="/assets/images/boshhh-sim.jpeg" width="35" height="50" alt="Boshhh Sim Card" >
            <div class="specs flex-column  ">
              <h2 [attr.id]="'plan_' + i+1" class="package-name d-flex justify-content-between mt-2">{{ plan.Name }}</h2>
              <p class="text">+ unlimited calls and texts</p>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column">
            <!-- <p class="text-pink m-0 f-16 text-decoration-line-through">£{{ plan.priceIncVAT }}</p> -->
            <p class="price">£{{  restrictToTwoDecimals(plan.Price) }}</p>
            <p class="per-month text-center">Per Month</p>
          </div>
        </div>
        
        <input type="radio" name="package_name" id="package_id-{{ i }}" value="{{ plan.Sku }}">
        <label for="package_id-{{ i }}" (click)="selectedPlan(plan)" 
        data-toggle="modal" data-target="#exampleModal" data-backdrop="false"
          class="bosh-btn-radio bosh-btn-primary text-center">
          + Add to my plan
        </label>
      </div>
    </div>
  </div>



  <div class="modal fade" id="exampleModal" tabindex="-200" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog"  style="margin-top: 100px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{this.amount}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          
          </button>
        </div>
        <div class="modal-body">
          <form #ref action="/paymentsuccess" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  
  <!-- <div id="myModal" class="modal" style="background-color: rgba(0, 0, 0, 0.5);">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Payment</h4>
          <button type="button" (click) = "hideModal()" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- <div class="modal-body">
          <form #ref action="/paymentsuccess" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
        
          <form>
            <div class="form-group">
              <label for="cardNumber">Card Number</label>
              <input type="text" class="form-control" id="cardNumber" placeholder="Enter card number" [(ngModel)]="cardNumber" name="cardNumber">
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="expiryMonth">Expiry Month</label>
                <input type="text" class="form-control" id="expiryMonth" placeholder="04" minlength="2" maxlength="2" [(ngModel)]="expiryMonth" name="expiryMonth">
              </div>
              <div class="form-group col-md-4">
                <label for="expiryYear">Expiry Year</label>
                <input type="text" class="form-control" id="expiryYear" placeholder="24" minlength="2" maxlength="2" [(ngModel)]="expiryYear" name="expiryYear">
              </div>
              <div class="form-group col-md-4">
                <label for="cvv">CVV</label>
                <input type="text" class="form-control" id="cvv" placeholder="CVV" minlength="3" maxlength="3" [(ngModel)]="cvv" name="cvv">
              </div>
            </div>
            <div class="form-group">
              <label for="creditCardType">Credit Card Type</label>
              <select class="form-control" id="creditCardType" [(ngModel)]="creditCardType" name="creditCardType">
                <option value="Visa" selected>Select Card type</option>
                <option value="Visa">Visa</option>
                <option value="Mastercard">Mastercard</option>
                <option value="American Express">American Express</option>
                <option value="Discover">Discover</option>
              </select>
            </div>
            <button type="submit" class="btn btn-primary" (click)="submit()">Submit</button>
          </form>
        
        
        </div> -->
<!--         
      </div>
    </div>
  </div> --> 
</div>