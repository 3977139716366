
<!-- THIS PAYMNET WIDGET IS FOR TESTING PURPOSES -->
<!-- <form action="gopgos.com" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form> -->
<div class="hero-section" [ngClass]="classFlag ? 'pb-5' : 'py-5'">
  
  <div class="container">
    <div class="row content text-light py-3">
      <div class="col-md-6 col-sm-6">
        <p class="fw-normal my-2"> GUARANTEED ACCEPTANCE!    </p>
        <h1>Boost your credit score by making on time SIM card payments!</h1>
        <div class="d-flex justify-content-between p-relative">
          <div>
            <p class="my-0"><b>Boshhh™️ Credit BOOSTER</b> can improve your score with Equifax.</p>
            <div class="checklist my-2">
              <p>Get your 12 month sim contract</p>
              <p>Make on-time payments</p>
              <p>Start boosting your credit score</p>
            </div>
            <div class="btn-container py-2" (click)="goToRegister()">
              <button class="bosh-btn-primary">CHOOSE MY PLAN  👉</button>
              <div class="cards  d-flex justify-content-center mt-2">
                <div class="d-flex">
                  <img class="me-2" src="/assets/images/visa.svg" alt="visa-card">
                  <img src="/assets/images/mastercard.svg" alt="master-card">
                </div>
              </div>
            </div>
            
            
          </div>
          <div class="mobile-img">
            <img src="/assets/images/phone.png" alt="phone-image">
          </div>
        </div>
    
  
      </div>
      <div class="col-md-6 col-sm-6 d-flex justify-content-center">
        <div class="desktop-img ">
          <img src="/assets/images/phone-desktop.png" alt="dekstop-phone">
        </div>
      </div>

    </div>
  </div>
</div>

<app-equifax-content></app-equifax-content>
<app-sim-plan></app-sim-plan>
<app-credit-file></app-credit-file>
<app-plan-banner></app-plan-banner>
<app-about-us></app-about-us>
<app-reviews></app-reviews>
<app-qa-section></app-qa-section>
