<div class="container">
  <div class="content-page-container text-center">
    <h2 class="pt-5">Account Setup Complete</h2>
    <p>
      Your account is now ready.
    </p>
    <div class="btn-next button">
      <button [routerLink]="['/login']" class="bosh-btn-primary mt-3" mat-button type="submit">Login</button>
    </div>
  </div>
</div>
