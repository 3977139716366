import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-the-point',
  templateUrl: './what-the-point.component.html',
  styleUrls: ['./what-the-point.component.css']
})
export class WhatThePointComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
