import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MbillsService } from "../../shared/services/mbills.service";
import { PipedriveService } from "../../shared/services/pipedrive.service";

@Component({
  selector: 'app-set-password',
  templateUrl: 'set-password.component.html',
  styles: [`
    /* .btn-next button{
      background: -webkit-linear-gradient(45deg,#009245,#003f37)!important;
      padding: 8px;
      width: 100%!important;
      color: #fff;
      font-size: 18px;
    } */
  `],
})
export class SetPasswordComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  newPassword: string;
  accno: string;
  email: string;
  expierytime: string;
  issuetime: string;
  accountNumber: string;
  isURLExpired: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private mbillsService: MbillsService,
    private toastr: ToastrService,
    private pipeDriveService: PipedriveService

  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.newPassword = '';
          this.email = params['e'];
          this.expierytime = params['i'];
          this.issuetime = params['ex'];
          this.changePasswordFormGroup =
            this.fb.group({
              newPassword: ['', Validators.required],
            });
        }
      );
      this.getAccountNumber();
  }

  setPassword(): void {
    if (this.changePasswordFormGroup.valid) {
        this.mbillsService.setAccount(
       this.accountNumber,
        this.changePasswordFormGroup.controls['newPassword'].value
      ).subscribe(result => {
        if (result.error) {
          this.toastr.error('Account Setup Error', 'Failed to Set Password');
        }
       }, error => { }, () => {
      });
      this.toastr.success('Password Set Success', 'Password Reset Successfully');
      this.router.navigate(['/login'])
    }
  }

  getAccountNumber(){
    this.pipeDriveService.getAccountNumber(this.email,this.issuetime,this.expierytime).subscribe(
      resp => {
        if (resp.accountNumber) {
              this.accountNumber = resp.accountNumber;
            }
      },
      err => {
        this.isURLExpired = true;
      }
    )
  }
}
