<div class="site-map">
  <div class="container sitemap">
    <h2 class="pt-4">Sitemap</h2>
    <div class="row">
      <div class="col-md-4 col-sm-4">
        <ul class="list-unstyled">
          <li class="nav-item ">
            <a class="nav-link"  href="https://boshhh.mbills.co.uk/Login">Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  href="https://boshhh.mbills.co.uk/Activate">Activate SIM</a>
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-sm-4 ">
        <ul class="list-unstyled">
          <li class="nav-item">
            <a class="nav-link" (click)="navigate('/#about')">How it works</a>
          </li>
          <!-- <li class="nav-item ">
            <a class="nav-link"  role="button" (click)="navigate('/#about')">Reviews</a>
          </li> -->
          <li class="nav-item ">
            <a class="nav-link" role="button" (click)="navigate('/#faqs')">FAQ's</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link"  role="button" (click)="navigate('/#plans')">View Plans</a>
          </li>
          
        </ul>
      </div>
      <div class="col-md-4 col-sm-4">
        <ul class="list-unstyled">  
          <li class="nav-item ">
            <a class="footer-item" role="button" (click)="navigate('/terms-and-conditions')">Terms & Conditions</a>
          </li>
          <li class="nav-item ">
            <a class="footer-item" role="button" (click)="navigate('/privacy-policy')">Privacy Policy</a>
          </li>
          <li class="">
            <a class="text-decoration-none font-size-sm text-gray-dark" role="button"  (click)="navigate('/recurring-policy')">Recurring Policy</a>
          </li>
          <li class="nav-item ">
            <a class="footer-item" role="button" (click)="navigate('/cookie-policy')">Cookies Policy</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>