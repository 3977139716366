<div class="container">
  <div class="content-page-container text-center py-5">
    <h2 class="pt-5"> Unsubscribed Successfully!! </h2>
    <p>You will no longer receive email marketing from this list.</p>
    <p>Thanks for the feedback!</p>
    <div class="btn-next button">
      <button (click)="doLogin()" class="bosh-btn-primary mt-3" mat-button type="submit">Home</button>
    </div>
  </div>
</div>
