<div class="container pt-5" id="faqs">
  <h1>Frequently Asked Questions (FAQs)</h1>
  <p>Quick answers to questions you may have. Can't find what you're looking for?</p>
  <div class="accordion accordion-flush mt-3" id="01">
    <div class="accordion-item active">
      <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-01" aria-expanded="false" aria-controls="flush-01">
          Who is the power behind our network?
        </button>
      </h2>
      <div id="flush-01" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#01">
        <div class="accordion-body">
          <p>We are powered by the UK’s most reliable network <b>EE</b>. We have the fastest and most reliable connection, and coverage in the most places.</p>
          <p>*Just one of the reasons to use Boshhh are its excellent coverage and performance. 4G coverage extends to over 99% of the UK population, and its 5G services are now in reach for over 50%</p>
        </div>
      </div>
    </div>
    <div class="accordion-item ">
      <h2 class="accordion-header" id="flush-heading12">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-012" aria-expanded="false" aria-controls="flush-012">
          What if I’m already with EE?
        </button>
      </h2>
      <div id="flush-012" class="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#012">
        <div class="accordion-body">
          <p>Don’t worry you're still welcome to switch with boshhh. We are all friends!</p>
        </div>
      </div>
    </div>
    
    <div class="accordion-item ">
      <h2 class="accordion-header" id="flush-heading13">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
          Do I get 5G with Boshhh SIM only deal?
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          Yes! As long as your handset is compatible. All our SIM deals are 5G
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
          What size of SIM card do I need?
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          <p>The SIM card we send you comes in 3 different sizes to fit all phones, so you don’t need to worry about ordering the right size.
          </p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
          Why chose a Boshhh SIM only plan?
        </button>
      </h2>
      <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          Boshhh is the only network that will boost your credit score if you can’t get credit and give you UNBEATABLE network coverage
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-heading4">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
          Can I leave Boshhh?
        </button>
      </h2>
      <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          Yes, we offer a 14 day money back guarantee. We think you should be able to leave in a way that’s fair. So if it’s not right for you we’ll meet you in the middle just let us know what’s up and if we can’t fix it we will terminate on the date of your next bill.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-heading5">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
          What do I do if I already have a contract?
        </button>
      </h2>
      <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          You can also text ‘INFO’ to 85075 to find out whether you have any early cancellation fees or how much credit you have left on your account.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-08">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-08" aria-expanded="false" aria-controls="flush-08">
          Can I use my SIM abroad?
        </button>
      </h2>
      <div id="flush-08" class="accordion-collapse collapse" aria-labelledby="heading-08" data-bs-parent="#08">
        <div class="accordion-body">
          All our inclusive unlimited minutes and texts are availible throughout Europe. Go roam on us.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-09">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-09" aria-expanded="false" aria-controls="flush-09">
          How do I get in contact with you?
        </button>
      </h2>
      <div id="flush-09" class="accordion-collapse collapse" aria-labelledby="heading-09" data-bs-parent="#09">
        <div class="accordion-body">
          You can use our online chat on. Our website, email us on <a mailto="hello@boshhh.com">hello@boshhh.com</a>  or give our customer service team a call on <a tell="03333 444415"> 03333 444415</a>. BOSHHH!
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-10">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-10" aria-expanded="false" aria-controls="flush-10">
          How will joining Boshhh help me?
        </button>
      </h2>
      <div id="flush-10" class="accordion-collapse collapse" aria-labelledby="heading-10" data-bs-parent="#10">
        <div class="accordion-body">
          Joining Boshhh could be your first step on your journey to a richer life. We empower you to reach your money goals. And we’ll offer you help and support along the way. So whether you want to build your credit score, or just have a SIM card on contract — we can help.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-11">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-11" aria-expanded="false" aria-controls="flush-11">
          Are there any risks?
        </button>
      </h2>
      <div id="flush-11" class="accordion-collapse collapse" aria-labelledby="heading-11" data-bs-parent="#11">
        <p class="accordion-body">
          <p>Boshhh is designed to help you build your credit score and reach your goals.We do everything we can to help our members understand the impact of using credit. And we help you learn how to manage it responsibly.</p>
          <p>As with any credit product, the way you manage your Boshhh Contract impacts your credit score. Managing it responsibly shows that you’re a brilliant borrower and helps you grow your credit score.</p>
        </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-12">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-12" aria-expanded="false" aria-controls="flush-12">
          How long will it take for my Boshhh plan to appear on my credit file?
        </button>
      </h2>
      <div id="flush-12" class="accordion-collapse collapse" aria-labelledby="heading-12" data-bs-parent="#12">
        <div class="accordion-body">
          Boshhh will usually take 6-8 weeks to appear on your credit file.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-13">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-13" aria-expanded="false" aria-controls="flush-13">
          How do I see my credit score improvement?
        </button>
      </h2>
      <div id="flush-13" class="accordion-collapse collapse" aria-labelledby="heading-13" data-bs-parent="#13">
        <div class="accordion-body">
          We will send you a SMS after sign up and give you access to our app and online log in to set up your credit file access, and easily view anytime on the go. This will help you better understand your financial profile
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-14">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-14" aria-expanded="false" aria-controls="flush-14">
          How do I pay each month?
        </button>
      </h2>
      <div id="flush-14" class="accordion-collapse collapse" aria-labelledby="heading-14" data-bs-parent="#14">
        <div class="accordion-body">
          Every month we charge the card that you used during signup, everything happens on auto. If for any reason we are unable to bill you (for example - if the card is declined) we will restrict services.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading-15">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-15" aria-expanded="false" aria-controls="flush-15">
          How to find your PAC code?
        </button>
      </h2>
      <div id="flush-15" class="accordion-collapse collapse" aria-labelledby="heading-15" data-bs-parent="#15">
        <div class="accordion-body">
          A PAC (Porting Authorisation Code) is an alphanumeric code that allows you to transfer your number to a new network. It's valid for 30 days after it's supplied to you. If you don't start porting your number within that period, you'll need a new PAC.. The simplest and fastest way to get a PAC is by texting 'PAC' (no need for the speech marks) to 65075. Switch to Boshhh today and keep the number you love!
        </div>
      </div>
    </div>
  </div>
</div>