import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AddressLookupService } from "../../../shared/services/address-lookup.service"

@Component({
  selector: 'app-agent-package-selector',
  templateUrl: './agent-package-selector.component.html',
  styleUrls: ['./agent-package-selector.component.css'],
})
export class AgentPackageSelectorComponent implements OnInit {
  @Output() packageInfo = new EventEmitter();
  selectedPackage: any
  packagePlans: any
  packageBoldOnPlans: any
  constructor(private adressLookupService: AddressLookupService) {}

  ngOnInit() {
    this.adressLookupService.getPackages().subscribe(
      resp => {
        this.packagePlans = resp
      this.packagePlans = this.packagePlans.filter( (x: any) => x.Categoryid === 2).reverse()
      this.packagePlans = this.packagePlans.sort((a:any, b:any) => {
        return b.Price - a.Price
      })

      })
  }

  selectedPlan(data: any){
    this.packageInfo.emit(data)
  }
}
