<div class="text-center mt-5 plan-section">
  <h1 class="mb-3 section-heading">
    Sim Plans
  </h1>
</div>
<style>

</style>
<div>
  <div class="packages-plan container">
    <table class="w-100">
      <tbody>
      <tr>
        <th></th>
        <th class="text-center">Basic</th>
        <th class="text-center">Most Popular</th>
        <th class="text-center">Premium</th>
      </tr>
      <tr>
        <td class="plan-feature">Data</td>
        <td><b>10GB</b></td>
        <td><b>30GB</b></td>
        <td><b>100GB</b></td>
      </tr>
      <tr>
        <td class="plan-feature">Minutes</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
      </tr>
      <tr>
        <td class="plan-feature">Text</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
      </tr>
      <tr>
        <td class="plan-feature">Setup Fee</td>
        <td>£7.99</td>
        <td>£7.99</td>
        <td>£7.99</td>
      </tr>
      <tr>
        <td class="plan-feature">5G Ready at no extra cost</td>
        <td class="ic-check"><img src="/assets/images/inactive.png" alt="un-check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
      </tr>
      <tr>
        <td class="plan-feature">Personal Hostpot</td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="un-check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
      </tr>
      <tr>
        <td class="plan-feature">Go Roam Around the world</td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="un-check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
      </tr>
      <tr>
        <td class="plan-feature">Credit Booster</td>
        <td class="credit-builder"><img src="/assets/images/boshhh-credit.png" alt="un-check"></td>
        <td class="credit-builder"><img src="/assets/images/boshhh-credit.png" alt="un-check"></td>
        <td class="credit-builder"><img src="/assets/images/boshhh-credit.png" alt="un-check"></td>
      </tr>
      <tr hidden>
        <td class="plan-feature">Credit Booster</td>
        <td class="ic-check"><img src="/assets/images/inactive.png" alt="un-check"></td>
        <td class="ic-check"><img src="/assets/images/inactive.png" alt="check"></td>
        <td class="ic-check"><img src="/assets/images/active.png" alt="check"></td>
      </tr>
      <tr>
        <td class="plan-feature">Price</td>
        <td><h2 class="fw-bolder">£22.75</h2></td>
        <td><h2 class="fw-bolder">£26.75</h2></td>
        <td><h2 class="fw-bolder">£32.75</h2></td>
      </tr>
      <tr class="btn-buy">
        <td></td>
        <!-- <a href="/create-account/{{priceIdOne}}"> -->
        <td><button (click)="selectPackage(this.priceIdOne)" class="btn btn-green-outline">Buy</button></td>
        <!-- </a> -->
        <!-- <a href="/create-account/{{priceIdTwo}}"> -->
        <td><button (click)="selectPackage(this.priceIdTwo)" class="btn btn-green-filled">Buy</button></td>
        <!-- </a> -->
        <!-- <a href="/create-account/{{priceIdThree}}"> -->
        <td><button (click)="selectPackage(this.priceIdThree)" class="btn btn-green-outline">Buy</button></td>
        <!-- </a> -->

      </tr>


      </tbody>
    </table>
  </div>
</div>
